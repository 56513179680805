export const VALID_GUESSES = [
    'aadam',
    'aahil',
    'aaira',
    'aanya',
    'aarav',
    'aariz',
    'aaron',
    'aarya',
    'aayan',
    'aayat',
    'abbas',
    'abbie',
    'abdul',
    'adela',
    'adele',
    'adnan',
    'adyan',
    'agnes',
    'ahmad',
    'ahmed',
    'aidan',
    'aiden',
    'aimee',
    'aisha',
    'aiyla',
    'aizah',
    'akram',
    'alaia',
    'alana',
    'alara',
    'alaya',
    'alban',
    'albie',
    'alexa',
    'alfie',
    'alice',
    'alina',
    'alisa',
    'aliya',
    'aliza',
    'allan',
    'alvin',
    'amaal',
    'amaan',
    'amaia',
    'amani',
    'amara',
    'amari',
    'amaya',
    'amber',
    'amina',
    'amira',
    'amiya',
    'ammar',
    'amyra',
    'anais',
    'anaya',
    'andre',
    'angel',
    'angus',
    'anika',
    'anita',
    'annie',
    'anton',
    'anwen',
    'aoife',
    'april',
    'arham',
    'ariah',
    'arian',
    'ariel',
    'ariya',
    'arjan',
    'arjun',
    'arlie',
    'arnie',
    'arran',
    'artur',
    'arvin',
    'arwen',
    'aryan',
    'asher',
    'asiya',
    'aston',
    'atlas',
    'aveer',
    'avery',
    'ayaan',
    'ayaat',
    'ayana',
    'ayden',
    'aydin',
    'ayeza',
    'aylin',
    'ayman',
    'ayoub',
    'aysha',
    'ayyub',
    'azaan',
    'azlan',
    'barry',
    'basil',
    'bella',
    'belle',
    'benas',
    'benny',
    'beryl',
    'betsy',
    'betty',
    'bilal',
    'billy',
    'bjorn',
    'blake',
    'bobbi',
    'bobby',
    'bodhi',
    'bodie',
    'boris',
    'bowen',
    'bowie',
    'brett',
    'brian',
    'brody',
    'bruce',
    'bruno',
    'bryan',
    'buddy',
    'byron',
    'caden',
    'cairo',
    'caleb',
    'carla',
    'carlo',
    'carly',
    'carol',
    'carys',
    'casey',
    'cecil',
    'celia',
    'cerys',
    'chase',
    'chaya',
    'chloe',
    'ciara',
    'clair',
    'clara',
    'clare',
    'clark',
    'clive',
    'cobie',
    'cohen',
    'colby',
    'colin',
    'conor',
    'corey',
    'cosmo',
    'craig',
    'cyril',
    'cyrus',
    'daisy',
    'dalia',
    'damon',
    'danny',
    'dante',
    'darcy',
    'daria',
    'darla',
    'david',
    'dawid',
    'dawud',
    'debra',
    'delia',
    'denis',
    'deniz',
    'denny',
    'derek',
    'devon',
    'dhruv',
    'diana',
    'diane',
    'diego',
    'dilan',
    'diyan',
    'dolly',
    'donna',
    'doris',
    'dotty',
    'dylan',
    'eadie',
    'ebony',
    'eddie',
    'edgar',
    'edith',
    'edwin',
    'effie',
    'elara',
    'elena',
    'eleni',
    'eleri',
    'elias',
    'elina',
    'elisa',
    'elise',
    'eliza',
    'ellen',
    'ellie',
    'ellis',
    'elora',
    'elsie',
    'elyas',
    'emaan',
    'ember',
    'emily',
    'emmie',
    'emrys',
    'erica',
    'erick',
    'erika',
    'ernie',
    'eshal',
    'esmae',
    'esmai',
    'esmay',
    'esmee',
    'ethan',
    'ethel',
    'ettie',
    'faith',
    'fanny',
    'farah',
    'faris',
    'fateh',
    'felix',
    'ferne',
    'ffion',
    'filip',
    'fiona',
    'fionn',
    'fleur',
    'flora',
    'flynn',
    'frank',
    'freda',
    'freya',
    'frida',
    'garry',
    'gavin',
    'gemma',
    'glenn',
    'grace',
    'greta',
    'hafsa',
    'hajra',
    'halle',
    'hamza',
    'hania',
    'hanna',
    'haris',
    'harri',
    'harry',
    'harun',
    'hasan',
    'hawwa',
    'haydn',
    'hazel',
    'heath',
    'heidi',
    'helen',
    'henri',
    'henry',
    'hilda',
    'holly',
    'honey',
    'husna',
    'ianis',
    'idris',
    'ilyas',
    'imaan',
    'imani',
    'imran',
    'inaya',
    'india',
    'indie',
    'ioana',
    'iosif',
    'irene',
    'irina',
    'isaac',
    'ishaq',
    'issac',
    'iylah',
    'izaan',
    'jacob',
    'jaden',
    'jakob',
    'jakub',
    'jamal',
    'james',
    'jamie',
    'janet',
    'jared',
    'jason',
    'jaxon',
    'jayce',
    'jayne',
    'jemma',
    'jenna',
    'jenny',
    'jesse',
    'jimmy',
    'jodie',
    'johan',
    'jonah',
    'jonas',
    'jorge',
    'josie',
    'jovan',
    'joyce',
    'judah',
    'julia',
    'julie',
    'kabir',
    'kaden',
    'kaira',
    'kairo',
    'kajus',
    'kaleb',
    'kamil',
    'karam',
    'karen',
    'karim',
    'karol',
    'katie',
    'kayan',
    'kayla',
    'keanu',
    'keira',
    'keith',
    'kelly',
    'kenny',
    'kenzo',
    'kerry',
    'kevin',
    'khloe',
    'kiaan',
    'kiana',
    'kiara',
    'kiera',
    'kitty',
    'kiyan',
    'klara',
    'kobie',
    'krish',
    'kylan',
    'kyran',
    'kyrie',
    'kyron',
    'lacey',
    'lacie',
    'laiba',
    'laila',
    'laith',
    'laura',
    'layan',
    'layla',
    'layth',
    'leela',
    'leena',
    'leigh',
    'leila',
    'lenny',
    'leona',
    'lewis',
    'lexie',
    'leyla',
    'liana',
    'libby',
    'lidia',
    'lilah',
    'lilia',
    'lilly',
    'linda',
    'livia',
    'lloyd',
    'logan',
    'louie',
    'louis',
    'lowen',
    'lowri',
    'luana',
    'lucas',
    'lucca',
    'lucia',
    'lucie',
    'luisa',
    'lukas',
    'lydia',
    'lylah',
    'lynda',
    'lynne',
    'mabel',
    'macey',
    'macie',
    'maeva',
    'maeve',
    'mahdi',
    'mahir',
    'maira',
    'maisy',
    'maiya',
    'malia',
    'malik',
    'mandy',
    'manha',
    'marco',
    'margo',
    'maria',
    'marie',
    'mario',
    'marla',
    'marta',
    'marwa',
    'mason',
    'matas',
    'matei',
    'mateo',
    'mavis',
    'maxim',
    'megan',
    'micah',
    'mikey',
    'milan',
    'miles',
    'miley',
    'milly',
    'miran',
    'mirha',
    'molly',
    'monty',
    'moses',
    'moshe',
    'mylah',
    'myles',
    'nadia',
    'naila',
    'naira',
    'nancy',
    'naomi',
    'natan',
    'neave',
    'nella',
    'nelly',
    'niall',
    'niamh',
    'nieve',
    'nigel',
    'nojus',
    'nolan',
    'norah',
    'norma',
    'nylah',
    'ocean',
    'oisin',
    'olive',
    'ollie',
    'omari',
    'orion',
    'orson',
    'oscar',
    'osian',
    'oskar',
    'osman',
    'owain',
    'pablo',
    'paddy',
    'paige',
    'paris',
    'paula',
    'pearl',
    'pedro',
    'peggy',
    'penny',
    'percy',
    'peter',
    'petra',
    'piotr',
    'piper',
    'pippa',
    'pixie',
    'polly',
    'poppy',
    'priya',
    'qasim',
    'quinn',
    'raees',
    'rahma',
    'raife',
    'raisa',
    'ralph',
    'rania',
    'rares',
    'raven',
    'rayan',
    'rayna',
    'reece',
    'reeva',
    'regan',
    'rehan',
    'reign',
    'reina',
    'renae',
    'renee',
    'renzo',
    'ricky',
    'riley',
    'river',
    'riyan',
    'robin',
    'robyn',
    'rocco',
    'rocky',
    'rogan',
    'roger',
    'rohan',
    'roman',
    'romeo',
    'ronan',
    'ronny',
    'rosie',
    'rowan',
    'ruben',
    'rufus',
    'ryder',
    'rylee',
    'saara',
    'sadie',
    'safaa',
    'safia',
    'sahib',
    'sahil',
    'saint',
    'saira',
    'salah',
    'sally',
    'salma',
    'samir',
    'sarah',
    'sasha',
    'saxon',
    'scott',
    'seren',
    'shaan',
    'shane',
    'shaun',
    'sidra',
    'siena',
    'silas',
    'simon',
    'skyla',
    'sofia',
    'sonia',
    'sonny',
    'soren',
    'storm',
    'susan',
    'sybil',
    'syeda',
    'tadhg',
    'talha',
    'talia',
    'tanya',
    'taran',
    'taron',
    'teddy',
    'terry',
    'tessa',
    'theia',
    'theon',
    'tiago',
    'tiana',
    'tilly',
    'tomas',
    'tommy',
    'tomos',
    'torin',
    'tracy',
    'tudor',
    'tyler',
    'tymon',
    'umair',
    'usman',
    'uzair',
    'viaan',
    'vinny',
    'wayne',
    'wendy',
    'willa',
    'woody',
    'wyatt',
    'yahya',
    'yanis',
    'yaqub',
    'yasin',
    'yasir',
    'yosef',
    'yunus',
    'yusha',
    'yusra',
    'yusuf',
    'yuvan',
    'zadie',
    'zahra',
    'zaina',
    'zayan',
    'zayne',
    'zidan',
    'ziggy',
    'zofia'
]
