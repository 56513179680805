import { BaseModal } from './BaseModal'

type Props = {
  isOpen: boolean
  handleClose: () => void
}

export const GameSelectorModal = ({ isOpen, handleClose }: Props) => {
  return (
    <BaseModal title="NAMEL" isOpen={isOpen} handleClose={handleClose}>

      <p className="mt-2 text-sm text-gray-500 dark:text-gray-300">
      Welcome to Namel. The game where you have 6 tries to guess the person's name! A new name every day
      </p>

      <p className="mt-4 text-sm text-gray-500 dark:text-gray-300">
        <b>&nbsp;&nbsp;Game Modes:</b>
      </p>

      <p className="mt-1 text-sm text-gray-500 dark:text-gray-300 underline">
      <a href="/">
        Classic Namel - Guess the person's name
      </a>
      </p>

      <p className="mt-2 text-sm text-gray-500 dark:text-gray-300 underline">
      <a href="/england-players">
        England Footballers - Guess the England player
      </a>
      </p>

      <p className="mt-6 text-sm italic text-gray-500 dark:text-gray-300">
          More game modes coming soon!
      </p>
    </BaseModal>
  )
}
