import { Cell } from '../../grid/england-players/Cell'
import { BaseModal } from '../BaseModal'

type Props = {
  isOpen: boolean
  handleClose: () => void
}

export const InfoModal = ({ isOpen, handleClose }: Props) => {
  return (
    <BaseModal title="How to play" isOpen={isOpen} handleClose={handleClose}>
      <p className="text-sm text-gray-500 dark:text-gray-300">
      <b>Guess the England player in 6 tries.</b> 
      </p>

      <p className="mt-2 text-sm text-gray-500 dark:text-gray-300">
      After each guess, the color of the tiles will change to show how close your guess was to the player.
      </p>

      <p className="mt-4 text-sm text-gray-500 dark:text-gray-300 flex justify-left">
        <b>&nbsp;&nbsp;Examples:</b>
      </p>

      <div className="mb-1 mt-4 flex justify-center">
        <Cell
          isRevealing={true}
          isCompleted={true}
          value="R"
          status="correct"
        />
        <Cell value="O" isCompleted={true} />
        <Cell value="O" isCompleted={true} />
        <Cell value="N" isCompleted={true} />
        <Cell value="E" isCompleted={true} />
        <Cell value="Y" isCompleted={true} />
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        The letter R is in the name and in the correct spot.
      </p>

      <div className="mb-1 mt-4 flex justify-center">
        <Cell value="L" isCompleted={true} />
        <Cell value="I" isCompleted={true} />
        <Cell
          isRevealing={true}
          isCompleted={true}
          value="N"
          status="present"
        />
        <Cell value="E" isCompleted={true} />
        <Cell value="K" isCompleted={true} />
        <Cell value="E" isCompleted={true} />
        <Cell value="R" isCompleted={true} />
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        The letter N is in the name but in the wrong spot.
      </p>

      <div className="mb-1 mt-4 flex justify-center">
        <Cell value="S" isCompleted={true} />
        <Cell value="E" isCompleted={true} />
        <Cell value="A" isCompleted={true} />
        <Cell isRevealing={true} isCompleted={true} value="M" status="absent" />
        <Cell value="A" isCompleted={true} />
        <Cell value="N" isCompleted={true} />
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        The letter M is not in the name in any spot.
      </p>

      <p className="mt-4 text-sm text-gray-500 dark:text-gray-300">
        A new name is released daily at midnight.
      </p>

    </BaseModal>
  )
}
