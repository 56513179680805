import './index.css'

import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import App from './pages/Main'
import EnglandPlayers from './pages/England-Players'
import { AlertProvider } from './context/AlertContext'
import reportWebVitals from './reportWebVitals'

ReactDOM.render(
  <BrowserRouter>
    <AlertProvider>
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/england-players" element={<EnglandPlayers />} />
      </Routes>   
    </AlertProvider>
  </BrowserRouter>,
  document.getElementById('root')
)
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
