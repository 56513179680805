import { Cell } from '../../grid/main/Cell'
import { BaseModal } from '../BaseModal'

type Props = {
  isOpen: boolean
  handleClose: () => void
}

export const InfoModal = ({ isOpen, handleClose }: Props) => {
  return (
    <BaseModal title="How to play" isOpen={isOpen} handleClose={handleClose}>
      <p className="text-sm text-gray-500 dark:text-gray-300">
      <b>Guess the person's name in 6 tries.</b> 
      </p>

      <p className="mt-2 text-sm text-gray-500 dark:text-gray-300">
      After each guess, the color of the tiles will change to show how close your guess was to the name.
      </p>

      <p className="mt-4 text-sm text-gray-500 dark:text-gray-300 flex justify-left">
        <b>&nbsp;&nbsp;Examples:</b>
      </p>

      <div className="mb-1 mt-4 flex justify-center">
        <Cell
          isRevealing={true}
          isCompleted={true}
          value="F"
          status="correct"
        />
        <Cell value="L" isCompleted={true} />
        <Cell value="Y" isCompleted={true} />
        <Cell value="N" isCompleted={true} />
        <Cell value="N" isCompleted={true} />
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        The letter F is in the name and in the correct spot.
      </p>

      <div className="mb-1 mt-4 flex justify-center">
        <Cell value="K" isCompleted={true} />
        <Cell value="E" isCompleted={true} />
        <Cell
          isRevealing={true}
          isCompleted={true}
          value="V"
          status="present"
        />
        <Cell value="I" isCompleted={true} />
        <Cell value="N" isCompleted={true} />
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        The letter V is in the name but in the wrong spot.
      </p>

      <div className="mb-1 mt-4 flex justify-center">
        <Cell value="M" isCompleted={true} />
        <Cell value="A" isCompleted={true} />
        <Cell value="R" isCompleted={true} />
        <Cell isRevealing={true} isCompleted={true} value="I" status="absent" />
        <Cell value="A" isCompleted={true} />
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        The letter I is not in the name in any spot.
      </p>

      <p className="mt-4 text-sm text-gray-500 dark:text-gray-300">
        A new name is released daily at midnight.
      </p>

      <p className="mt-6 text-sm italic text-gray-500 dark:text-gray-300">
        <a href="https://www.namel.co.uk/england-players" target="_blank" rel="noopener noreferrer">
          Try our new Engand Players game!
        </a>
      </p>
    </BaseModal>
  )
}
