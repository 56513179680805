export const WORDS = [
    {
        "Surname": "Clough",
        "FullName": "Brian Clough",
        "Position": "FW",
        "Caps": "2",
        "Goals": "0",
        "DebutYear": "1959",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Haygarth",
        "FullName": "Edward Haygarth",
        "Position": "FB",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1875",
        "DebutOpponent": "Scotland"
    },
    {
        "Surname": "Clegg",
        "FullName": "Charles Clegg",
        "Position": "FW",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1872",
        "DebutOpponent": "Scotland"
    },
    {
        "Surname": "Davies",
        "FullName": "Kevin Davies",
        "Position": "FW",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "2010",
        "DebutOpponent": "Montenegro"
    },
    {
        "Surname": "Downing",
        "FullName": "Stewart Downing",
        "Position": "MF",
        "Caps": "35",
        "Goals": "0",
        "DebutYear": "2005",
        "DebutOpponent": "Netherlands"
    },
    {
        "Surname": "Richards",
        "FullName": "Micah Richards",
        "Position": "DF",
        "Caps": "13",
        "Goals": "1",
        "DebutYear": "2006",
        "DebutOpponent": "Netherlands"
    },
    {
        "Surname": "Nish",
        "FullName": "David Nish",
        "Position": "DF",
        "Caps": "5",
        "Goals": "0",
        "DebutYear": "1973",
        "DebutOpponent": "Scotland"
    },
    {
        "Surname": "Cox",
        "FullName": "John Cox",
        "Position": "HB",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1892",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Douglas",
        "FullName": "Bryan Douglas",
        "Position": "FW",
        "Caps": "36",
        "Goals": "11",
        "DebutYear": "1957",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Bruton",
        "FullName": "Jack Bruton",
        "Position": "FW",
        "Caps": "3",
        "Goals": "0",
        "DebutYear": "1928",
        "DebutOpponent": "France"
    },
    {
        "Surname": "Hampton",
        "FullName": "Harry Hampton",
        "Position": "FW",
        "Caps": "4",
        "Goals": "2",
        "DebutYear": "1913",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Melia",
        "FullName": "Jimmy Melia",
        "Position": "FW",
        "Caps": "2",
        "Goals": "1",
        "DebutYear": "1963",
        "DebutOpponent": "Scotland"
    },
    {
        "Surname": "Birtles",
        "FullName": "Garry Birtles",
        "Position": "FW",
        "Caps": "3",
        "Goals": "0",
        "DebutYear": "1980",
        "DebutOpponent": "Argentina"
    },
    {
        "Surname": "Goulden",
        "FullName": "Len Goulden",
        "Position": "FW",
        "Caps": "14",
        "Goals": "4",
        "DebutYear": "1937",
        "DebutOpponent": "Norway"
    },
    {
        "Surname": "Tilson",
        "FullName": "Fred Tilson",
        "Position": "FW",
        "Caps": "4",
        "Goals": "6",
        "DebutYear": "1934",
        "DebutOpponent": "Hungary"
    },
    {
        "Surname": "Gallagher",
        "FullName": "Conor Gallagher",
        "Position": "MF",
        "Caps": "6",
        "Goals": "0",
        "DebutYear": "2021",
        "DebutOpponent": "San Marino"
    },
    {
        "Surname": "Hartley",
        "FullName": "Frank Hartley",
        "Position": "FW",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1923",
        "DebutOpponent": "France"
    },
    {
        "Surname": "Shea",
        "FullName": "Danny Shea",
        "Position": "FW",
        "Caps": "2",
        "Goals": "0",
        "DebutYear": "1914",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Vidal",
        "FullName": "Robert Vidal",
        "Position": "FB",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1873",
        "DebutOpponent": "Scotland"
    },
    {
        "Surname": "Williams",
        "FullName": "Steve Williams",
        "Position": "MF",
        "Caps": "6",
        "Goals": "0",
        "DebutYear": "1983",
        "DebutOpponent": "Australia"
    },
    {
        "Surname": "Kay",
        "FullName": "Tony Kay",
        "Position": "HB",
        "Caps": "1",
        "Goals": "1",
        "DebutYear": "1963",
        "DebutOpponent": "Switzerland"
    },
    {
        "Surname": "Pawson",
        "FullName": "Francis Pawson",
        "Position": "FW",
        "Caps": "2",
        "Goals": "1",
        "DebutYear": "1883",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Whitfeld",
        "FullName": "Herbert Whitfeld",
        "Position": "FW",
        "Caps": "1",
        "Goals": "1",
        "DebutYear": "1879",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Forman",
        "FullName": "Frank Forman",
        "Position": "HB",
        "Caps": "9",
        "Goals": "1",
        "DebutYear": "1898",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Cullis",
        "FullName": "Stan Cullis",
        "Position": "HB",
        "Caps": "12",
        "Goals": "0",
        "DebutYear": "1937",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Jenkinson",
        "FullName": "Carl Jenkinson",
        "Position": "DF",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "2012",
        "DebutOpponent": "Sweden"
    },
    {
        "Surname": "Pearson",
        "FullName": "Stan Pearson",
        "Position": "FW",
        "Caps": "8",
        "Goals": "5",
        "DebutYear": "1948",
        "DebutOpponent": "Scotland"
    },
    {
        "Surname": "Barnet",
        "FullName": "Horace Barnet",
        "Position": "FW",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1882",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Berry",
        "FullName": "Arthur Berry",
        "Position": "FW",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1909",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Godfrey",
        "FullName": "Ben Godfrey",
        "Position": "DF",
        "Caps": "2",
        "Goals": "0",
        "DebutYear": "2021",
        "DebutOpponent": "Austria"
    },
    {
        "Surname": "George",
        "FullName": "Billy George",
        "Position": "GK",
        "Caps": "3",
        "Goals": "0",
        "DebutYear": "1902",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Ball",
        "FullName": "Michael Ball",
        "Position": "DF",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "2001",
        "DebutOpponent": "Spain"
    },
    {
        "Surname": "Wise",
        "FullName": "Dennis Wise",
        "Position": "MF",
        "Caps": "21",
        "Goals": "1",
        "DebutYear": "1991",
        "DebutOpponent": "Turkey"
    },
    {
        "Surname": "Buchanan",
        "FullName": "Walter Buchanan",
        "Position": "FW",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1876",
        "DebutOpponent": "Scotland"
    },
    {
        "Surname": "Crayston",
        "FullName": "Jack Crayston",
        "Position": "HB",
        "Caps": "8",
        "Goals": "1",
        "DebutYear": "1935",
        "DebutOpponent": "Switzerland"
    },
    {
        "Surname": "Staniforth",
        "FullName": "Ron Staniforth",
        "Position": "FB",
        "Caps": "8",
        "Goals": "0",
        "DebutYear": "1954",
        "DebutOpponent": "Scotland"
    },
    {
        "Surname": "Peacock",
        "FullName": "Joe Peacock",
        "Position": "HB",
        "Caps": "3",
        "Goals": "0",
        "DebutYear": "1929",
        "DebutOpponent": "France"
    },
    {
        "Surname": "Baddeley",
        "FullName": "Tom Baddeley",
        "Position": "GK",
        "Caps": "5",
        "Goals": "0",
        "DebutYear": "1903",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Heron",
        "FullName": "Francis Heron",
        "Position": "FW",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1876",
        "DebutOpponent": "Scotland"
    },
    {
        "Surname": "Young",
        "FullName": "Ashley Young",
        "Position": "MF / DF",
        "Caps": "39",
        "Goals": "7",
        "DebutYear": "2007",
        "DebutOpponent": "Austria"
    },
    {
        "Surname": "Charsley",
        "FullName": "Chris Charsley",
        "Position": "GK",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1893",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Cole",
        "FullName": "Joe Cole",
        "Position": "MF",
        "Caps": "56",
        "Goals": "10",
        "DebutYear": "2001",
        "DebutOpponent": "Mexico"
    },
    {
        "Surname": "Yates",
        "FullName": "Jack Yates",
        "Position": "EW",
        "Caps": "1",
        "Goals": "3",
        "DebutYear": "1889",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Brittleton",
        "FullName": "Tom Brittleton",
        "Position": "HB",
        "Caps": "5",
        "Goals": "0",
        "DebutYear": "1912",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Davenport",
        "FullName": "William Bromley-Davenport",
        "Position": "FW",
        "Caps": "2",
        "Goals": "2",
        "DebutYear": "1884",
        "DebutOpponent": "Scotland"
    },
    {
        "Surname": "Watkins",
        "FullName": "Ollie Watkins",
        "Position": "FW",
        "Caps": "7",
        "Goals": "2",
        "DebutYear": "2021",
        "DebutOpponent": "San Marino"
    },
    {
        "Surname": "Whymark",
        "FullName": "Trevor Whymark",
        "Position": "MF",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1977",
        "DebutOpponent": "Luxembourg"
    },
    {
        "Surname": "Streten",
        "FullName": "Bernard Streten",
        "Position": "GK",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1949",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "OGrady",
        "FullName": "Michael O'Grady",
        "Position": "FW",
        "Caps": "2",
        "Goals": "3",
        "DebutYear": "1962",
        "DebutOpponent": "Northern Ireland"
    },
    {
        "Surname": "Gidman",
        "FullName": "John Gidman",
        "Position": "DF",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1977",
        "DebutOpponent": "Luxembourg"
    },
    {
        "Surname": "Lindsay",
        "FullName": "William Lindsay",
        "Position": "FB",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1877",
        "DebutOpponent": "Scotland"
    },
    {
        "Surname": "Raikes",
        "FullName": "George Raikes",
        "Position": "GK",
        "Caps": "4",
        "Goals": "0",
        "DebutYear": "1895",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Shawcross",
        "FullName": "Ryan Shawcross",
        "Position": "DF",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "2012",
        "DebutOpponent": "Sweden"
    },
    {
        "Surname": "Temple",
        "FullName": "Derek Temple",
        "Position": "FW",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1965",
        "DebutOpponent": "West Germany"
    },
    {
        "Surname": "Bowser",
        "FullName": "Sid Bowser",
        "Position": "HB",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1919",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Dorrell",
        "FullName": "Arthur Dorrell",
        "Position": "FW",
        "Caps": "4",
        "Goals": "1",
        "DebutYear": "1924",
        "DebutOpponent": "Belgium"
    },
    {
        "Surname": "Dixon",
        "FullName": "Kerry Dixon",
        "Position": "FW",
        "Caps": "8",
        "Goals": "4",
        "DebutYear": "1985",
        "DebutOpponent": "Mexico"
    },
    {
        "Surname": "Hunt",
        "FullName": "Kenneth Hunt",
        "Position": "HB",
        "Caps": "2",
        "Goals": "0",
        "DebutYear": "1911",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Hall",
        "FullName": "Willie Hall",
        "Position": "FW",
        "Caps": "10",
        "Goals": "9",
        "DebutYear": "1933",
        "DebutOpponent": "France"
    },
    {
        "Surname": "Lucas",
        "FullName": "Tommy Lucas",
        "Position": "FB",
        "Caps": "3",
        "Goals": "0",
        "DebutYear": "1921",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Gosling",
        "FullName": "Cunliffe Gosling",
        "Position": "FW",
        "Caps": "5",
        "Goals": "2",
        "DebutYear": "1892",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Sheringham",
        "FullName": "Teddy Sheringham",
        "Position": "FW",
        "Caps": "51",
        "Goals": "11",
        "DebutYear": "1993",
        "DebutOpponent": "Poland"
    },
    {
        "Surname": "Spouncer",
        "FullName": "Alf Spouncer",
        "Position": "FW",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1900",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Goodwyn",
        "FullName": "Alfred Goodwyn",
        "Position": "HB",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1873",
        "DebutOpponent": "Scotland"
    },
    {
        "Surname": "Warnock",
        "FullName": "Stephen Warnock",
        "Position": "DF",
        "Caps": "2",
        "Goals": "0",
        "DebutYear": "2008",
        "DebutOpponent": "Trinidad and Tobago"
    },
    {
        "Surname": "McInroy",
        "FullName": "Albert McInroy",
        "Position": "GK",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1926",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Gates",
        "FullName": "Eric Gates",
        "Position": "MF",
        "Caps": "2",
        "Goals": "0",
        "DebutYear": "1980",
        "DebutOpponent": "Norway"
    },
    {
        "Surname": "Bell",
        "FullName": "Colin Bell",
        "Position": "MF",
        "Caps": "48",
        "Goals": "9",
        "DebutYear": "1968",
        "DebutOpponent": "Sweden"
    },
    {
        "Surname": "Corrigan",
        "FullName": "Joe Corrigan",
        "Position": "GK",
        "Caps": "9",
        "Goals": "0",
        "DebutYear": "1976",
        "DebutOpponent": "Italy"
    },
    {
        "Surname": "Butt",
        "FullName": "Nicky Butt",
        "Position": "MF",
        "Caps": "39",
        "Goals": "0",
        "DebutYear": "1997",
        "DebutOpponent": "Mexico"
    },
    {
        "Surname": "Barclay",
        "FullName": "Bobby Barclay",
        "Position": "FW",
        "Caps": "3",
        "Goals": "2",
        "DebutYear": "1932",
        "DebutOpponent": "Scotland"
    },
    {
        "Surname": "Hardy",
        "FullName": "Harry Hardy",
        "Position": "GK",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1924",
        "DebutOpponent": "Belgium"
    },
    {
        "Surname": "Barmby",
        "FullName": "Nick Barmby",
        "Position": "MF",
        "Caps": "23",
        "Goals": "4",
        "DebutYear": "1995",
        "DebutOpponent": "Uruguay"
    },
    {
        "Surname": "Silcock",
        "FullName": "Jack Silcock",
        "Position": "FB",
        "Caps": "3",
        "Goals": "0",
        "DebutYear": "1921",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Blenkinsop",
        "FullName": "Ernie Blenkinsop",
        "Position": "FB",
        "Caps": "26",
        "Goals": "0",
        "DebutYear": "1928",
        "DebutOpponent": "France"
    },
    {
        "Surname": "Jones",
        "FullName": "Mick Jones",
        "Position": "FW",
        "Caps": "3",
        "Goals": "0",
        "DebutYear": "1965",
        "DebutOpponent": "West Germany"
    },
    {
        "Surname": "Clamp",
        "FullName": "Eddie Clamp",
        "Position": "HB",
        "Caps": "4",
        "Goals": "0",
        "DebutYear": "1958",
        "DebutOpponent": "Soviet Union"
    },
    {
        "Surname": "Wilkes",
        "FullName": "Albert Wilkes",
        "Position": "HB",
        "Caps": "5",
        "Goals": "1",
        "DebutYear": "1901",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Channon",
        "FullName": "Mick Channon",
        "Position": "FW",
        "Caps": "46",
        "Goals": "21",
        "DebutYear": "1972",
        "DebutOpponent": "Yugoslavia"
    },
    {
        "Surname": "Daley",
        "FullName": "Tony Daley",
        "Position": "MF",
        "Caps": "7",
        "Goals": "0",
        "DebutYear": "1991",
        "DebutOpponent": "Poland"
    },
    {
        "Surname": "Boyle",
        "FullName": "Tommy Boyle",
        "Position": "HB",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1913",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Britton",
        "FullName": "Cliff Britton",
        "Position": "HB",
        "Caps": "9",
        "Goals": "1",
        "DebutYear": "1934",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Beasley",
        "FullName": "Pat Beasley",
        "Position": "FW",
        "Caps": "1",
        "Goals": "1",
        "DebutYear": "1939",
        "DebutOpponent": "Scotland"
    },
    {
        "Surname": "Mullery",
        "FullName": "Alan Mullery",
        "Position": "MF",
        "Caps": "35",
        "Goals": "1",
        "DebutYear": "1964",
        "DebutOpponent": "Netherlands"
    },
    {
        "Surname": "Prowse",
        "FullName": "James Ward-Prowse",
        "Position": "MF",
        "Caps": "11",
        "Goals": "2",
        "DebutYear": "2017",
        "DebutOpponent": "Germany"
    },
    {
        "Surname": "Lawton",
        "FullName": "Tommy Lawton",
        "Position": "FW",
        "Caps": "23",
        "Goals": "22",
        "DebutYear": "1938",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Rawlinson",
        "FullName": "John Rawlinson",
        "Position": "GK",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1882",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Henry",
        "FullName": "Ron Henry",
        "Position": "FB",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1963",
        "DebutOpponent": "France"
    },
    {
        "Surname": "Barkas",
        "FullName": "Sam Barkas",
        "Position": "FB",
        "Caps": "5",
        "Goals": "0",
        "DebutYear": "1936",
        "DebutOpponent": "Belgium"
    },
    {
        "Surname": "Allen",
        "FullName": "Albert Allen",
        "Position": "FW",
        "Caps": "1",
        "Goals": "3",
        "DebutYear": "1888",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Viollet",
        "FullName": "Dennis Viollet",
        "Position": "FW",
        "Caps": "2",
        "Goals": "1",
        "DebutYear": "1960",
        "DebutOpponent": "Hungary"
    },
    {
        "Surname": "Hufton",
        "FullName": "Ted Hufton",
        "Position": "GK",
        "Caps": "6",
        "Goals": "0",
        "DebutYear": "1923",
        "DebutOpponent": "Belgium"
    },
    {
        "Surname": "Stevens",
        "FullName": "Gary Stevens",
        "Position": "DF",
        "Caps": "7",
        "Goals": "0",
        "DebutYear": "1984",
        "DebutOpponent": "Finland"
    },
    {
        "Surname": "Milne",
        "FullName": "Gordon Milne",
        "Position": "HB",
        "Caps": "14",
        "Goals": "0",
        "DebutYear": "1963",
        "DebutOpponent": "Brazil"
    },
    {
        "Surname": "McGuinness",
        "FullName": "Wilf McGuinness",
        "Position": "HB",
        "Caps": "2",
        "Goals": "0",
        "DebutYear": "1958",
        "DebutOpponent": "Northern Ireland"
    },
    {
        "Surname": "Pelly",
        "FullName": "Fred Pelly",
        "Position": "FB",
        "Caps": "3",
        "Goals": "0",
        "DebutYear": "1893",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Hoddle",
        "FullName": "Glenn Hoddle",
        "Position": "MF",
        "Caps": "53",
        "Goals": "8",
        "DebutYear": "1979",
        "DebutOpponent": "Bulgaria"
    },
    {
        "Surname": "Greenwood",
        "FullName": "Mason Greenwood",
        "Position": "FW",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "2020",
        "DebutOpponent": "Iceland"
    },
    {
        "Surname": "Smith",
        "FullName": "Bobby Smith",
        "Position": "FW",
        "Caps": "15",
        "Goals": "13",
        "DebutYear": "1960",
        "DebutOpponent": "Northern Ireland"
    },
    {
        "Surname": "Jarvis",
        "FullName": "Matt Jarvis",
        "Position": "MF",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "2011",
        "DebutOpponent": "Ghana"
    },
    {
        "Surname": "Hegan",
        "FullName": "Jackie Hegan",
        "Position": "FW",
        "Caps": "4",
        "Goals": "4",
        "DebutYear": "1923",
        "DebutOpponent": "Belgium"
    },
    {
        "Surname": "Allen",
        "FullName": "Jimmy Allen",
        "Position": "HB",
        "Caps": "2",
        "Goals": "0",
        "DebutYear": "1933",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Bradford",
        "FullName": "Geoff Bradford",
        "Position": "FW",
        "Caps": "1",
        "Goals": "1",
        "DebutYear": "1955",
        "DebutOpponent": "Denmark"
    },
    {
        "Surname": "Hall",
        "FullName": "Bert Hall",
        "Position": "FW",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1910",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Morley",
        "FullName": "Tony Morley",
        "Position": "MF",
        "Caps": "6",
        "Goals": "0",
        "DebutYear": "1981",
        "DebutOpponent": "Hungary"
    },
    {
        "Surname": "Barnes",
        "FullName": "Harvey Barnes",
        "Position": "FW",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "2020",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Mings",
        "FullName": "Tyrone Mings",
        "Position": "DF",
        "Caps": "17",
        "Goals": "2",
        "DebutYear": "2019",
        "DebutOpponent": "Bulgaria"
    },
    {
        "Surname": "Byrne",
        "FullName": "Gerry Byrne",
        "Position": "FB",
        "Caps": "2",
        "Goals": "0",
        "DebutYear": "1963",
        "DebutOpponent": "Scotland"
    },
    {
        "Surname": "Martin",
        "FullName": "Alvin Martin",
        "Position": "DF",
        "Caps": "17",
        "Goals": "0",
        "DebutYear": "1981",
        "DebutOpponent": "Brazil"
    },
    {
        "Surname": "Chilton",
        "FullName": "Allenby Chilton",
        "Position": "HB",
        "Caps": "2",
        "Goals": "0",
        "DebutYear": "1950",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Williamson",
        "FullName": "Ernest Williamson",
        "Position": "GK",
        "Caps": "2",
        "Goals": "0",
        "DebutYear": "1923",
        "DebutOpponent": "Sweden"
    },
    {
        "Surname": "Stoker",
        "FullName": "Lewis Stoker",
        "Position": "HB",
        "Caps": "3",
        "Goals": "0",
        "DebutYear": "1932",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Harvey",
        "FullName": "Colin Harvey",
        "Position": "MF",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1971",
        "DebutOpponent": "Malta"
    },
    {
        "Surname": "Sagar",
        "FullName": "Ted Sagar",
        "Position": "GK",
        "Caps": "4",
        "Goals": "0",
        "DebutYear": "1935",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Hargreaves",
        "FullName": "Owen Hargreaves",
        "Position": "MF / DF",
        "Caps": "42",
        "Goals": "0",
        "DebutYear": "2001",
        "DebutOpponent": "Netherlands"
    },
    {
        "Surname": "Seddon",
        "FullName": "Jimmy Seddon",
        "Position": "HB",
        "Caps": "6",
        "Goals": "0",
        "DebutYear": "1923",
        "DebutOpponent": "France"
    },
    {
        "Surname": "Bishop",
        "FullName": "Sid Bishop",
        "Position": "HB",
        "Caps": "4",
        "Goals": "1",
        "DebutYear": "1927",
        "DebutOpponent": "Scotland"
    },
    {
        "Surname": "Willis",
        "FullName": "Arthur Willis",
        "Position": "FB",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1951",
        "DebutOpponent": "France"
    },
    {
        "Surname": "Slater",
        "FullName": "Bill Slater",
        "Position": "HB",
        "Caps": "12",
        "Goals": "0",
        "DebutYear": "1954",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "McDermott",
        "FullName": "Terry McDermott",
        "Position": "MF",
        "Caps": "25",
        "Goals": "3",
        "DebutYear": "1977",
        "DebutOpponent": "Switzerland"
    },
    {
        "Surname": "Dobson",
        "FullName": "Alfred Dobson",
        "Position": "FB",
        "Caps": "4",
        "Goals": "0",
        "DebutYear": "1882",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Lewin",
        "FullName": "Dominic Calvert-Lewin",
        "Position": "FW",
        "Caps": "11",
        "Goals": "4",
        "DebutYear": "2020",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Smith",
        "FullName": "Tommy Smith",
        "Position": "HB",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1971",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Jarrett",
        "FullName": "Beaumont Jarrett",
        "Position": "HB",
        "Caps": "3",
        "Goals": "0",
        "DebutYear": "1876",
        "DebutOpponent": "Scotland"
    },
    {
        "Surname": "Barnes",
        "FullName": "Peter Barnes",
        "Position": "MF",
        "Caps": "22",
        "Goals": "4",
        "DebutYear": "1977",
        "DebutOpponent": "Italy"
    },
    {
        "Surname": "Taylor",
        "FullName": "Phil Taylor",
        "Position": "HB",
        "Caps": "3",
        "Goals": "0",
        "DebutYear": "1947",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Foden",
        "FullName": "Phil Foden",
        "Position": "MF",
        "Caps": "23",
        "Goals": "3",
        "DebutYear": "2020",
        "DebutOpponent": "Iceland"
    },
    {
        "Surname": "Spencer",
        "FullName": "Charlie Spencer",
        "Position": "HB",
        "Caps": "2",
        "Goals": "0",
        "DebutYear": "1924",
        "DebutOpponent": "Scotland"
    },
    {
        "Surname": "Blackburn",
        "FullName": "Fred Blackburn",
        "Position": "FW",
        "Caps": "3",
        "Goals": "1",
        "DebutYear": "1901",
        "DebutOpponent": "Scotland"
    },
    {
        "Surname": "Stephenson",
        "FullName": "Clem Stephenson",
        "Position": "FW",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1924",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Mew",
        "FullName": "Jack Mew",
        "Position": "GK",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1920",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Jezzard",
        "FullName": "Bedford Jezzard",
        "Position": "FW",
        "Caps": "2",
        "Goals": "0",
        "DebutYear": "1954",
        "DebutOpponent": "Hungary"
    },
    {
        "Surname": "Watson",
        "FullName": "Willie Watson",
        "Position": "HB",
        "Caps": "4",
        "Goals": "0",
        "DebutYear": "1949",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Barson",
        "FullName": "Frank Barson",
        "Position": "HB",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1920",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Baugh",
        "FullName": "Dickie Baugh",
        "Position": "FB",
        "Caps": "2",
        "Goals": "0",
        "DebutYear": "1886",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Colclough",
        "FullName": "Harry Colclough",
        "Position": "FB",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1914",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Brooks",
        "FullName": "Johnny Brooks",
        "Position": "FW",
        "Caps": "3",
        "Goals": "2",
        "DebutYear": "1956",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Kennedy",
        "FullName": "Alan Kennedy",
        "Position": "DF",
        "Caps": "2",
        "Goals": "0",
        "DebutYear": "1984",
        "DebutOpponent": "Northern Ireland"
    },
    {
        "Surname": "Westwood",
        "FullName": "Ray Westwood",
        "Position": "FW",
        "Caps": "6",
        "Goals": "0",
        "DebutYear": "1934",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Madeley",
        "FullName": "Paul Madeley",
        "Position": "DF / MF",
        "Caps": "24",
        "Goals": "0",
        "DebutYear": "1971",
        "DebutOpponent": "Northern Ireland"
    },
    {
        "Surname": "Chadwick",
        "FullName": "Edgar Chadwick",
        "Position": "FW",
        "Caps": "7",
        "Goals": "3",
        "DebutYear": "1891",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Beckham",
        "FullName": "David Beckham",
        "Position": "MF",
        "Caps": "115",
        "Goals": "17",
        "DebutYear": "1996",
        "DebutOpponent": "Moldova"
    },
    {
        "Surname": "Mills",
        "FullName": "Mick Mills",
        "Position": "DF / MF",
        "Caps": "42",
        "Goals": "0",
        "DebutYear": "1972",
        "DebutOpponent": "Yugoslavia"
    },
    {
        "Surname": "Ferdinand",
        "FullName": "Les Ferdinand",
        "Position": "FW",
        "Caps": "17",
        "Goals": "5",
        "DebutYear": "1993",
        "DebutOpponent": "San Marino"
    },
    {
        "Surname": "Spiksley",
        "FullName": "Fred Spiksley",
        "Position": "FW",
        "Caps": "7",
        "Goals": "7",
        "DebutYear": "1893",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Neal",
        "FullName": "Phil Neal",
        "Position": "DF",
        "Caps": "50",
        "Goals": "5",
        "DebutYear": "1976",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Crawford",
        "FullName": "Jackie Crawford",
        "Position": "FW",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1931",
        "DebutOpponent": "Scotland"
    },
    {
        "Surname": "Lowder",
        "FullName": "Arthur Lowder",
        "Position": "HB",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1889",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Iremonger",
        "FullName": "James Iremonger",
        "Position": "FB",
        "Caps": "2",
        "Goals": "0",
        "DebutYear": "1901",
        "DebutOpponent": "Scotland"
    },
    {
        "Surname": "Edwards",
        "FullName": "Duncan Edwards",
        "Position": "HB / FW",
        "Caps": "18",
        "Goals": "5",
        "DebutYear": "1955",
        "DebutOpponent": "Scotland"
    },
    {
        "Surname": "Russell",
        "FullName": "Bruce Russell",
        "Position": "FB",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1883",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Doggart",
        "FullName": "Graham Doggart",
        "Position": "FW",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1923",
        "DebutOpponent": "Belgium"
    },
    {
        "Surname": "Shellito",
        "FullName": "Ken Shellito",
        "Position": "FB",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1963",
        "DebutOpponent": "Czechoslovakia"
    },
    {
        "Surname": "Dickinson",
        "FullName": "Jimmy Dickinson",
        "Position": "HB",
        "Caps": "48",
        "Goals": "0",
        "DebutYear": "1949",
        "DebutOpponent": "Norway"
    },
    {
        "Surname": "ACourt",
        "FullName": "Alan A'Court",
        "Position": "FW",
        "Caps": "5",
        "Goals": "1",
        "DebutYear": "1957",
        "DebutOpponent": "Northern Ireland"
    },
    {
        "Surname": "Marshall",
        "FullName": "Thomas Marshall",
        "Position": "FW",
        "Caps": "2",
        "Goals": "0",
        "DebutYear": "1880",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Sturgess",
        "FullName": "Albert Sturgess",
        "Position": "HB",
        "Caps": "2",
        "Goals": "0",
        "DebutYear": "1911",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Houghton",
        "FullName": "Eric Houghton",
        "Position": "FW",
        "Caps": "7",
        "Goals": "5",
        "DebutYear": "1930",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Anderson",
        "FullName": "Stan Anderson",
        "Position": "HB",
        "Caps": "2",
        "Goals": "0",
        "DebutYear": "1962",
        "DebutOpponent": "Austria"
    },
    {
        "Surname": "Pejic",
        "FullName": "Mike Pejic",
        "Position": "DF",
        "Caps": "4",
        "Goals": "0",
        "DebutYear": "1974",
        "DebutOpponent": "Portugal"
    },
    {
        "Surname": "Hughes",
        "FullName": "Emlyn Hughes",
        "Position": "DF / MF",
        "Caps": "62",
        "Goals": "1",
        "DebutYear": "1969",
        "DebutOpponent": "Netherlands"
    },
    {
        "Surname": "Davis",
        "FullName": "Harry Davis",
        "Position": "FW",
        "Caps": "3",
        "Goals": "1",
        "DebutYear": "1903",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Greenhoff",
        "FullName": "Brian Greenhoff",
        "Position": "DF",
        "Caps": "18",
        "Goals": "0",
        "DebutYear": "1976",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Wood",
        "FullName": "Harry Wood",
        "Position": "FW",
        "Caps": "3",
        "Goals": "1",
        "DebutYear": "1890",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Ottaway",
        "FullName": "Cuthbert Ottaway",
        "Position": "FW",
        "Caps": "2",
        "Goals": "0",
        "DebutYear": "1872",
        "DebutOpponent": "Scotland"
    },
    {
        "Surname": "Howey",
        "FullName": "Steve Howey",
        "Position": "DF",
        "Caps": "4",
        "Goals": "0",
        "DebutYear": "1994",
        "DebutOpponent": "Nigeria"
    },
    {
        "Surname": "Chenery",
        "FullName": "Charles Chenery",
        "Position": "FW",
        "Caps": "3",
        "Goals": "1",
        "DebutYear": "1872",
        "DebutOpponent": "Scotland"
    },
    {
        "Surname": "Morren",
        "FullName": "Tommy Morren",
        "Position": "HB",
        "Caps": "1",
        "Goals": "1",
        "DebutYear": "1898",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Weller",
        "FullName": "Keith Weller",
        "Position": "FW",
        "Caps": "4",
        "Goals": "1",
        "DebutYear": "1974",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Baker",
        "FullName": "Benjamin Howard Baker",
        "Position": "GK",
        "Caps": "2",
        "Goals": "0",
        "DebutYear": "1921",
        "DebutOpponent": "Belgium"
    },
    {
        "Surname": "Hodkinson",
        "FullName": "Joseph Hodkinson",
        "Position": "FW",
        "Caps": "3",
        "Goals": "0",
        "DebutYear": "1913",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Molyneux",
        "FullName": "George Molyneux",
        "Position": "FB",
        "Caps": "4",
        "Goals": "0",
        "DebutYear": "1902",
        "DebutOpponent": "Scotland"
    },
    {
        "Surname": "Porteous",
        "FullName": "Tom Porteous",
        "Position": "FB",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1891",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Perry",
        "FullName": "Bill Perry",
        "Position": "FW",
        "Caps": "3",
        "Goals": "2",
        "DebutYear": "1955",
        "DebutOpponent": "Northern Ireland"
    },
    {
        "Surname": "Cresswell",
        "FullName": "Warney Cresswell",
        "Position": "FB",
        "Caps": "7",
        "Goals": "0",
        "DebutYear": "1921",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Alsford",
        "FullName": "Walter Alsford",
        "Position": "HB",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1935",
        "DebutOpponent": "Scotland"
    },
    {
        "Surname": "Pearson",
        "FullName": "Harold Pearson",
        "Position": "GK",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1932",
        "DebutOpponent": "Scotland"
    },
    {
        "Surname": "Fleming",
        "FullName": "Harold Fleming",
        "Position": "FW",
        "Caps": "11",
        "Goals": "9",
        "DebutYear": "1909",
        "DebutOpponent": "Scotland"
    },
    {
        "Surname": "Haines",
        "FullName": "Jack Haines",
        "Position": "FW",
        "Caps": "1",
        "Goals": "2",
        "DebutYear": "1948",
        "DebutOpponent": "Switzerland"
    },
    {
        "Surname": "Austin",
        "FullName": "Billy Austin",
        "Position": "FW",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1925",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Field",
        "FullName": "Edgar Field",
        "Position": "FB",
        "Caps": "2",
        "Goals": "0",
        "DebutYear": "1876",
        "DebutOpponent": "Scotland"
    },
    {
        "Surname": "Callaghan",
        "FullName": "Ian Callaghan",
        "Position": "MF",
        "Caps": "4",
        "Goals": "0",
        "DebutYear": "1966",
        "DebutOpponent": "Finland"
    },
    {
        "Surname": "Duxbury",
        "FullName": "Mike Duxbury",
        "Position": "DF",
        "Caps": "10",
        "Goals": "0",
        "DebutYear": "1983",
        "DebutOpponent": "Luxembourg"
    },
    {
        "Surname": "Thomas",
        "FullName": "Danny Thomas",
        "Position": "DF",
        "Caps": "2",
        "Goals": "0",
        "DebutYear": "1983",
        "DebutOpponent": "Australia"
    },
    {
        "Surname": "Francis",
        "FullName": "Trevor Francis",
        "Position": "FW",
        "Caps": "52",
        "Goals": "12",
        "DebutYear": "1977",
        "DebutOpponent": "Netherlands"
    },
    {
        "Surname": "Haynes",
        "FullName": "Johnny Haynes",
        "Position": "FW",
        "Caps": "56",
        "Goals": "18",
        "DebutYear": "1954",
        "DebutOpponent": "Northern Ireland"
    },
    {
        "Surname": "Carter",
        "FullName": "Joe Carter",
        "Position": "FW",
        "Caps": "3",
        "Goals": "4",
        "DebutYear": "1926",
        "DebutOpponent": "Belgium"
    },
    {
        "Surname": "Beats",
        "FullName": "Billy Beats",
        "Position": "FW",
        "Caps": "2",
        "Goals": "0",
        "DebutYear": "1901",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Hill",
        "FullName": "Jack Hill",
        "Position": "HB",
        "Caps": "11",
        "Goals": "0",
        "DebutYear": "1925",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Ashmore",
        "FullName": "George Ashmore",
        "Position": "GK",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1926",
        "DebutOpponent": "Belgium"
    },
    {
        "Surname": "Birley",
        "FullName": "Francis Birley",
        "Position": "HB",
        "Caps": "2",
        "Goals": "0",
        "DebutYear": "1874",
        "DebutOpponent": "Scotland"
    },
    {
        "Surname": "Ramsey",
        "FullName": "Alf Ramsey",
        "Position": "FB",
        "Caps": "32",
        "Goals": "3",
        "DebutYear": "1948",
        "DebutOpponent": "Switzerland"
    },
    {
        "Surname": "Hardwick",
        "FullName": "George Hardwick",
        "Position": "FB",
        "Caps": "13",
        "Goals": "0",
        "DebutYear": "1946",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Holden",
        "FullName": "George Holden",
        "Position": "FW",
        "Caps": "4",
        "Goals": "0",
        "DebutYear": "1881",
        "DebutOpponent": "Scotland"
    },
    {
        "Surname": "Sunderland",
        "FullName": "Alan Sunderland",
        "Position": "FW",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1980",
        "DebutOpponent": "Australia"
    },
    {
        "Surname": "Shutt",
        "FullName": "George Shutt",
        "Position": "HB",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1886",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Tueart",
        "FullName": "Dennis Tueart",
        "Position": "FW",
        "Caps": "6",
        "Goals": "2",
        "DebutYear": "1975",
        "DebutOpponent": "Cyprus"
    },
    {
        "Surname": "Mitchell",
        "FullName": "Tyrick Mitchell",
        "Position": "DF",
        "Caps": "2",
        "Goals": "0",
        "DebutYear": "2022",
        "DebutOpponent": "Switzerland"
    },
    {
        "Surname": "Ufton",
        "FullName": "Derek Ufton",
        "Position": "HB",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1953",
        "DebutOpponent": "Rest of Europe"
    },
    {
        "Surname": "Sansom",
        "FullName": "Kenny Sansom",
        "Position": "DF",
        "Caps": "86",
        "Goals": "1",
        "DebutYear": "1979",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Sancho",
        "FullName": "Jadon Sancho",
        "Position": "FW",
        "Caps": "23",
        "Goals": "3",
        "DebutYear": "2018",
        "DebutOpponent": "Croatia"
    },
    {
        "Surname": "Brown",
        "FullName": "Wes Brown",
        "Position": "DF",
        "Caps": "23",
        "Goals": "1",
        "DebutYear": "1999",
        "DebutOpponent": "Hungary"
    },
    {
        "Surname": "Pantling",
        "FullName": "Harry Pantling",
        "Position": "HB",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1923",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Milburn",
        "FullName": "Jackie Milburn",
        "Position": "FW",
        "Caps": "13",
        "Goals": "10",
        "DebutYear": "1948",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Wallace",
        "FullName": "Danny Wallace",
        "Position": "FW",
        "Caps": "1",
        "Goals": "1",
        "DebutYear": "1986",
        "DebutOpponent": "Egypt"
    },
    {
        "Surname": "Thomas",
        "FullName": "Geoff Thomas",
        "Position": "MF",
        "Caps": "9",
        "Goals": "0",
        "DebutYear": "1991",
        "DebutOpponent": "Turkey"
    },
    {
        "Surname": "Ball",
        "FullName": "Jack Ball",
        "Position": "FW",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1927",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Mortensen",
        "FullName": "Stan Mortensen",
        "Position": "FW",
        "Caps": "25",
        "Goals": "23",
        "DebutYear": "1947",
        "DebutOpponent": "Portugal"
    },
    {
        "Surname": "Buchan",
        "FullName": "Charlie Buchan",
        "Position": "FW",
        "Caps": "6",
        "Goals": "4",
        "DebutYear": "1913",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Bain",
        "FullName": "John Bain",
        "Position": "FW",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1877",
        "DebutOpponent": "Scotland"
    },
    {
        "Surname": "Earle",
        "FullName": "Stan Earle",
        "Position": "FW",
        "Caps": "2",
        "Goals": "0",
        "DebutYear": "1924",
        "DebutOpponent": "France"
    },
    {
        "Surname": "Watson",
        "FullName": "Vic Watson",
        "Position": "FW",
        "Caps": "5",
        "Goals": "4",
        "DebutYear": "1923",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Read",
        "FullName": "Albert Read",
        "Position": "HB",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1921",
        "DebutOpponent": "Belgium"
    },
    {
        "Surname": "Baker",
        "FullName": "Alf Baker",
        "Position": "HB",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1927",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Hawtrey",
        "FullName": "John Hawtrey",
        "Position": "GK",
        "Caps": "2",
        "Goals": "0",
        "DebutYear": "1881",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Dunn",
        "FullName": "David Dunn",
        "Position": "MF",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "2002",
        "DebutOpponent": "Portugal"
    },
    {
        "Surname": "Brown",
        "FullName": "Charles Wreford-Brown",
        "Position": "HB",
        "Caps": "4",
        "Goals": "0",
        "DebutYear": "1889",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Robinson",
        "FullName": "Paul Robinson",
        "Position": "GK",
        "Caps": "41",
        "Goals": "0",
        "DebutYear": "2003",
        "DebutOpponent": "Australia"
    },
    {
        "Surname": "Cole",
        "FullName": "Ashley Cole",
        "Position": "DF",
        "Caps": "107",
        "Goals": "0",
        "DebutYear": "2001",
        "DebutOpponent": "Albania"
    },
    {
        "Surname": "Oakley",
        "FullName": "William Oakley",
        "Position": "FB",
        "Caps": "16",
        "Goals": "0",
        "DebutYear": "1895",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Barkley",
        "FullName": "Ross Barkley",
        "Position": "MF",
        "Caps": "33",
        "Goals": "6",
        "DebutYear": "2013",
        "DebutOpponent": "Moldova"
    },
    {
        "Surname": "Viljoen",
        "FullName": "Colin Viljoen",
        "Position": "MF",
        "Caps": "2",
        "Goals": "0",
        "DebutYear": "1975",
        "DebutOpponent": "Northern Ireland"
    },
    {
        "Surname": "Woodley",
        "FullName": "Vic Woodley",
        "Position": "GK",
        "Caps": "19",
        "Goals": "0",
        "DebutYear": "1937",
        "DebutOpponent": "Scotland"
    },
    {
        "Surname": "Unsworth",
        "FullName": "David Unsworth",
        "Position": "DF",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1995",
        "DebutOpponent": "Japan"
    },
    {
        "Surname": "Bowden",
        "FullName": "Ray Bowden",
        "Position": "FW",
        "Caps": "6",
        "Goals": "1",
        "DebutYear": "1934",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Elliott",
        "FullName": "Billy Elliott",
        "Position": "FW",
        "Caps": "5",
        "Goals": "3",
        "DebutYear": "1952",
        "DebutOpponent": "Italy"
    },
    {
        "Surname": "Parker",
        "FullName": "Tom Parker",
        "Position": "FB",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1925",
        "DebutOpponent": "France"
    },
    {
        "Surname": "Ripley",
        "FullName": "Stuart Ripley",
        "Position": "MF",
        "Caps": "2",
        "Goals": "0",
        "DebutYear": "1993",
        "DebutOpponent": "San Marino"
    },
    {
        "Surname": "Cooper",
        "FullName": "Terry Cooper",
        "Position": "DF",
        "Caps": "20",
        "Goals": "0",
        "DebutYear": "1969",
        "DebutOpponent": "France"
    },
    {
        "Surname": "Bury",
        "FullName": "Lindsay Bury",
        "Position": "FB",
        "Caps": "2",
        "Goals": "0",
        "DebutYear": "1877",
        "DebutOpponent": "Scotland"
    },
    {
        "Surname": "Butland",
        "FullName": "Jack Butland",
        "Position": "GK",
        "Caps": "9",
        "Goals": "0",
        "DebutYear": "2012",
        "DebutOpponent": "Italy"
    },
    {
        "Surname": "Edwards",
        "FullName": "Willis Edwards",
        "Position": "HB",
        "Caps": "16",
        "Goals": "0",
        "DebutYear": "1926",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Hodgkinson",
        "FullName": "Alan Hodgkinson",
        "Position": "GK",
        "Caps": "5",
        "Goals": "0",
        "DebutYear": "1957",
        "DebutOpponent": "Scotland"
    },
    {
        "Surname": "James",
        "FullName": "Reece James",
        "Position": "DF",
        "Caps": "16",
        "Goals": "0",
        "DebutYear": "2020",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "McNeal",
        "FullName": "Bobby McNeal",
        "Position": "HB",
        "Caps": "2",
        "Goals": "0",
        "DebutYear": "1914",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Blissett",
        "FullName": "Luther Blissett",
        "Position": "FW",
        "Caps": "14",
        "Goals": "3",
        "DebutYear": "1982",
        "DebutOpponent": "West Germany"
    },
    {
        "Surname": "Galley",
        "FullName": "Tom Galley",
        "Position": "FW",
        "Caps": "2",
        "Goals": "1",
        "DebutYear": "1937",
        "DebutOpponent": "Norway"
    },
    {
        "Surname": "Creek",
        "FullName": "Norman Creek",
        "Position": "FW",
        "Caps": "1",
        "Goals": "1",
        "DebutYear": "1923",
        "DebutOpponent": "France"
    },
    {
        "Surname": "Wignall",
        "FullName": "Frank Wignall",
        "Position": "FW",
        "Caps": "2",
        "Goals": "2",
        "DebutYear": "1964",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Coleman",
        "FullName": "Bert Coleman",
        "Position": "GK",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1921",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Smith",
        "FullName": "Joe Smith",
        "Position": "FW",
        "Caps": "5",
        "Goals": "1",
        "DebutYear": "1913",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Wright",
        "FullName": "Gordon Wright",
        "Position": "FW",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1906",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Ward",
        "FullName": "Jimmy Ward",
        "Position": "FB",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1885",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Dix",
        "FullName": "Ronnie Dix",
        "Position": "FW",
        "Caps": "1",
        "Goals": "1",
        "DebutYear": "1938",
        "DebutOpponent": "Norway"
    },
    {
        "Surname": "Perry",
        "FullName": "Charlie Perry",
        "Position": "HB",
        "Caps": "3",
        "Goals": "0",
        "DebutYear": "1890",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Sterling",
        "FullName": "Raheem Sterling",
        "Position": "MF / FW",
        "Caps": "82",
        "Goals": "20",
        "DebutYear": "2012",
        "DebutOpponent": "Sweden"
    },
    {
        "Surname": "Cunliffe",
        "FullName": "Arthur Cunliffe",
        "Position": "FW",
        "Caps": "2",
        "Goals": "0",
        "DebutYear": "1932",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Wilcox",
        "FullName": "Jason Wilcox",
        "Position": "MF",
        "Caps": "3",
        "Goals": "0",
        "DebutYear": "1996",
        "DebutOpponent": "Hungary"
    },
    {
        "Surname": "Jones",
        "FullName": "Harry Jones",
        "Position": "FB",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1923",
        "DebutOpponent": "France"
    },
    {
        "Surname": "Smith",
        "FullName": "Reg Smith",
        "Position": "FW",
        "Caps": "2",
        "Goals": "2",
        "DebutYear": "1938",
        "DebutOpponent": "Scotland"
    },
    {
        "Surname": "Miller",
        "FullName": "Harold Miller",
        "Position": "FW",
        "Caps": "1",
        "Goals": "1",
        "DebutYear": "1923",
        "DebutOpponent": "Sweden"
    },
    {
        "Surname": "Tambling",
        "FullName": "Bobby Tambling",
        "Position": "FW",
        "Caps": "3",
        "Goals": "1",
        "DebutYear": "1962",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Crawshaw",
        "FullName": "Tommy Crawshaw",
        "Position": "HB",
        "Caps": "10",
        "Goals": "1",
        "DebutYear": "1895",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Donop",
        "FullName": "Pelham von Donop",
        "Position": "FW",
        "Caps": "2",
        "Goals": "0",
        "DebutYear": "1873",
        "DebutOpponent": "Scotland"
    },
    {
        "Surname": "Quantrill",
        "FullName": "Alf Quantrill",
        "Position": "FW",
        "Caps": "4",
        "Goals": "1",
        "DebutYear": "1920",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Wolstenholme",
        "FullName": "Samuel Wolstenholme",
        "Position": "HB",
        "Caps": "3",
        "Goals": "0",
        "DebutYear": "1904",
        "DebutOpponent": "Scotland"
    },
    {
        "Surname": "Bayliss",
        "FullName": "Jem Bayliss",
        "Position": "HB",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1891",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Bailey",
        "FullName": "Horace Bailey",
        "Position": "GK",
        "Caps": "5",
        "Goals": "0",
        "DebutYear": "1908",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Dorigo",
        "FullName": "Tony Dorigo",
        "Position": "DF",
        "Caps": "15",
        "Goals": "0",
        "DebutYear": "1989",
        "DebutOpponent": "Yugoslavia"
    },
    {
        "Surname": "Lee",
        "FullName": "Rob Lee",
        "Position": "MF / DF",
        "Caps": "21",
        "Goals": "2",
        "DebutYear": "1994",
        "DebutOpponent": "Romania"
    },
    {
        "Surname": "Hall",
        "FullName": "Jeff Hall",
        "Position": "FB",
        "Caps": "17",
        "Goals": "0",
        "DebutYear": "1955",
        "DebutOpponent": "Denmark"
    },
    {
        "Surname": "Wylie",
        "FullName": "John Wylie",
        "Position": "FW",
        "Caps": "1",
        "Goals": "1",
        "DebutYear": "1878",
        "DebutOpponent": "Scotland"
    },
    {
        "Surname": "Osman",
        "FullName": "Leon Osman",
        "Position": "MF",
        "Caps": "2",
        "Goals": "0",
        "DebutYear": "2012",
        "DebutOpponent": "Sweden"
    },
    {
        "Surname": "Dawson",
        "FullName": "Jerry Dawson",
        "Position": "GK",
        "Caps": "2",
        "Goals": "0",
        "DebutYear": "1921",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Revie",
        "FullName": "Don Revie",
        "Position": "FW",
        "Caps": "6",
        "Goals": "4",
        "DebutYear": "1954",
        "DebutOpponent": "Northern Ireland"
    },
    {
        "Surname": "Prinsep",
        "FullName": "James Prinsep",
        "Position": "HB",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1879",
        "DebutOpponent": "Scotland"
    },
    {
        "Surname": "Sands",
        "FullName": "John Sands",
        "Position": "GK",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1880",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Phillips",
        "FullName": "Len Phillips",
        "Position": "FW",
        "Caps": "3",
        "Goals": "0",
        "DebutYear": "1951",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Wollaston",
        "FullName": "Charles Wollaston",
        "Position": "FW",
        "Caps": "4",
        "Goals": "1",
        "DebutYear": "1874",
        "DebutOpponent": "Scotland"
    },
    {
        "Surname": "Dyer",
        "FullName": "Kieron Dyer",
        "Position": "MF / DF",
        "Caps": "33",
        "Goals": "0",
        "DebutYear": "1999",
        "DebutOpponent": "Luxembourg"
    },
    {
        "Surname": "Holden",
        "FullName": "Doug Holden",
        "Position": "FW",
        "Caps": "5",
        "Goals": "0",
        "DebutYear": "1959",
        "DebutOpponent": "Scotland"
    },
    {
        "Surname": "Rowley",
        "FullName": "Bill Rowley",
        "Position": "GK",
        "Caps": "2",
        "Goals": "0",
        "DebutYear": "1889",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Hunt",
        "FullName": "Roger Hunt",
        "Position": "FW",
        "Caps": "34",
        "Goals": "18",
        "DebutYear": "1962",
        "DebutOpponent": "Austria"
    },
    {
        "Surname": "Thompson",
        "FullName": "Phil Thompson",
        "Position": "DF",
        "Caps": "42",
        "Goals": "1",
        "DebutYear": "1976",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Underwood",
        "FullName": "Alf Underwood",
        "Position": "HB",
        "Caps": "2",
        "Goals": "0",
        "DebutYear": "1891",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Hardman",
        "FullName": "Harold Hardman",
        "Position": "FW",
        "Caps": "4",
        "Goals": "1",
        "DebutYear": "1905",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Chilwell",
        "FullName": "Ben Chilwell",
        "Position": "DF",
        "Caps": "18",
        "Goals": "1",
        "DebutYear": "2018",
        "DebutOpponent": "Switzerland"
    },
    {
        "Surname": "Mitchell",
        "FullName": "James Mitchell",
        "Position": "GK",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1924",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Currey",
        "FullName": "Edmund Currey",
        "Position": "FW",
        "Caps": "2",
        "Goals": "2",
        "DebutYear": "1890",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Crouch",
        "FullName": "Peter Crouch",
        "Position": "FW",
        "Caps": "42",
        "Goals": "22",
        "DebutYear": "2005",
        "DebutOpponent": "Colombia"
    },
    {
        "Surname": "Arnold",
        "FullName": "Trent Alexander-Arnold",
        "Position": "DF",
        "Caps": "18",
        "Goals": "1",
        "DebutYear": "2018",
        "DebutOpponent": "Costa Rica"
    },
    {
        "Surname": "Bradley",
        "FullName": "Warren Bradley",
        "Position": "FW",
        "Caps": "3",
        "Goals": "2",
        "DebutYear": "1959",
        "DebutOpponent": "Italy"
    },
    {
        "Surname": "Strange",
        "FullName": "Alfred Strange",
        "Position": "HB",
        "Caps": "20",
        "Goals": "0",
        "DebutYear": "1930",
        "DebutOpponent": "Scotland"
    },
    {
        "Surname": "Boyes",
        "FullName": "Walter Boyes",
        "Position": "FW",
        "Caps": "3",
        "Goals": "0",
        "DebutYear": "1935",
        "DebutOpponent": "Netherlands"
    },
    {
        "Surname": "Harrison",
        "FullName": "Alban Harrison",
        "Position": "HB",
        "Caps": "2",
        "Goals": "0",
        "DebutYear": "1892",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "White",
        "FullName": "Ben White",
        "Position": "DF",
        "Caps": "4",
        "Goals": "0",
        "DebutYear": "2021",
        "DebutOpponent": "Austria"
    },
    {
        "Surname": "Luntley",
        "FullName": "Edwin Luntley",
        "Position": "FB",
        "Caps": "2",
        "Goals": "0",
        "DebutYear": "1880",
        "DebutOpponent": "Scotland"
    },
    {
        "Surname": "Ball",
        "FullName": "Alan Ball",
        "Position": "FW",
        "Caps": "72",
        "Goals": "8",
        "DebutYear": "1965",
        "DebutOpponent": "Yugoslavia"
    },
    {
        "Surname": "Johnson",
        "FullName": "David Johnson",
        "Position": "FW",
        "Caps": "8",
        "Goals": "6",
        "DebutYear": "1975",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Thompson",
        "FullName": "Peter Thompson",
        "Position": "FW / MF",
        "Caps": "16",
        "Goals": "0",
        "DebutYear": "1964",
        "DebutOpponent": "Portugal"
    },
    {
        "Surname": "Devey",
        "FullName": "Jack Devey",
        "Position": "FW",
        "Caps": "2",
        "Goals": "1",
        "DebutYear": "1892",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Ogilvie",
        "FullName": "Robert Ogilvie",
        "Position": "FB",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1874",
        "DebutOpponent": "Scotland"
    },
    {
        "Surname": "Macrae",
        "FullName": "Stuart Macrae",
        "Position": "HB",
        "Caps": "6",
        "Goals": "0",
        "DebutYear": "1883",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Cottle",
        "FullName": "Joe Cottle",
        "Position": "FB",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1909",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Stratford",
        "FullName": "Alfred Stratford",
        "Position": "FB",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1874",
        "DebutOpponent": "Scotland"
    },
    {
        "Surname": "Young",
        "FullName": "Luke Young",
        "Position": "DF",
        "Caps": "7",
        "Goals": "0",
        "DebutYear": "2005",
        "DebutOpponent": "United States"
    },
    {
        "Surname": "Barrett",
        "FullName": "Jim Barrett",
        "Position": "HB",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1928",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Reid",
        "FullName": "Peter Reid",
        "Position": "MF",
        "Caps": "13",
        "Goals": "0",
        "DebutYear": "1985",
        "DebutOpponent": "Mexico"
    },
    {
        "Surname": "Anderton",
        "FullName": "Darren Anderton",
        "Position": "MF / DF",
        "Caps": "30",
        "Goals": "7",
        "DebutYear": "1994",
        "DebutOpponent": "Denmark"
    },
    {
        "Surname": "Keane",
        "FullName": "Michael Keane",
        "Position": "DF",
        "Caps": "12",
        "Goals": "1",
        "DebutYear": "2017",
        "DebutOpponent": "Germany"
    },
    {
        "Surname": "Holford",
        "FullName": "Tom Holford",
        "Position": "HB",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1903",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Allen",
        "FullName": "Harry Allen",
        "Position": "HB",
        "Caps": "5",
        "Goals": "0",
        "DebutYear": "1888",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Matthews",
        "FullName": "Vincent Matthews",
        "Position": "HB",
        "Caps": "2",
        "Goals": "1",
        "DebutYear": "1928",
        "DebutOpponent": "France"
    },
    {
        "Surname": "Charlton",
        "FullName": "Bobby Charlton",
        "Position": "FW / MF",
        "Caps": "106",
        "Goals": "49",
        "DebutYear": "1958",
        "DebutOpponent": "Scotland"
    },
    {
        "Surname": "Alcock",
        "FullName": "Charles Alcock",
        "Position": "FW",
        "Caps": "1",
        "Goals": "1",
        "DebutYear": "1875",
        "DebutOpponent": "Scotland"
    },
    {
        "Surname": "Saka",
        "FullName": "Bukayo Saka",
        "Position": "FW",
        "Caps": "26",
        "Goals": "8",
        "DebutYear": "2020",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Armstrong",
        "FullName": "David Armstrong",
        "Position": "MF",
        "Caps": "3",
        "Goals": "0",
        "DebutYear": "1980",
        "DebutOpponent": "Australia"
    },
    {
        "Surname": "Newton",
        "FullName": "Keith Newton",
        "Position": "DF",
        "Caps": "27",
        "Goals": "0",
        "DebutYear": "1966",
        "DebutOpponent": "West Germany"
    },
    {
        "Surname": "Calvey",
        "FullName": "John Calvey",
        "Position": "FW",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1902",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Labone",
        "FullName": "Brian Labone",
        "Position": "DF",
        "Caps": "26",
        "Goals": "0",
        "DebutYear": "1962",
        "DebutOpponent": "Northern Ireland"
    },
    {
        "Surname": "Copping",
        "FullName": "Wilf Copping",
        "Position": "HB",
        "Caps": "20",
        "Goals": "0",
        "DebutYear": "1933",
        "DebutOpponent": "Italy"
    },
    {
        "Surname": "Vardy",
        "FullName": "Jamie Vardy",
        "Position": "FW",
        "Caps": "26",
        "Goals": "7",
        "DebutYear": "2015",
        "DebutOpponent": "Republic of Ireland"
    },
    {
        "Surname": "Jones",
        "FullName": "Bill Jones",
        "Position": "HB",
        "Caps": "2",
        "Goals": "0",
        "DebutYear": "1950",
        "DebutOpponent": "Portugal"
    },
    {
        "Surname": "Smith",
        "FullName": "Bert Smith",
        "Position": "HB",
        "Caps": "2",
        "Goals": "0",
        "DebutYear": "1921",
        "DebutOpponent": "Scotland"
    },
    {
        "Surname": "Barry",
        "FullName": "Leonard Barry",
        "Position": "FW",
        "Caps": "5",
        "Goals": "0",
        "DebutYear": "1928",
        "DebutOpponent": "France"
    },
    {
        "Surname": "Gilliat",
        "FullName": "Walter Gilliat",
        "Position": "FW",
        "Caps": "1",
        "Goals": "3",
        "DebutYear": "1893",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Vaughton",
        "FullName": "Howard Vaughton",
        "Position": "FW",
        "Caps": "5",
        "Goals": "6",
        "DebutYear": "1882",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Clay",
        "FullName": "Thomas Clay",
        "Position": "FB",
        "Caps": "4",
        "Goals": "0",
        "DebutYear": "1920",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Moss",
        "FullName": "Frank Moss",
        "Position": "GK",
        "Caps": "4",
        "Goals": "0",
        "DebutYear": "1934",
        "DebutOpponent": "Scotland"
    },
    {
        "Surname": "Osman",
        "FullName": "Russell Osman",
        "Position": "DF",
        "Caps": "11",
        "Goals": "0",
        "DebutYear": "1980",
        "DebutOpponent": "Australia"
    },
    {
        "Surname": "Hunter",
        "FullName": "Norman Hunter",
        "Position": "DF",
        "Caps": "28",
        "Goals": "2",
        "DebutYear": "1965",
        "DebutOpponent": "Spain"
    },
    {
        "Surname": "Defoe",
        "FullName": "Jermain Defoe",
        "Position": "FW",
        "Caps": "57",
        "Goals": "20",
        "DebutYear": "2004",
        "DebutOpponent": "Sweden"
    },
    {
        "Surname": "Fenwick",
        "FullName": "Terry Fenwick",
        "Position": "DF",
        "Caps": "20",
        "Goals": "0",
        "DebutYear": "1984",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Bailey",
        "FullName": "Norman Bailey",
        "Position": "HB",
        "Caps": "19",
        "Goals": "2",
        "DebutYear": "1878",
        "DebutOpponent": "Scotland"
    },
    {
        "Surname": "Starling",
        "FullName": "Ronnie Starling",
        "Position": "FW",
        "Caps": "2",
        "Goals": "0",
        "DebutYear": "1933",
        "DebutOpponent": "Scotland"
    },
    {
        "Surname": "Ewer",
        "FullName": "Fred Ewer",
        "Position": "HB",
        "Caps": "2",
        "Goals": "0",
        "DebutYear": "1924",
        "DebutOpponent": "France"
    },
    {
        "Surname": "Hudson",
        "FullName": "Jack Hudson",
        "Position": "HB",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1883",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Bracewell",
        "FullName": "Paul Bracewell",
        "Position": "MF",
        "Caps": "3",
        "Goals": "0",
        "DebutYear": "1985",
        "DebutOpponent": "West Germany"
    },
    {
        "Surname": "Whateley",
        "FullName": "Oliver Whateley",
        "Position": "FW",
        "Caps": "2",
        "Goals": "2",
        "DebutYear": "1883",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Wilson",
        "FullName": "Claude Wilson",
        "Position": "FB",
        "Caps": "2",
        "Goals": "0",
        "DebutYear": "1879",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Henfrey",
        "FullName": "Arthur Henfrey",
        "Position": "FW",
        "Caps": "5",
        "Goals": "2",
        "DebutYear": "1891",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Regis",
        "FullName": "Cyrille Regis",
        "Position": "FW",
        "Caps": "5",
        "Goals": "0",
        "DebutYear": "1982",
        "DebutOpponent": "Northern Ireland"
    },
    {
        "Surname": "Webb",
        "FullName": "George Webb",
        "Position": "FW",
        "Caps": "2",
        "Goals": "1",
        "DebutYear": "1911",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Turner",
        "FullName": "Jimmy Turner",
        "Position": "HB",
        "Caps": "3",
        "Goals": "0",
        "DebutYear": "1893",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Bloomer",
        "FullName": "Steve Bloomer",
        "Position": "FW",
        "Caps": "23",
        "Goals": "28",
        "DebutYear": "1900",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Townley",
        "FullName": "William Townley",
        "Position": "FW",
        "Caps": "2",
        "Goals": "2",
        "DebutYear": "1889",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Gray",
        "FullName": "Michael Gray",
        "Position": "DF",
        "Caps": "3",
        "Goals": "0",
        "DebutYear": "1999",
        "DebutOpponent": "Hungary"
    },
    {
        "Surname": "Bastard",
        "FullName": "Segar Bastard",
        "Position": "FW",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1880",
        "DebutOpponent": "Scotland"
    },
    {
        "Surname": "Swift",
        "FullName": "Frank Swift",
        "Position": "GK",
        "Caps": "19",
        "Goals": "0",
        "DebutYear": "1946",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Ditchburn",
        "FullName": "Ted Ditchburn",
        "Position": "GK",
        "Caps": "6",
        "Goals": "0",
        "DebutYear": "1948",
        "DebutOpponent": "Switzerland"
    },
    {
        "Surname": "Redknapp",
        "FullName": "Jamie Redknapp",
        "Position": "MF",
        "Caps": "17",
        "Goals": "1",
        "DebutYear": "1995",
        "DebutOpponent": "Colombia"
    },
    {
        "Surname": "Hine",
        "FullName": "Ernie Hine",
        "Position": "FW",
        "Caps": "6",
        "Goals": "4",
        "DebutYear": "1928",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Bertrand",
        "FullName": "Ryan Bertrand",
        "Position": "DF",
        "Caps": "19",
        "Goals": "1",
        "DebutYear": "2012",
        "DebutOpponent": "Italy"
    },
    {
        "Surname": "Leach",
        "FullName": "Tony Leach",
        "Position": "HB",
        "Caps": "2",
        "Goals": "0",
        "DebutYear": "1930",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Clyne",
        "FullName": "Nathaniel Clyne",
        "Position": "DF",
        "Caps": "14",
        "Goals": "0",
        "DebutYear": "2014",
        "DebutOpponent": "Slovenia"
    },
    {
        "Surname": "Rawson",
        "FullName": "William Rawson",
        "Position": "HB",
        "Caps": "2",
        "Goals": "0",
        "DebutYear": "1875",
        "DebutOpponent": "Scotland"
    },
    {
        "Surname": "Oliver",
        "FullName": "Len Oliver",
        "Position": "HB",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1929",
        "DebutOpponent": "Belgium"
    },
    {
        "Surname": "Hodgetts",
        "FullName": "Dennis Hodgetts",
        "Position": "FW",
        "Caps": "6",
        "Goals": "1",
        "DebutYear": "1888",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Graham",
        "FullName": "Tommy Graham",
        "Position": "HB",
        "Caps": "2",
        "Goals": "0",
        "DebutYear": "1931",
        "DebutOpponent": "France"
    },
    {
        "Surname": "Simpson",
        "FullName": "Jock Simpson",
        "Position": "FW",
        "Caps": "8",
        "Goals": "1",
        "DebutYear": "1911",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Fowler",
        "FullName": "Robbie Fowler",
        "Position": "FW",
        "Caps": "26",
        "Goals": "7",
        "DebutYear": "1996",
        "DebutOpponent": "Bulgaria"
    },
    {
        "Surname": "Wilkinson",
        "FullName": "Bernard Wilkinson",
        "Position": "HB",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1904",
        "DebutOpponent": "Scotland"
    },
    {
        "Surname": "Banks",
        "FullName": "Tommy Banks",
        "Position": "FB",
        "Caps": "6",
        "Goals": "0",
        "DebutYear": "1958",
        "DebutOpponent": "Soviet Union"
    },
    {
        "Surname": "Lennon",
        "FullName": "Aaron Lennon",
        "Position": "MF / FW",
        "Caps": "21",
        "Goals": "0",
        "DebutYear": "2006",
        "DebutOpponent": "Jamaica"
    },
    {
        "Surname": "Aldridge",
        "FullName": "Albert Aldridge",
        "Position": "FB",
        "Caps": "2",
        "Goals": "0",
        "DebutYear": "1888",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Bray",
        "FullName": "Jackie Bray",
        "Position": "HB",
        "Caps": "6",
        "Goals": "0",
        "DebutYear": "1934",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Webb",
        "FullName": "Neil Webb",
        "Position": "MF",
        "Caps": "26",
        "Goals": "4",
        "DebutYear": "1987",
        "DebutOpponent": "West Germany"
    },
    {
        "Surname": "Ings",
        "FullName": "Danny Ings",
        "Position": "FW",
        "Caps": "3",
        "Goals": "1",
        "DebutYear": "2015",
        "DebutOpponent": "Lithuania"
    },
    {
        "Surname": "Devonshire",
        "FullName": "Alan Devonshire",
        "Position": "MF",
        "Caps": "8",
        "Goals": "0",
        "DebutYear": "1980",
        "DebutOpponent": "Northern Ireland"
    },
    {
        "Surname": "Vaughan",
        "FullName": "Lewis Vaughan Lodge",
        "Position": "FB",
        "Caps": "5",
        "Goals": "0",
        "DebutYear": "1894",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Nuttall",
        "FullName": "Harry Nuttall",
        "Position": "HB",
        "Caps": "3",
        "Goals": "0",
        "DebutYear": "1927",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Smith",
        "FullName": "Sep Smith",
        "Position": "HB",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1935",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Mount",
        "FullName": "Mason Mount",
        "Position": "MF",
        "Caps": "36",
        "Goals": "5",
        "DebutYear": "2019",
        "DebutOpponent": "Bulgaria"
    },
    {
        "Surname": "Hammond",
        "FullName": "Henry Hammond",
        "Position": "HB",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1889",
        "DebutOpponent": "Scotland"
    },
    {
        "Surname": "Cowans",
        "FullName": "Gordon Cowans",
        "Position": "MF",
        "Caps": "10",
        "Goals": "2",
        "DebutYear": "1983",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Walker",
        "FullName": "Des Walker",
        "Position": "DF",
        "Caps": "59",
        "Goals": "0",
        "DebutYear": "1988",
        "DebutOpponent": "Denmark"
    },
    {
        "Surname": "Barnes",
        "FullName": "John Barnes",
        "Position": "MF",
        "Caps": "79",
        "Goals": "11",
        "DebutYear": "1983",
        "DebutOpponent": "Northern Ireland"
    },
    {
        "Surname": "Alli",
        "FullName": "Dele Alli",
        "Position": "MF",
        "Caps": "37",
        "Goals": "3",
        "DebutYear": "2015",
        "DebutOpponent": "Estonia"
    },
    {
        "Surname": "Smith",
        "FullName": "Trevor Smith",
        "Position": "HB",
        "Caps": "2",
        "Goals": "0",
        "DebutYear": "1959",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Pickering",
        "FullName": "Jack Pickering",
        "Position": "FW",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1933",
        "DebutOpponent": "Scotland"
    },
    {
        "Surname": "Johnson",
        "FullName": "Tommy Johnson",
        "Position": "FW",
        "Caps": "5",
        "Goals": "5",
        "DebutYear": "1926",
        "DebutOpponent": "Belgium"
    },
    {
        "Surname": "Whitham",
        "FullName": "Michael Whitham",
        "Position": "HB",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1892",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Green",
        "FullName": "Frederick Green",
        "Position": "FB",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1876",
        "DebutOpponent": "Scotland"
    },
    {
        "Surname": "Leighton",
        "FullName": "Teddy Leighton",
        "Position": "FW",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1886",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Matthews",
        "FullName": "Stanley Matthews",
        "Position": "FW",
        "Caps": "54",
        "Goals": "11",
        "DebutYear": "1934",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Turnbull",
        "FullName": "Robert Turnbull",
        "Position": "FW",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1919",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Harris",
        "FullName": "Gordon Harris",
        "Position": "FW",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1966",
        "DebutOpponent": "Poland"
    },
    {
        "Surname": "Johnson",
        "FullName": "Joe Johnson",
        "Position": "FW",
        "Caps": "5",
        "Goals": "2",
        "DebutYear": "1936",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Bannister",
        "FullName": "Billy Bannister",
        "Position": "HB",
        "Caps": "2",
        "Goals": "0",
        "DebutYear": "1901",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Palmer",
        "FullName": "Carlton Palmer",
        "Position": "MF / DF",
        "Caps": "18",
        "Goals": "1",
        "DebutYear": "1992",
        "DebutOpponent": "CIS"
    },
    {
        "Surname": "Carr",
        "FullName": "William Carr",
        "Position": "GK",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1875",
        "DebutOpponent": "Scotland"
    },
    {
        "Surname": "Corbett",
        "FullName": "Bertie Corbett",
        "Position": "FW",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1901",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Thompson",
        "FullName": "Tommy Thompson",
        "Position": "FW",
        "Caps": "2",
        "Goals": "0",
        "DebutYear": "1951",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Milton",
        "FullName": "Arthur Milton",
        "Position": "FW",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1951",
        "DebutOpponent": "Austria"
    },
    {
        "Surname": "Marsden",
        "FullName": "Joe Marsden",
        "Position": "FB",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1891",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Smith",
        "FullName": "Alan Smith",
        "Position": "FW / MF",
        "Caps": "19",
        "Goals": "1",
        "DebutYear": "2001",
        "DebutOpponent": "Mexico"
    },
    {
        "Surname": "Jeffers",
        "FullName": "Francis Jeffers",
        "Position": "FW",
        "Caps": "1",
        "Goals": "1",
        "DebutYear": "2003",
        "DebutOpponent": "Australia"
    },
    {
        "Surname": "Froggatt",
        "FullName": "Jack Froggatt",
        "Position": "HB",
        "Caps": "13",
        "Goals": "2",
        "DebutYear": "1949",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Stewart",
        "FullName": "James Stewart",
        "Position": "FW",
        "Caps": "3",
        "Goals": "2",
        "DebutYear": "1907",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Johnson",
        "FullName": "Harry Johnson",
        "Position": "HB",
        "Caps": "6",
        "Goals": "1",
        "DebutYear": "1900",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Neville",
        "FullName": "Gary Neville",
        "Position": "DF",
        "Caps": "85",
        "Goals": "0",
        "DebutYear": "1995",
        "DebutOpponent": "Japan"
    },
    {
        "Surname": "Moore",
        "FullName": "James Moore",
        "Position": "FW",
        "Caps": "1",
        "Goals": "1",
        "DebutYear": "1923",
        "DebutOpponent": "Sweden"
    },
    {
        "Surname": "Geldard",
        "FullName": "Albert Geldard",
        "Position": "FW",
        "Caps": "4",
        "Goals": "0",
        "DebutYear": "1933",
        "DebutOpponent": "Italy"
    },
    {
        "Surname": "Winckworth",
        "FullName": "William Winckworth",
        "Position": "HB",
        "Caps": "2",
        "Goals": "1",
        "DebutYear": "1892",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Bambridge",
        "FullName": "Ernest Bambridge",
        "Position": "HB",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1876",
        "DebutOpponent": "Scotland"
    },
    {
        "Surname": "Delph",
        "FullName": "Fabian Delph",
        "Position": "MF",
        "Caps": "20",
        "Goals": "0",
        "DebutYear": "2014",
        "DebutOpponent": "Norway"
    },
    {
        "Surname": "Mason",
        "FullName": "Ryan Mason",
        "Position": "MF",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "2015",
        "DebutOpponent": "Italy"
    },
    {
        "Surname": "Blunstone",
        "FullName": "Frank Blunstone",
        "Position": "FW",
        "Caps": "5",
        "Goals": "0",
        "DebutYear": "1954",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Winterburn",
        "FullName": "Nigel Winterburn",
        "Position": "DF",
        "Caps": "2",
        "Goals": "0",
        "DebutYear": "1989",
        "DebutOpponent": "Italy"
    },
    {
        "Surname": "Cursham",
        "FullName": "Arthur Cursham",
        "Position": "FW",
        "Caps": "6",
        "Goals": "2",
        "DebutYear": "1876",
        "DebutOpponent": "Scotland"
    },
    {
        "Surname": "Heron",
        "FullName": "Hubert Heron",
        "Position": "FW",
        "Caps": "5",
        "Goals": "0",
        "DebutYear": "1873",
        "DebutOpponent": "Scotland"
    },
    {
        "Surname": "Bellingham",
        "FullName": "Jude Bellingham",
        "Position": "MF",
        "Caps": "24",
        "Goals": "1",
        "DebutYear": "2020",
        "DebutOpponent": "Republic of Ireland"
    },
    {
        "Surname": "Bromilow",
        "FullName": "Tom Bromilow",
        "Position": "HB",
        "Caps": "5",
        "Goals": "0",
        "DebutYear": "1921",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Bardsley",
        "FullName": "David Bardsley",
        "Position": "DF",
        "Caps": "2",
        "Goals": "0",
        "DebutYear": "1992",
        "DebutOpponent": "Spain"
    },
    {
        "Surname": "Kinsey",
        "FullName": "George Kinsey",
        "Position": "HB",
        "Caps": "4",
        "Goals": "0",
        "DebutYear": "1892",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Rodwell",
        "FullName": "Jack Rodwell",
        "Position": "MF",
        "Caps": "3",
        "Goals": "0",
        "DebutYear": "2011",
        "DebutOpponent": "Spain"
    },
    {
        "Surname": "Hunt",
        "FullName": "George Hunt",
        "Position": "FW",
        "Caps": "3",
        "Goals": "1",
        "DebutYear": "1933",
        "DebutOpponent": "Scotland"
    },
    {
        "Surname": "Capes",
        "FullName": "Arthur Capes",
        "Position": "FW",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1903",
        "DebutOpponent": "Scotland"
    },
    {
        "Surname": "Shimwell",
        "FullName": "Eddie Shimwell",
        "Position": "FB",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1949",
        "DebutOpponent": "Sweden"
    },
    {
        "Surname": "Stiles",
        "FullName": "Nobby Stiles",
        "Position": "MF",
        "Caps": "28",
        "Goals": "1",
        "DebutYear": "1965",
        "DebutOpponent": "Scotland"
    },
    {
        "Surname": "Agbonlahor",
        "FullName": "Gabriel Agbonlahor",
        "Position": "FW",
        "Caps": "3",
        "Goals": "0",
        "DebutYear": "2008",
        "DebutOpponent": "Germany"
    },
    {
        "Surname": "Ricketts",
        "FullName": "Michael Ricketts",
        "Position": "FW",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "2002",
        "DebutOpponent": "Netherlands"
    },
    {
        "Surname": "Lee",
        "FullName": "Bert Lee",
        "Position": "HB",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1904",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Thornewell",
        "FullName": "George Thornewell",
        "Position": "FW",
        "Caps": "4",
        "Goals": "1",
        "DebutYear": "1923",
        "DebutOpponent": "Sweden"
    },
    {
        "Surname": "Hill",
        "FullName": "Richard Hill",
        "Position": "FB",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1926",
        "DebutOpponent": "Belgium"
    },
    {
        "Surname": "Taylor",
        "FullName": "Peter Taylor",
        "Position": "MF",
        "Caps": "4",
        "Goals": "2",
        "DebutYear": "1976",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Hodge",
        "FullName": "Steve Hodge",
        "Position": "MF",
        "Caps": "24",
        "Goals": "0",
        "DebutYear": "1986",
        "DebutOpponent": "Soviet Union"
    },
    {
        "Surname": "Carr",
        "FullName": "Jack Carr",
        "Position": "FB",
        "Caps": "2",
        "Goals": "0",
        "DebutYear": "1905",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Lindsay",
        "FullName": "Alec Lindsay",
        "Position": "DF",
        "Caps": "4",
        "Goals": "0",
        "DebutYear": "1974",
        "DebutOpponent": "Argentina"
    },
    {
        "Surname": "West",
        "FullName": "Gordon West",
        "Position": "GK",
        "Caps": "3",
        "Goals": "0",
        "DebutYear": "1968",
        "DebutOpponent": "Bulgaria"
    },
    {
        "Surname": "Lallana",
        "FullName": "Adam Lallana",
        "Position": "MF",
        "Caps": "34",
        "Goals": "3",
        "DebutYear": "2013",
        "DebutOpponent": "Chile"
    },
    {
        "Surname": "Pearson",
        "FullName": "John Pearson",
        "Position": "FW",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1892",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Peacock",
        "FullName": "Alan Peacock",
        "Position": "FW",
        "Caps": "6",
        "Goals": "3",
        "DebutYear": "1962",
        "DebutOpponent": "Argentina"
    },
    {
        "Surname": "Grimsdell",
        "FullName": "Arthur Grimsdell",
        "Position": "HB",
        "Caps": "6",
        "Goals": "0",
        "DebutYear": "1920",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Williamson",
        "FullName": "Tim Williamson",
        "Position": "GK",
        "Caps": "7",
        "Goals": "0",
        "DebutYear": "1905",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Banks",
        "FullName": "Herbert Banks",
        "Position": "FW",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1901",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Webster",
        "FullName": "Maurice Webster",
        "Position": "HB",
        "Caps": "3",
        "Goals": "0",
        "DebutYear": "1930",
        "DebutOpponent": "Scotland"
    },
    {
        "Surname": "Sandford",
        "FullName": "Teddy Sandford",
        "Position": "FW",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1932",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Burrows",
        "FullName": "Horace Burrows",
        "Position": "HB",
        "Caps": "3",
        "Goals": "0",
        "DebutYear": "1934",
        "DebutOpponent": "Hungary"
    },
    {
        "Surname": "Platt",
        "FullName": "David Platt",
        "Position": "MF",
        "Caps": "62",
        "Goals": "27",
        "DebutYear": "1989",
        "DebutOpponent": "Italy"
    },
    {
        "Surname": "Bowers",
        "FullName": "Jack Bowers",
        "Position": "FW",
        "Caps": "3",
        "Goals": "2",
        "DebutYear": "1933",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Salako",
        "FullName": "John Salako",
        "Position": "MF",
        "Caps": "5",
        "Goals": "0",
        "DebutYear": "1991",
        "DebutOpponent": "Australia"
    },
    {
        "Surname": "Stratford",
        "FullName": "Cecil Wingfield-Stratford",
        "Position": "FW",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1877",
        "DebutOpponent": "Scotland"
    },
    {
        "Surname": "Ferdinand",
        "FullName": "Rio Ferdinand",
        "Position": "DF",
        "Caps": "81",
        "Goals": "3",
        "DebutYear": "1997",
        "DebutOpponent": "Cameroon"
    },
    {
        "Surname": "Moore",
        "FullName": "Billy Moore",
        "Position": "FW",
        "Caps": "1",
        "Goals": "2",
        "DebutYear": "1923",
        "DebutOpponent": "Sweden"
    },
    {
        "Surname": "Hinton",
        "FullName": "Alan Hinton",
        "Position": "FW",
        "Caps": "3",
        "Goals": "1",
        "DebutYear": "1962",
        "DebutOpponent": "France"
    },
    {
        "Surname": "Chamberlain",
        "FullName": "Mark Chamberlain",
        "Position": "MF",
        "Caps": "8",
        "Goals": "1",
        "DebutYear": "1982",
        "DebutOpponent": "Luxembourg"
    },
    {
        "Surname": "Atyeo",
        "FullName": "John Atyeo",
        "Position": "FW",
        "Caps": "6",
        "Goals": "5",
        "DebutYear": "1955",
        "DebutOpponent": "Spain"
    },
    {
        "Surname": "Maguire",
        "FullName": "Harry Maguire",
        "Position": "DF",
        "Caps": "55",
        "Goals": "7",
        "DebutYear": "2017",
        "DebutOpponent": "Lithuania"
    },
    {
        "Surname": "Lyttelton",
        "FullName": "Alfred Lyttelton",
        "Position": "FW",
        "Caps": "1",
        "Goals": "1",
        "DebutYear": "1877",
        "DebutOpponent": "Scotland"
    },
    {
        "Surname": "Wright",
        "FullName": "Ian Wright",
        "Position": "FW",
        "Caps": "33",
        "Goals": "9",
        "DebutYear": "1991",
        "DebutOpponent": "Cameroon"
    },
    {
        "Surname": "Scattergood",
        "FullName": "Ernald Scattergood",
        "Position": "GK",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1913",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Anderson",
        "FullName": "Viv Anderson",
        "Position": "DF",
        "Caps": "30",
        "Goals": "2",
        "DebutYear": "1978",
        "DebutOpponent": "Czechoslovakia"
    },
    {
        "Surname": "Richardson",
        "FullName": "William Richardson",
        "Position": "FW",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1935",
        "DebutOpponent": "Netherlands"
    },
    {
        "Surname": "Utley",
        "FullName": "George Utley",
        "Position": "HB",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1913",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Meadows",
        "FullName": "Jimmy Meadows",
        "Position": "FB",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1955",
        "DebutOpponent": "Scotland"
    },
    {
        "Surname": "Shelton",
        "FullName": "Charlie Shelton",
        "Position": "HB",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1888",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Barry",
        "FullName": "Gareth Barry",
        "Position": "DF / MF",
        "Caps": "53",
        "Goals": "3",
        "DebutYear": "2000",
        "DebutOpponent": "Ukraine"
    },
    {
        "Surname": "Hogg",
        "FullName": "Billy Hogg",
        "Position": "FW",
        "Caps": "3",
        "Goals": "0",
        "DebutYear": "1902",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Eastham",
        "FullName": "George Eastham",
        "Position": "FW",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1935",
        "DebutOpponent": "Netherlands"
    },
    {
        "Surname": "Brown",
        "FullName": "Arthur Brown",
        "Position": "FW",
        "Caps": "3",
        "Goals": "4",
        "DebutYear": "1882",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Cork",
        "FullName": "Jack Cork",
        "Position": "MF",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "2017",
        "DebutOpponent": "Germany"
    },
    {
        "Surname": "Johnston",
        "FullName": "Harry Johnston",
        "Position": "HB",
        "Caps": "10",
        "Goals": "0",
        "DebutYear": "1946",
        "DebutOpponent": "Netherlands"
    },
    {
        "Surname": "Pilkington",
        "FullName": "Brian Pilkington",
        "Position": "FW",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1954",
        "DebutOpponent": "Northern Ireland"
    },
    {
        "Surname": "Phillips",
        "FullName": "Shaun Wright-Phillips",
        "Position": "MF / FW",
        "Caps": "36",
        "Goals": "6",
        "DebutYear": "2004",
        "DebutOpponent": "Ukraine"
    },
    {
        "Surname": "Garraty",
        "FullName": "William Garraty",
        "Position": "FW",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1903",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Reynolds",
        "FullName": "Jack Reynolds",
        "Position": "HB",
        "Caps": "8",
        "Goals": "2",
        "DebutYear": "1892",
        "DebutOpponent": "Scotland"
    },
    {
        "Surname": "Jackson",
        "FullName": "Elphinstone Jackson",
        "Position": "FB",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1891",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Scales",
        "FullName": "John Scales",
        "Position": "DF",
        "Caps": "3",
        "Goals": "0",
        "DebutYear": "1995",
        "DebutOpponent": "Japan"
    },
    {
        "Surname": "Halse",
        "FullName": "Harold Halse",
        "Position": "FW",
        "Caps": "1",
        "Goals": "2",
        "DebutYear": "1909",
        "DebutOpponent": "Austria"
    },
    {
        "Surname": "Wilson",
        "FullName": "Charles Plumpton Wilson",
        "Position": "HB",
        "Caps": "2",
        "Goals": "0",
        "DebutYear": "1884",
        "DebutOpponent": "Scotland"
    },
    {
        "Surname": "Garfield",
        "FullName": "Ben Garfield",
        "Position": "FW",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1898",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Hagan",
        "FullName": "Jimmy Hagan",
        "Position": "FW",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1948",
        "DebutOpponent": "Denmark"
    },
    {
        "Surname": "Wheldon",
        "FullName": "Fred Wheldon",
        "Position": "FW",
        "Caps": "4",
        "Goals": "6",
        "DebutYear": "1897",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Melmoth",
        "FullName": "Percy Melmoth Walters",
        "Position": "FB",
        "Caps": "13",
        "Goals": "0",
        "DebutYear": "1895",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Worthington",
        "FullName": "Frank Worthington",
        "Position": "FW",
        "Caps": "8",
        "Goals": "2",
        "DebutYear": "1974",
        "DebutOpponent": "Northern Ireland"
    },
    {
        "Surname": "Rowe",
        "FullName": "Arthur Rowe",
        "Position": "HB",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1933",
        "DebutOpponent": "France"
    },
    {
        "Surname": "Pegg",
        "FullName": "David Pegg",
        "Position": "FW",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1957",
        "DebutOpponent": "Republic of Ireland"
    },
    {
        "Surname": "Dixon",
        "FullName": "Lee Dixon",
        "Position": "DF",
        "Caps": "22",
        "Goals": "1",
        "DebutYear": "1990",
        "DebutOpponent": "Czechoslovakia"
    },
    {
        "Surname": "Byrne",
        "FullName": "Roger Byrne",
        "Position": "FB",
        "Caps": "33",
        "Goals": "0",
        "DebutYear": "1954",
        "DebutOpponent": "Scotland"
    },
    {
        "Surname": "Woosnam",
        "FullName": "Max Woosnam",
        "Position": "HB",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1922",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Stephenson",
        "FullName": "George Stephenson",
        "Position": "FW",
        "Caps": "3",
        "Goals": "2",
        "DebutYear": "1928",
        "DebutOpponent": "France"
    },
    {
        "Surname": "Townsend",
        "FullName": "Andros Townsend",
        "Position": "MF",
        "Caps": "13",
        "Goals": "3",
        "DebutYear": "2013",
        "DebutOpponent": "Montenegro"
    },
    {
        "Surname": "Bamber",
        "FullName": "Jack Bamber",
        "Position": "HB",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1921",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Mozley",
        "FullName": "Bert Mozley",
        "Position": "FB",
        "Caps": "3",
        "Goals": "0",
        "DebutYear": "1949",
        "DebutOpponent": "Republic of Ireland"
    },
    {
        "Surname": "Maynard",
        "FullName": "William Maynard",
        "Position": "FW",
        "Caps": "2",
        "Goals": "0",
        "DebutYear": "1872",
        "DebutOpponent": "Scotland"
    },
    {
        "Surname": "Gibbins",
        "FullName": "Vivian Gibbins",
        "Position": "FW",
        "Caps": "2",
        "Goals": "3",
        "DebutYear": "1924",
        "DebutOpponent": "France"
    },
    {
        "Surname": "Common",
        "FullName": "Alf Common",
        "Position": "FW",
        "Caps": "3",
        "Goals": "2",
        "DebutYear": "1904",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Moore",
        "FullName": "Harry Moore",
        "Position": "FB",
        "Caps": "2",
        "Goals": "0",
        "DebutYear": "1883",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Foulke",
        "FullName": "William Foulke",
        "Position": "GK",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1897",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Reeves",
        "FullName": "Kevin Reeves",
        "Position": "FW",
        "Caps": "2",
        "Goals": "0",
        "DebutYear": "1979",
        "DebutOpponent": "Bulgaria"
    },
    {
        "Surname": "Gay",
        "FullName": "Leslie Gay",
        "Position": "GK",
        "Caps": "3",
        "Goals": "0",
        "DebutYear": "1893",
        "DebutOpponent": "Scotland"
    },
    {
        "Surname": "Withe",
        "FullName": "Peter Withe",
        "Position": "FW",
        "Caps": "11",
        "Goals": "1",
        "DebutYear": "1981",
        "DebutOpponent": "Brazil"
    },
    {
        "Surname": "Angus",
        "FullName": "John Angus",
        "Position": "FB",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1961",
        "DebutOpponent": "Austria"
    },
    {
        "Surname": "Gu�hi",
        "FullName": "Marc Gu�hi",
        "Position": "DF",
        "Caps": "3",
        "Goals": "0",
        "DebutYear": "2022",
        "DebutOpponent": "Switzerland"
    },
    {
        "Surname": "Williams",
        "FullName": "Bert Williams",
        "Position": "GK",
        "Caps": "24",
        "Goals": "0",
        "DebutYear": "1949",
        "DebutOpponent": "France"
    },
    {
        "Surname": "Middleditch",
        "FullName": "Bernard Middleditch",
        "Position": "HB",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1897",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Smith",
        "FullName": "Steve Smith",
        "Position": "FW",
        "Caps": "1",
        "Goals": "1",
        "DebutYear": "1895",
        "DebutOpponent": "Scotland"
    },
    {
        "Surname": "Gomez",
        "FullName": "Joe Gomez",
        "Position": "DF",
        "Caps": "11",
        "Goals": "0",
        "DebutYear": "2017",
        "DebutOpponent": "Germany"
    },
    {
        "Surname": "Wall",
        "FullName": "George Wall",
        "Position": "FW",
        "Caps": "7",
        "Goals": "2",
        "DebutYear": "1907",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Shilton",
        "FullName": "Peter Shilton",
        "Position": "GK",
        "Caps": "125",
        "Goals": "0",
        "DebutYear": "1970",
        "DebutOpponent": "East Germany"
    },
    {
        "Surname": "Bradshaw",
        "FullName": "Harry Bradshaw",
        "Position": "FW",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1897",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Ellerington",
        "FullName": "Bill Ellerington",
        "Position": "FB",
        "Caps": "2",
        "Goals": "0",
        "DebutYear": "1949",
        "DebutOpponent": "Norway"
    },
    {
        "Surname": "Guppy",
        "FullName": "Steve Guppy",
        "Position": "MF",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1999",
        "DebutOpponent": "Belgium"
    },
    {
        "Surname": "Armitage",
        "FullName": "George Armitage",
        "Position": "HB",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1925",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Bonetti",
        "FullName": "Peter Bonetti",
        "Position": "GK",
        "Caps": "7",
        "Goals": "0",
        "DebutYear": "1966",
        "DebutOpponent": "Denmark"
    },
    {
        "Surname": "Smith",
        "FullName": "G. O. Smith",
        "Position": "FW",
        "Caps": "20",
        "Goals": "11",
        "DebutYear": "1893",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Doyle",
        "FullName": "Mike Doyle",
        "Position": "DF",
        "Caps": "5",
        "Goals": "0",
        "DebutYear": "1976",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Lindley",
        "FullName": "Tinsley Lindley",
        "Position": "FW",
        "Caps": "13",
        "Goals": "14",
        "DebutYear": "1886",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Springett",
        "FullName": "Ron Springett",
        "Position": "GK",
        "Caps": "33",
        "Goals": "0",
        "DebutYear": "1959",
        "DebutOpponent": "Northern Ireland"
    },
    {
        "Surname": "Anderson",
        "FullName": "Rupert Anderson",
        "Position": "GK",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1879",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Fort",
        "FullName": "Jack Fort",
        "Position": "FB",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1921",
        "DebutOpponent": "Belgium"
    },
    {
        "Surname": "Saunders",
        "FullName": "Frank Saunders",
        "Position": "HB",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1888",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Bent",
        "FullName": "Darren Bent",
        "Position": "FW",
        "Caps": "13",
        "Goals": "4",
        "DebutYear": "2006",
        "DebutOpponent": "Uruguay"
    },
    {
        "Surname": "Bonsor",
        "FullName": "Alexander Bonsor",
        "Position": "FW",
        "Caps": "2",
        "Goals": "1",
        "DebutYear": "1873",
        "DebutOpponent": "Scotland"
    },
    {
        "Surname": "Lofthouse",
        "FullName": "Joe Lofthouse",
        "Position": "FW",
        "Caps": "7",
        "Goals": "3",
        "DebutYear": "1885",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Lockett",
        "FullName": "Arthur Lockett",
        "Position": "FW",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1903",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Payne",
        "FullName": "Joe Payne",
        "Position": "FW",
        "Caps": "1",
        "Goals": "2",
        "DebutYear": "1937",
        "DebutOpponent": "Finland"
    },
    {
        "Surname": "Dean",
        "FullName": "Dixie Dean",
        "Position": "FW",
        "Caps": "16",
        "Goals": "18",
        "DebutYear": "1927",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Lineker",
        "FullName": "Gary Lineker",
        "Position": "FW",
        "Caps": "80",
        "Goals": "48",
        "DebutYear": "1984",
        "DebutOpponent": "Scotland"
    },
    {
        "Surname": "Howarth",
        "FullName": "Bob Howarth",
        "Position": "FB",
        "Caps": "5",
        "Goals": "0",
        "DebutYear": "1887",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Brown",
        "FullName": "Ken Brown",
        "Position": "HB",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1959",
        "DebutOpponent": "Northern Ireland"
    },
    {
        "Surname": "Walker",
        "FullName": "Ian Walker",
        "Position": "GK",
        "Caps": "4",
        "Goals": "0",
        "DebutYear": "1996",
        "DebutOpponent": "Hungary"
    },
    {
        "Surname": "Broadbent",
        "FullName": "Peter Broadbent",
        "Position": "FW",
        "Caps": "7",
        "Goals": "2",
        "DebutYear": "1958",
        "DebutOpponent": "Soviet Union"
    },
    {
        "Surname": "Alderson",
        "FullName": "Jack Alderson",
        "Position": "GK",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1923",
        "DebutOpponent": "France"
    },
    {
        "Surname": "Harper",
        "FullName": "Ted Harper",
        "Position": "FW",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1926",
        "DebutOpponent": "Scotland"
    },
    {
        "Surname": "Bailey",
        "FullName": "Mike Bailey",
        "Position": "HB",
        "Caps": "2",
        "Goals": "0",
        "DebutYear": "1964",
        "DebutOpponent": "United States"
    },
    {
        "Surname": "Parkinson",
        "FullName": "Jack Parkinson",
        "Position": "FW",
        "Caps": "2",
        "Goals": "0",
        "DebutYear": "1910",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Bailey",
        "FullName": "Gary Bailey",
        "Position": "GK",
        "Caps": "2",
        "Goals": "0",
        "DebutYear": "1985",
        "DebutOpponent": "Republic of Ireland"
    },
    {
        "Surname": "Bedford",
        "FullName": "Harry Bedford",
        "Position": "FW",
        "Caps": "2",
        "Goals": "1",
        "DebutYear": "1923",
        "DebutOpponent": "Sweden"
    },
    {
        "Surname": "Johnson",
        "FullName": "Glen Johnson",
        "Position": "DF",
        "Caps": "54",
        "Goals": "1",
        "DebutYear": "2003",
        "DebutOpponent": "Denmark"
    },
    {
        "Surname": "Bower",
        "FullName": "Alfred Bower",
        "Position": "FB",
        "Caps": "5",
        "Goals": "0",
        "DebutYear": "1923",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Howell",
        "FullName": "Leonard Howell",
        "Position": "FB",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1873",
        "DebutOpponent": "Scotland"
    },
    {
        "Surname": "Clegg",
        "FullName": "William Clegg",
        "Position": "FW",
        "Caps": "2",
        "Goals": "0",
        "DebutYear": "1873",
        "DebutOpponent": "Scotland"
    },
    {
        "Surname": "Rose",
        "FullName": "William Rose",
        "Position": "GK",
        "Caps": "5",
        "Goals": "0",
        "DebutYear": "1884",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Williams",
        "FullName": "Owen Williams",
        "Position": "FW",
        "Caps": "2",
        "Goals": "0",
        "DebutYear": "1922",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Sherwood",
        "FullName": "Tim Sherwood",
        "Position": "MF",
        "Caps": "3",
        "Goals": "0",
        "DebutYear": "1999",
        "DebutOpponent": "Poland"
    },
    {
        "Surname": "Reaney",
        "FullName": "Paul Reaney",
        "Position": "DF",
        "Caps": "3",
        "Goals": "0",
        "DebutYear": "1968",
        "DebutOpponent": "Bulgaria"
    },
    {
        "Surname": "Barton",
        "FullName": "Warren Barton",
        "Position": "DF",
        "Caps": "3",
        "Goals": "0",
        "DebutYear": "1995",
        "DebutOpponent": "Republic of Ireland"
    },
    {
        "Surname": "Walsh",
        "FullName": "Paul Walsh",
        "Position": "FW",
        "Caps": "5",
        "Goals": "1",
        "DebutYear": "1983",
        "DebutOpponent": "Australia"
    },
    {
        "Surname": "Cox",
        "FullName": "Jack Cox",
        "Position": "FW",
        "Caps": "3",
        "Goals": "0",
        "DebutYear": "1901",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Holliday",
        "FullName": "Edwin Holliday",
        "Position": "FW",
        "Caps": "3",
        "Goals": "0",
        "DebutYear": "1959",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Milward",
        "FullName": "Alf Milward",
        "Position": "FW",
        "Caps": "4",
        "Goals": "3",
        "DebutYear": "1891",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Wilson",
        "FullName": "Geoffrey Plumpton Wilson",
        "Position": "HB",
        "Caps": "2",
        "Goals": "1",
        "DebutYear": "1900",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Keown",
        "FullName": "Martin Keown",
        "Position": "DF",
        "Caps": "43",
        "Goals": "2",
        "DebutYear": "1992",
        "DebutOpponent": "France"
    },
    {
        "Surname": "Abraham",
        "FullName": "Tammy Abraham",
        "Position": "FW",
        "Caps": "11",
        "Goals": "3",
        "DebutYear": "2017",
        "DebutOpponent": "Germany"
    },
    {
        "Surname": "Parlour",
        "FullName": "Ray Parlour",
        "Position": "MF",
        "Caps": "10",
        "Goals": "0",
        "DebutYear": "1999",
        "DebutOpponent": "Poland"
    },
    {
        "Surname": "Parr",
        "FullName": "Percival Parr",
        "Position": "FW",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1882",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Winks",
        "FullName": "Harry Winks",
        "Position": "MF",
        "Caps": "10",
        "Goals": "1",
        "DebutYear": "2017",
        "DebutOpponent": "Lithuania"
    },
    {
        "Surname": "Morris",
        "FullName": "Bill Morris",
        "Position": "FB",
        "Caps": "3",
        "Goals": "0",
        "DebutYear": "1938",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Moffat",
        "FullName": "Hugh Moffat",
        "Position": "HB",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1913",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Harrow",
        "FullName": "Jack Harrow",
        "Position": "FB",
        "Caps": "2",
        "Goals": "0",
        "DebutYear": "1922",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Morris",
        "FullName": "Johnny Morris",
        "Position": "FW",
        "Caps": "3",
        "Goals": "3",
        "DebutYear": "1949",
        "DebutOpponent": "Norway"
    },
    {
        "Surname": "LeSaux",
        "FullName": "Graeme Le Saux",
        "Position": "DF",
        "Caps": "36",
        "Goals": "1",
        "DebutYear": "1994",
        "DebutOpponent": "Denmark"
    },
    {
        "Surname": "Stewart",
        "FullName": "Paul Stewart",
        "Position": "MF",
        "Caps": "3",
        "Goals": "0",
        "DebutYear": "1991",
        "DebutOpponent": "Germany"
    },
    {
        "Surname": "Moore",
        "FullName": "Ian Storey-Moore",
        "Position": "FW",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1970",
        "DebutOpponent": "Netherlands"
    },
    {
        "Surname": "Smith",
        "FullName": "Joe Smith",
        "Position": "FB",
        "Caps": "2",
        "Goals": "0",
        "DebutYear": "1919",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Joy",
        "FullName": "Bernard Joy",
        "Position": "HB",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1936",
        "DebutOpponent": "Belgium"
    },
    {
        "Surname": "Roberts",
        "FullName": "Herbie Roberts",
        "Position": "HB",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1931",
        "DebutOpponent": "Scotland"
    },
    {
        "Surname": "Crooks",
        "FullName": "Sammy Crooks",
        "Position": "FW",
        "Caps": "26",
        "Goals": "7",
        "DebutYear": "1930",
        "DebutOpponent": "Scotland"
    },
    {
        "Surname": "Brodie",
        "FullName": "John Brodie",
        "Position": "FW",
        "Caps": "3",
        "Goals": "1",
        "DebutYear": "1889",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Towers",
        "FullName": "Tony Towers",
        "Position": "MF",
        "Caps": "3",
        "Goals": "0",
        "DebutYear": "1976",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Eckersley",
        "FullName": "Bill Eckersley",
        "Position": "FB",
        "Caps": "17",
        "Goals": "0",
        "DebutYear": "1950",
        "DebutOpponent": "Spain"
    },
    {
        "Surname": "Squire",
        "FullName": "Ralph Squire",
        "Position": "HB",
        "Caps": "3",
        "Goals": "0",
        "DebutYear": "1886",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "McNab",
        "FullName": "Bob McNab",
        "Position": "DF",
        "Caps": "4",
        "Goals": "0",
        "DebutYear": "1968",
        "DebutOpponent": "Romania"
    },
    {
        "Surname": "Wilshaw",
        "FullName": "Dennis Wilshaw",
        "Position": "FW",
        "Caps": "12",
        "Goals": "10",
        "DebutYear": "1953",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Young",
        "FullName": "Gerry Young",
        "Position": "HB",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1964",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Scott",
        "FullName": "Billy Scott",
        "Position": "FW",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1936",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Mariner",
        "FullName": "Paul Mariner",
        "Position": "FW",
        "Caps": "35",
        "Goals": "13",
        "DebutYear": "1977",
        "DebutOpponent": "Luxembourg"
    },
    {
        "Surname": "Todd",
        "FullName": "Colin Todd",
        "Position": "DF",
        "Caps": "27",
        "Goals": "0",
        "DebutYear": "1972",
        "DebutOpponent": "Northern Ireland"
    },
    {
        "Surname": "Whitehead",
        "FullName": "Jimmy Whitehead",
        "Position": "FW",
        "Caps": "2",
        "Goals": "0",
        "DebutYear": "1893",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Byrne",
        "FullName": "Johnny Byrne",
        "Position": "FW",
        "Caps": "11",
        "Goals": "8",
        "DebutYear": "1961",
        "DebutOpponent": "Northern Ireland"
    },
    {
        "Surname": "Walters",
        "FullName": "Mark Walters",
        "Position": "MF",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1991",
        "DebutOpponent": "New Zealand"
    },
    {
        "Surname": "Butler",
        "FullName": "Jack Butler",
        "Position": "HB",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1924",
        "DebutOpponent": "Belgium"
    },
    {
        "Surname": "Page",
        "FullName": "Louis Page",
        "Position": "FW",
        "Caps": "7",
        "Goals": "1",
        "DebutYear": "1927",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Wilshere",
        "FullName": "Jack Wilshere",
        "Position": "MF",
        "Caps": "34",
        "Goals": "2",
        "DebutYear": "2010",
        "DebutOpponent": "Hungary"
    },
    {
        "Surname": "Parry",
        "FullName": "Ray Parry",
        "Position": "FW",
        "Caps": "2",
        "Goals": "1",
        "DebutYear": "1959",
        "DebutOpponent": "Northern Ireland"
    },
    {
        "Surname": "Schofield",
        "FullName": "Joe Schofield",
        "Position": "FW",
        "Caps": "3",
        "Goals": "0",
        "DebutYear": "1892",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Clare",
        "FullName": "Tommy Clare",
        "Position": "FB",
        "Caps": "4",
        "Goals": "0",
        "DebutYear": "1889",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Barrett",
        "FullName": "Albert Barrett",
        "Position": "HB",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1929",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Johnson",
        "FullName": "Adam Johnson",
        "Position": "MF",
        "Caps": "12",
        "Goals": "2",
        "DebutYear": "2010",
        "DebutOpponent": "Mexico"
    },
    {
        "Surname": "Young",
        "FullName": "Alf Young",
        "Position": "HB",
        "Caps": "9",
        "Goals": "0",
        "DebutYear": "1932",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Southgate",
        "FullName": "Gareth Southgate",
        "Position": "DF",
        "Caps": "57",
        "Goals": "2",
        "DebutYear": "1995",
        "DebutOpponent": "Portugal"
    },
    {
        "Surname": "Caulker",
        "FullName": "Steven Caulker",
        "Position": "DF",
        "Caps": "1",
        "Goals": "1",
        "DebutYear": "2012",
        "DebutOpponent": "Sweden"
    },
    {
        "Surname": "Carrick",
        "FullName": "Michael Carrick",
        "Position": "MF",
        "Caps": "34",
        "Goals": "0",
        "DebutYear": "2001",
        "DebutOpponent": "Mexico"
    },
    {
        "Surname": "Bowles",
        "FullName": "Stan Bowles",
        "Position": "FW",
        "Caps": "5",
        "Goals": "1",
        "DebutYear": "1974",
        "DebutOpponent": "Portugal"
    },
    {
        "Surname": "Booth",
        "FullName": "Tom Booth",
        "Position": "HB",
        "Caps": "2",
        "Goals": "0",
        "DebutYear": "1898",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Becton",
        "FullName": "Frank Becton",
        "Position": "FW",
        "Caps": "2",
        "Goals": "2",
        "DebutYear": "1895",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Stone",
        "FullName": "Steve Stone",
        "Position": "MF",
        "Caps": "9",
        "Goals": "2",
        "DebutYear": "1995",
        "DebutOpponent": "Norway"
    },
    {
        "Surname": "Goodyer",
        "FullName": "Arthur Goodyer",
        "Position": "FW",
        "Caps": "1",
        "Goals": "1",
        "DebutYear": "1879",
        "DebutOpponent": "Scotland"
    },
    {
        "Surname": "Morse",
        "FullName": "Harold Morse",
        "Position": "FB",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1879",
        "DebutOpponent": "Scotland"
    },
    {
        "Surname": "Dewhurst",
        "FullName": "Fred Dewhurst",
        "Position": "FW",
        "Caps": "9",
        "Goals": "11",
        "DebutYear": "1886",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Macaulay",
        "FullName": "Reginald Macaulay",
        "Position": "FW",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1881",
        "DebutOpponent": "Scotland"
    },
    {
        "Surname": "Venables",
        "FullName": "Terry Venables",
        "Position": "FW",
        "Caps": "2",
        "Goals": "0",
        "DebutYear": "1964",
        "DebutOpponent": "Belgium"
    },
    {
        "Surname": "Carson",
        "FullName": "Scott Carson",
        "Position": "GK",
        "Caps": "4",
        "Goals": "0",
        "DebutYear": "2007",
        "DebutOpponent": "Austria"
    },
    {
        "Surname": "Topham",
        "FullName": "Robert Topham",
        "Position": "FW",
        "Caps": "2",
        "Goals": "0",
        "DebutYear": "1892",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Stones",
        "FullName": "John Stones",
        "Position": "DF",
        "Caps": "66",
        "Goals": "3",
        "DebutYear": "2014",
        "DebutOpponent": "Peru"
    },
    {
        "Surname": "Trippier",
        "FullName": "Kieran Trippier",
        "Position": "DF",
        "Caps": "41",
        "Goals": "1",
        "DebutYear": "2017",
        "DebutOpponent": "France"
    },
    {
        "Surname": "Ehiogu",
        "FullName": "Ugo Ehiogu",
        "Position": "DF",
        "Caps": "4",
        "Goals": "1",
        "DebutYear": "1996",
        "DebutOpponent": "China"
    },
    {
        "Surname": "Smith",
        "FullName": "Billy Smith",
        "Position": "FW",
        "Caps": "3",
        "Goals": "0",
        "DebutYear": "1922",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Chappell",
        "FullName": "Frederick Chappell",
        "Position": "HB",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1872",
        "DebutOpponent": "Scotland"
    },
    {
        "Surname": "Widdowson",
        "FullName": "Samuel Widdowson",
        "Position": "FW",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1880",
        "DebutOpponent": "Scotland"
    },
    {
        "Surname": "Deeley",
        "FullName": "Norman Deeley",
        "Position": "FW",
        "Caps": "2",
        "Goals": "0",
        "DebutYear": "1959",
        "DebutOpponent": "Brazil"
    },
    {
        "Surname": "Linacre",
        "FullName": "Harry Linacre",
        "Position": "GK",
        "Caps": "2",
        "Goals": "0",
        "DebutYear": "1905",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Davenport",
        "FullName": "Peter Davenport",
        "Position": "FW",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1985",
        "DebutOpponent": "Republic of Ireland"
    },
    {
        "Surname": "Titmuss",
        "FullName": "Fred Titmuss",
        "Position": "FB",
        "Caps": "2",
        "Goals": "0",
        "DebutYear": "1922",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Pallister",
        "FullName": "Gary Pallister",
        "Position": "DF",
        "Caps": "22",
        "Goals": "0",
        "DebutYear": "1988",
        "DebutOpponent": "Hungary"
    },
    {
        "Surname": "Brawn",
        "FullName": "Billy Brawn",
        "Position": "FW",
        "Caps": "2",
        "Goals": "0",
        "DebutYear": "1904",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Lee",
        "FullName": "Jack Lee",
        "Position": "FW",
        "Caps": "1",
        "Goals": "1",
        "DebutYear": "1950",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Gardner",
        "FullName": "Tommy Gardner",
        "Position": "HB",
        "Caps": "2",
        "Goals": "0",
        "DebutYear": "1934",
        "DebutOpponent": "Czechoslovakia"
    },
    {
        "Surname": "Cockburn",
        "FullName": "Henry Cockburn",
        "Position": "HB",
        "Caps": "13",
        "Goals": "0",
        "DebutYear": "1946",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Johnson",
        "FullName": "Seth Johnson",
        "Position": "MF",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "2000",
        "DebutOpponent": "Italy"
    },
    {
        "Surname": "Beasant",
        "FullName": "Dave Beasant",
        "Position": "GK",
        "Caps": "2",
        "Goals": "0",
        "DebutYear": "1989",
        "DebutOpponent": "Italy"
    },
    {
        "Surname": "Kevan",
        "FullName": "Derek Kevan",
        "Position": "FW",
        "Caps": "14",
        "Goals": "8",
        "DebutYear": "1957",
        "DebutOpponent": "Scotland"
    },
    {
        "Surname": "Matthews",
        "FullName": "Reg Matthews",
        "Position": "GK",
        "Caps": "5",
        "Goals": "0",
        "DebutYear": "1956",
        "DebutOpponent": "Scotland"
    },
    {
        "Surname": "Richards",
        "FullName": "George Richards",
        "Position": "HB",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1909",
        "DebutOpponent": "Austria"
    },
    {
        "Surname": "Cheek",
        "FullName": "Ruben Loftus-Cheek",
        "Position": "MF",
        "Caps": "10",
        "Goals": "0",
        "DebutYear": "2017",
        "DebutOpponent": "Germany"
    },
    {
        "Surname": "Rowley",
        "FullName": "Jack Rowley",
        "Position": "FW",
        "Caps": "6",
        "Goals": "6",
        "DebutYear": "1948",
        "DebutOpponent": "Switzerland"
    },
    {
        "Surname": "Berry",
        "FullName": "Johnny Berry",
        "Position": "FW",
        "Caps": "4",
        "Goals": "0",
        "DebutYear": "1953",
        "DebutOpponent": "Argentina"
    },
    {
        "Surname": "Bastin",
        "FullName": "Cliff Bastin",
        "Position": "FW",
        "Caps": "21",
        "Goals": "12",
        "DebutYear": "1931",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Rutherford",
        "FullName": "Jock Rutherford",
        "Position": "FW",
        "Caps": "11",
        "Goals": "3",
        "DebutYear": "1904",
        "DebutOpponent": "Scotland"
    },
    {
        "Surname": "ODowd",
        "FullName": "Peter O'Dowd",
        "Position": "HB",
        "Caps": "3",
        "Goals": "0",
        "DebutYear": "1932",
        "DebutOpponent": "Scotland"
    },
    {
        "Surname": "Barker",
        "FullName": "Richard Barker",
        "Position": "HB",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1895",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Lambert",
        "FullName": "Rickie Lambert",
        "Position": "FW",
        "Caps": "11",
        "Goals": "3",
        "DebutYear": "2013",
        "DebutOpponent": "Scotland"
    },
    {
        "Surname": "Worrall",
        "FullName": "Fred Worrall",
        "Position": "FW",
        "Caps": "2",
        "Goals": "2",
        "DebutYear": "1935",
        "DebutOpponent": "Netherlands"
    },
    {
        "Surname": "Barrass",
        "FullName": "Malcolm Barrass",
        "Position": "HB",
        "Caps": "3",
        "Goals": "0",
        "DebutYear": "1951",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Sturridge",
        "FullName": "Daniel Sturridge",
        "Position": "FW",
        "Caps": "26",
        "Goals": "8",
        "DebutYear": "2011",
        "DebutOpponent": "Sweden"
    },
    {
        "Surname": "Stepney",
        "FullName": "Alex Stepney",
        "Position": "GK",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1968",
        "DebutOpponent": "Sweden"
    },
    {
        "Surname": "Crompton",
        "FullName": "Bob Crompton",
        "Position": "FB",
        "Caps": "41",
        "Goals": "0",
        "DebutYear": "1902",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Plant",
        "FullName": "Jack Plant",
        "Position": "FW",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1900",
        "DebutOpponent": "Scotland"
    },
    {
        "Surname": "Mullen",
        "FullName": "Jimmy Mullen",
        "Position": "FW",
        "Caps": "12",
        "Goals": "6",
        "DebutYear": "1947",
        "DebutOpponent": "Scotland"
    },
    {
        "Surname": "Collymore",
        "FullName": "Stan Collymore",
        "Position": "FW",
        "Caps": "3",
        "Goals": "0",
        "DebutYear": "1995",
        "DebutOpponent": "Japan"
    },
    {
        "Surname": "Scholes",
        "FullName": "Paul Scholes",
        "Position": "MF",
        "Caps": "66",
        "Goals": "14",
        "DebutYear": "1997",
        "DebutOpponent": "South Africa"
    },
    {
        "Surname": "Kelly",
        "FullName": "Martin Kelly",
        "Position": "DF",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "2012",
        "DebutOpponent": "Norway"
    },
    {
        "Surname": "White",
        "FullName": "Tommy White",
        "Position": "HB",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1933",
        "DebutOpponent": "Italy"
    },
    {
        "Surname": "Goodhart",
        "FullName": "Harry Goodhart",
        "Position": "FW",
        "Caps": "3",
        "Goals": "0",
        "DebutYear": "1883",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Barlow",
        "FullName": "Ray Barlow",
        "Position": "HB",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1954",
        "DebutOpponent": "Northern Ireland"
    },
    {
        "Surname": "Waddle",
        "FullName": "Chris Waddle",
        "Position": "MF",
        "Caps": "62",
        "Goals": "6",
        "DebutYear": "1985",
        "DebutOpponent": "Republic of Ireland"
    },
    {
        "Surname": "Phillips",
        "FullName": "Kalvin Phillips",
        "Position": "MF",
        "Caps": "26",
        "Goals": "0",
        "DebutYear": "2020",
        "DebutOpponent": "Denmark"
    },
    {
        "Surname": "Justin",
        "FullName": "James Justin",
        "Position": "DF",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "2022",
        "DebutOpponent": "Hungary"
    },
    {
        "Surname": "Brown",
        "FullName": "George Brown",
        "Position": "FW",
        "Caps": "9",
        "Goals": "5",
        "DebutYear": "1926",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Fairhurst",
        "FullName": "David Fairhurst",
        "Position": "FB",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1933",
        "DebutOpponent": "France"
    },
    {
        "Surname": "Kennedy",
        "FullName": "Ray Kennedy",
        "Position": "MF",
        "Caps": "17",
        "Goals": "3",
        "DebutYear": "1976",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Hassall",
        "FullName": "Harold Hassall",
        "Position": "FW",
        "Caps": "5",
        "Goals": "4",
        "DebutYear": "1951",
        "DebutOpponent": "Scotland"
    },
    {
        "Surname": "Mabbutt",
        "FullName": "Gary Mabbutt",
        "Position": "DF / MF",
        "Caps": "16",
        "Goals": "1",
        "DebutYear": "1982",
        "DebutOpponent": "West Germany"
    },
    {
        "Surname": "McFarland",
        "FullName": "Roy McFarland",
        "Position": "DF",
        "Caps": "28",
        "Goals": "0",
        "DebutYear": "1971",
        "DebutOpponent": "Malta"
    },
    {
        "Surname": "Clough",
        "FullName": "Nigel Clough",
        "Position": "MF",
        "Caps": "14",
        "Goals": "0",
        "DebutYear": "1989",
        "DebutOpponent": "Chile"
    },
    {
        "Surname": "Goodall",
        "FullName": "John Goodall",
        "Position": "FW",
        "Caps": "14",
        "Goals": "12",
        "DebutYear": "1888",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Peters",
        "FullName": "Kyle Walker-Peters",
        "Position": "DF",
        "Caps": "2",
        "Goals": "0",
        "DebutYear": "2022",
        "DebutOpponent": "Switzerland"
    },
    {
        "Surname": "Holdcroft",
        "FullName": "George Holdcroft",
        "Position": "GK",
        "Caps": "2",
        "Goals": "0",
        "DebutYear": "1936",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Dublin",
        "FullName": "Dion Dublin",
        "Position": "FW",
        "Caps": "4",
        "Goals": "0",
        "DebutYear": "1998",
        "DebutOpponent": "Chile"
    },
    {
        "Surname": "Lampard",
        "FullName": "Frank Lampard",
        "Position": "DF",
        "Caps": "2",
        "Goals": "0",
        "DebutYear": "1972",
        "DebutOpponent": "Yugoslavia"
    },
    {
        "Surname": "Hadley",
        "FullName": "Harry Hadley",
        "Position": "HB",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1903",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Scott",
        "FullName": "Laurie Scott",
        "Position": "FB",
        "Caps": "17",
        "Goals": "0",
        "DebutYear": "1946",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Hunter",
        "FullName": "Jack Hunter",
        "Position": "HB",
        "Caps": "7",
        "Goals": "0",
        "DebutYear": "1878",
        "DebutOpponent": "Scotland"
    },
    {
        "Surname": "Redmond",
        "FullName": "Nathan Redmond",
        "Position": "MF",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "2017",
        "DebutOpponent": "Germany"
    },
    {
        "Surname": "Cooper",
        "FullName": "Colin Cooper",
        "Position": "DF",
        "Caps": "2",
        "Goals": "0",
        "DebutYear": "1995",
        "DebutOpponent": "Sweden"
    },
    {
        "Surname": "Hossack",
        "FullName": "Anthony Hossack",
        "Position": "HB",
        "Caps": "2",
        "Goals": "0",
        "DebutYear": "1892",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "McGarry",
        "FullName": "Bill McGarry",
        "Position": "HB",
        "Caps": "4",
        "Goals": "0",
        "DebutYear": "1954",
        "DebutOpponent": "Switzerland"
    },
    {
        "Surname": "Drinkwater",
        "FullName": "Danny Drinkwater",
        "Position": "MF",
        "Caps": "3",
        "Goals": "0",
        "DebutYear": "2016",
        "DebutOpponent": "Netherlands"
    },
    {
        "Surname": "Thomas",
        "FullName": "Michael Thomas",
        "Position": "MF",
        "Caps": "2",
        "Goals": "0",
        "DebutYear": "1988",
        "DebutOpponent": "Saudi Arabia"
    },
    {
        "Surname": "Priest",
        "FullName": "Fred Priest",
        "Position": "FW",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1900",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Walden",
        "FullName": "Fanny Walden",
        "Position": "FW",
        "Caps": "2",
        "Goals": "0",
        "DebutYear": "1914",
        "DebutOpponent": "Scotland"
    },
    {
        "Surname": "Chalobah",
        "FullName": "Nathaniel Chalobah",
        "Position": "MF",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "2018",
        "DebutOpponent": "Spain"
    },
    {
        "Surname": "Forman",
        "FullName": "Fred Forman",
        "Position": "FW",
        "Caps": "3",
        "Goals": "2",
        "DebutYear": "1899",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Cahill",
        "FullName": "Gary Cahill",
        "Position": "DF",
        "Caps": "61",
        "Goals": "5",
        "DebutYear": "2010",
        "DebutOpponent": "Bulgaria"
    },
    {
        "Surname": "Mills",
        "FullName": "George Mills",
        "Position": "FW",
        "Caps": "3",
        "Goals": "3",
        "DebutYear": "1937",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Cooper",
        "FullName": "Norman Cooper",
        "Position": "HB",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1893",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Hurst",
        "FullName": "Geoff Hurst",
        "Position": "FW",
        "Caps": "49",
        "Goals": "24",
        "DebutYear": "1966",
        "DebutOpponent": "West Germany"
    },
    {
        "Surname": "Wilkins",
        "FullName": "Ray Wilkins",
        "Position": "MF",
        "Caps": "84",
        "Goals": "3",
        "DebutYear": "1976",
        "DebutOpponent": "Italy"
    },
    {
        "Surname": "Blockley",
        "FullName": "Jeff Blockley",
        "Position": "HB",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1972",
        "DebutOpponent": "Yugoslavia"
    },
    {
        "Surname": "Hawkes",
        "FullName": "Robert Hawkes",
        "Position": "HB",
        "Caps": "5",
        "Goals": "0",
        "DebutYear": "1907",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Dunn",
        "FullName": "Arthur Dunn",
        "Position": "FW",
        "Caps": "4",
        "Goals": "2",
        "DebutYear": "1883",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Sharp",
        "FullName": "Jack Sharp",
        "Position": "FW",
        "Caps": "2",
        "Goals": "1",
        "DebutYear": "1903",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Rocastle",
        "FullName": "David Rocastle",
        "Position": "MF",
        "Caps": "14",
        "Goals": "0",
        "DebutYear": "1988",
        "DebutOpponent": "Denmark"
    },
    {
        "Surname": "Barrett",
        "FullName": "Earl Barrett",
        "Position": "DF",
        "Caps": "3",
        "Goals": "0",
        "DebutYear": "1991",
        "DebutOpponent": "New Zealand"
    },
    {
        "Surname": "Bach",
        "FullName": "Phil Bach",
        "Position": "FB",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1899",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Connelly",
        "FullName": "John Connelly",
        "Position": "FW",
        "Caps": "20",
        "Goals": "7",
        "DebutYear": "1959",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Campbell",
        "FullName": "Austen Campbell",
        "Position": "HB",
        "Caps": "8",
        "Goals": "0",
        "DebutYear": "1928",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Flanagan",
        "FullName": "Jon Flanagan",
        "Position": "MF",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "2014",
        "DebutOpponent": "Ecuador"
    },
    {
        "Surname": "Wallace",
        "FullName": "Charlie Wallace",
        "Position": "FW",
        "Caps": "3",
        "Goals": "0",
        "DebutYear": "1913",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Kirchen",
        "FullName": "Alf Kirchen",
        "Position": "FW",
        "Caps": "3",
        "Goals": "2",
        "DebutYear": "1937",
        "DebutOpponent": "Norway"
    },
    {
        "Surname": "Roberts",
        "FullName": "Graham Roberts",
        "Position": "DF",
        "Caps": "6",
        "Goals": "0",
        "DebutYear": "1983",
        "DebutOpponent": "Northern Ireland"
    },
    {
        "Surname": "Miller",
        "FullName": "Brian Miller",
        "Position": "HB",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1961",
        "DebutOpponent": "Austria"
    },
    {
        "Surname": "Mercer",
        "FullName": "David Mercer",
        "Position": "FW",
        "Caps": "2",
        "Goals": "1",
        "DebutYear": "1922",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Ruddlesdin",
        "FullName": "Harry Ruddlesdin",
        "Position": "HB",
        "Caps": "3",
        "Goals": "0",
        "DebutYear": "1904",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Osgood",
        "FullName": "Peter Osgood",
        "Position": "FW",
        "Caps": "4",
        "Goals": "0",
        "DebutYear": "1970",
        "DebutOpponent": "Belgium"
    },
    {
        "Surname": "Dier",
        "FullName": "Eric Dier",
        "Position": "MF / DF",
        "Caps": "49",
        "Goals": "3",
        "DebutYear": "2015",
        "DebutOpponent": "Spain"
    },
    {
        "Surname": "Jones",
        "FullName": "Alf Jones",
        "Position": "FB",
        "Caps": "3",
        "Goals": "0",
        "DebutYear": "1882",
        "DebutOpponent": "Scotland"
    },
    {
        "Surname": "Toone",
        "FullName": "George Toone",
        "Position": "GK",
        "Caps": "2",
        "Goals": "0",
        "DebutYear": "1892",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Beattie",
        "FullName": "James Beattie",
        "Position": "FW",
        "Caps": "5",
        "Goals": "0",
        "DebutYear": "2003",
        "DebutOpponent": "Australia"
    },
    {
        "Surname": "Sewell",
        "FullName": "Ronnie Sewell",
        "Position": "GK",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1924",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Watson",
        "FullName": "Billy Watson",
        "Position": "HB",
        "Caps": "3",
        "Goals": "0",
        "DebutYear": "1913",
        "DebutOpponent": "Scotland"
    },
    {
        "Surname": "White",
        "FullName": "Cecil Holden-White",
        "Position": "HB",
        "Caps": "2",
        "Goals": "0",
        "DebutYear": "1888",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Jenas",
        "FullName": "Jermaine Jenas",
        "Position": "MF",
        "Caps": "21",
        "Goals": "1",
        "DebutYear": "2003",
        "DebutOpponent": "Australia"
    },
    {
        "Surname": "Lloyd",
        "FullName": "Larry Lloyd",
        "Position": "DF",
        "Caps": "4",
        "Goals": "0",
        "DebutYear": "1971",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Bridge",
        "FullName": "Wayne Bridge",
        "Position": "DF",
        "Caps": "36",
        "Goals": "1",
        "DebutYear": "2002",
        "DebutOpponent": "Netherlands"
    },
    {
        "Surname": "Quixall",
        "FullName": "Albert Quixall",
        "Position": "FW",
        "Caps": "5",
        "Goals": "0",
        "DebutYear": "1953",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Pease",
        "FullName": "Willie Pease",
        "Position": "FW",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1927",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Chambers",
        "FullName": "Harry Chambers",
        "Position": "FW",
        "Caps": "8",
        "Goals": "5",
        "DebutYear": "1921",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Spink",
        "FullName": "Nigel Spink",
        "Position": "GK",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1983",
        "DebutOpponent": "Australia"
    },
    {
        "Surname": "Hughes",
        "FullName": "Laurie Hughes",
        "Position": "FB",
        "Caps": "3",
        "Goals": "0",
        "DebutYear": "1950",
        "DebutOpponent": "Chile"
    },
    {
        "Surname": "Camsell",
        "FullName": "George Camsell",
        "Position": "FW",
        "Caps": "9",
        "Goals": "18",
        "DebutYear": "1929",
        "DebutOpponent": "France"
    },
    {
        "Surname": "Plum",
        "FullName": "Seth Plum",
        "Position": "HB",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1923",
        "DebutOpponent": "France"
    },
    {
        "Surname": "Wilson",
        "FullName": "Ray Wilson",
        "Position": "DF",
        "Caps": "63",
        "Goals": "0",
        "DebutYear": "1960",
        "DebutOpponent": "Scotland"
    },
    {
        "Surname": "Gough",
        "FullName": "Harold Gough",
        "Position": "GK",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1921",
        "DebutOpponent": "Scotland"
    },
    {
        "Surname": "Geaves",
        "FullName": "Richard Geaves",
        "Position": "FW",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1875",
        "DebutOpponent": "Scotland"
    },
    {
        "Surname": "Gerrard",
        "FullName": "Steven Gerrard",
        "Position": "MF",
        "Caps": "114",
        "Goals": "21",
        "DebutYear": "2000",
        "DebutOpponent": "Ukraine"
    },
    {
        "Surname": "Cuggy",
        "FullName": "Francis Cuggy",
        "Position": "HB",
        "Caps": "2",
        "Goals": "0",
        "DebutYear": "1913",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "McDonald",
        "FullName": "Colin McDonald",
        "Position": "GK",
        "Caps": "8",
        "Goals": "0",
        "DebutYear": "1958",
        "DebutOpponent": "Soviet Union"
    },
    {
        "Surname": "Wright",
        "FullName": "Mark Wright",
        "Position": "DF",
        "Caps": "45",
        "Goals": "1",
        "DebutYear": "1984",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Wright",
        "FullName": "Billy Wright",
        "Position": "HB",
        "Caps": "105",
        "Goals": "3",
        "DebutYear": "1946",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Neville",
        "FullName": "Phil Neville",
        "Position": "DF",
        "Caps": "59",
        "Goals": "0",
        "DebutYear": "1996",
        "DebutOpponent": "China"
    },
    {
        "Surname": "Cohen",
        "FullName": "George Cohen",
        "Position": "DF",
        "Caps": "37",
        "Goals": "0",
        "DebutYear": "1964",
        "DebutOpponent": "Uruguay"
    },
    {
        "Surname": "Norman",
        "FullName": "Maurice Norman",
        "Position": "DF",
        "Caps": "23",
        "Goals": "0",
        "DebutYear": "1962",
        "DebutOpponent": "Peru"
    },
    {
        "Surname": "Shaw",
        "FullName": "Luke Shaw",
        "Position": "DF",
        "Caps": "29",
        "Goals": "3",
        "DebutYear": "2014",
        "DebutOpponent": "Denmark"
    },
    {
        "Surname": "Shelton",
        "FullName": "Alf Shelton",
        "Position": "HB",
        "Caps": "6",
        "Goals": "1",
        "DebutYear": "1889",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Smith",
        "FullName": "Charles Smith",
        "Position": "FW",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1876",
        "DebutOpponent": "Scotland"
    },
    {
        "Surname": "Aston",
        "FullName": "John Aston",
        "Position": "FB",
        "Caps": "17",
        "Goals": "0",
        "DebutYear": "1948",
        "DebutOpponent": "Denmark"
    },
    {
        "Surname": "Amos",
        "FullName": "Andrew Amos",
        "Position": "HB",
        "Caps": "2",
        "Goals": "0",
        "DebutYear": "1885",
        "DebutOpponent": "Scotland"
    },
    {
        "Surname": "Sutcliffe",
        "FullName": "John Sutcliffe",
        "Position": "GK",
        "Caps": "5",
        "Goals": "0",
        "DebutYear": "1893",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Christian",
        "FullName": "Edward Christian",
        "Position": "FB",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1879",
        "DebutOpponent": "Scotland"
    },
    {
        "Surname": "Morley",
        "FullName": "Bert Morley",
        "Position": "FB",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1910",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Ducat",
        "FullName": "Andy Ducat",
        "Position": "HB",
        "Caps": "6",
        "Goals": "1",
        "DebutYear": "1910",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Merson",
        "FullName": "Paul Merson",
        "Position": "MF",
        "Caps": "21",
        "Goals": "3",
        "DebutYear": "1991",
        "DebutOpponent": "Germany"
    },
    {
        "Surname": "Johnstone",
        "FullName": "Sam Johnstone",
        "Position": "GK",
        "Caps": "3",
        "Goals": "0",
        "DebutYear": "2021",
        "DebutOpponent": "Romania"
    },
    {
        "Surname": "Weir",
        "FullName": "Davie Weir",
        "Position": "HB",
        "Caps": "2",
        "Goals": "2",
        "DebutYear": "1889",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Balmer",
        "FullName": "Billy Balmer",
        "Position": "FB",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1905",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Southworth",
        "FullName": "Jack Southworth",
        "Position": "FW",
        "Caps": "3",
        "Goals": "3",
        "DebutYear": "1889",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Cunliffe",
        "FullName": "Dan Cunliffe",
        "Position": "FW",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1900",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Latchford",
        "FullName": "Bob Latchford",
        "Position": "FW",
        "Caps": "12",
        "Goals": "5",
        "DebutYear": "1977",
        "DebutOpponent": "Italy"
    },
    {
        "Surname": "Currie",
        "FullName": "Tony Currie",
        "Position": "MF",
        "Caps": "17",
        "Goals": "3",
        "DebutYear": "1972",
        "DebutOpponent": "Northern Ireland"
    },
    {
        "Surname": "Sandilands",
        "FullName": "Rupert Sandilands",
        "Position": "FW",
        "Caps": "5",
        "Goals": "3",
        "DebutYear": "1892",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Green",
        "FullName": "George Green",
        "Position": "HB",
        "Caps": "8",
        "Goals": "0",
        "DebutYear": "1925",
        "DebutOpponent": "France"
    },
    {
        "Surname": "Thomas",
        "FullName": "Dave Thomas",
        "Position": "MF",
        "Caps": "8",
        "Goals": "0",
        "DebutYear": "1974",
        "DebutOpponent": "Czechoslovakia"
    },
    {
        "Surname": "Sparks",
        "FullName": "Francis Sparks",
        "Position": "FW",
        "Caps": "3",
        "Goals": "3",
        "DebutYear": "1879",
        "DebutOpponent": "Scotland"
    },
    {
        "Surname": "Pickering",
        "FullName": "Fred Pickering",
        "Position": "FW",
        "Caps": "3",
        "Goals": "5",
        "DebutYear": "1964",
        "DebutOpponent": "United States"
    },
    {
        "Surname": "Robb",
        "FullName": "George Robb",
        "Position": "FW",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1953",
        "DebutOpponent": "Hungary"
    },
    {
        "Surname": "Felton",
        "FullName": "Billy Felton",
        "Position": "FB",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1925",
        "DebutOpponent": "France"
    },
    {
        "Surname": "Kelly",
        "FullName": "Bob Kelly",
        "Position": "FW",
        "Caps": "14",
        "Goals": "8",
        "DebutYear": "1920",
        "DebutOpponent": "Scotland"
    },
    {
        "Surname": "Ruffell",
        "FullName": "Jimmy Ruffell",
        "Position": "FW",
        "Caps": "6",
        "Goals": "0",
        "DebutYear": "1926",
        "DebutOpponent": "Scotland"
    },
    {
        "Surname": "Barton",
        "FullName": "John Barton",
        "Position": "HB",
        "Caps": "1",
        "Goals": "1",
        "DebutYear": "1890",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Mercer",
        "FullName": "Joe Mercer",
        "Position": "HB",
        "Caps": "5",
        "Goals": "0",
        "DebutYear": "1938",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Hibbs",
        "FullName": "Harry Hibbs",
        "Position": "GK",
        "Caps": "25",
        "Goals": "0",
        "DebutYear": "1929",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Smith",
        "FullName": "Arnold Kirke Smith",
        "Position": "FW",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1872",
        "DebutOpponent": "Scotland"
    },
    {
        "Surname": "Corbett",
        "FullName": "Watty Corbett",
        "Position": "FB",
        "Caps": "3",
        "Goals": "0",
        "DebutYear": "1908",
        "DebutOpponent": "Austria"
    },
    {
        "Surname": "Harris",
        "FullName": "Peter Harris",
        "Position": "FW",
        "Caps": "2",
        "Goals": "0",
        "DebutYear": "1949",
        "DebutOpponent": "Republic of Ireland"
    },
    {
        "Surname": "Smith",
        "FullName": "Jack Smith",
        "Position": "FW",
        "Caps": "3",
        "Goals": "4",
        "DebutYear": "1931",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Warner",
        "FullName": "Conrad Warner",
        "Position": "GK",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1878",
        "DebutOpponent": "Scotland"
    },
    {
        "Surname": "Blackburn",
        "FullName": "George Blackburn",
        "Position": "HB",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1924",
        "DebutOpponent": "France"
    },
    {
        "Surname": "Paravicini",
        "FullName": "Percy de Paravicini",
        "Position": "FB",
        "Caps": "3",
        "Goals": "0",
        "DebutYear": "1883",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Jones",
        "FullName": "William Jones",
        "Position": "HB",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1901",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Davison",
        "FullName": "Teddy Davison",
        "Position": "GK",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1922",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Jack",
        "FullName": "David Jack",
        "Position": "FW",
        "Caps": "9",
        "Goals": "3",
        "DebutYear": "1924",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Ward",
        "FullName": "Peter Ward",
        "Position": "MF",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1980",
        "DebutOpponent": "Australia"
    },
    {
        "Surname": "Dobson",
        "FullName": "Charles Dobson",
        "Position": "HB",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1886",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Brown",
        "FullName": "Jack Brown",
        "Position": "GK",
        "Caps": "6",
        "Goals": "0",
        "DebutYear": "1927",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Broome",
        "FullName": "Frank Broome",
        "Position": "FW",
        "Caps": "7",
        "Goals": "3",
        "DebutYear": "1938",
        "DebutOpponent": "Germany"
    },
    {
        "Surname": "Windridge",
        "FullName": "Jimmy Windridge",
        "Position": "FW",
        "Caps": "8",
        "Goals": "7",
        "DebutYear": "1908",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Tunstall",
        "FullName": "Fred Tunstall",
        "Position": "FW",
        "Caps": "7",
        "Goals": "0",
        "DebutYear": "1923",
        "DebutOpponent": "Scotland"
    },
    {
        "Surname": "Bambridge",
        "FullName": "Arthur Bambridge",
        "Position": "FW",
        "Caps": "3",
        "Goals": "1",
        "DebutYear": "1881",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Bestall",
        "FullName": "Jackie Bestall",
        "Position": "FW",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1935",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Wheeler",
        "FullName": "Johnny Wheeler",
        "Position": "HB",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1954",
        "DebutOpponent": "Northern Ireland"
    },
    {
        "Surname": "Baily",
        "FullName": "Eddie Baily",
        "Position": "FW",
        "Caps": "9",
        "Goals": "5",
        "DebutYear": "1950",
        "DebutOpponent": "Spain"
    },
    {
        "Surname": "Broadis",
        "FullName": "Ivor Broadis",
        "Position": "FW",
        "Caps": "14",
        "Goals": "8",
        "DebutYear": "1951",
        "DebutOpponent": "Austria"
    },
    {
        "Surname": "Coady",
        "FullName": "Conor Coady",
        "Position": "DF",
        "Caps": "10",
        "Goals": "1",
        "DebutYear": "2020",
        "DebutOpponent": "Denmark"
    },
    {
        "Surname": "Marwood",
        "FullName": "Brian Marwood",
        "Position": "MF",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1988",
        "DebutOpponent": "Saudi Arabia"
    },
    {
        "Surname": "Phillips",
        "FullName": "Kevin Phillips",
        "Position": "FW",
        "Caps": "8",
        "Goals": "0",
        "DebutYear": "1999",
        "DebutOpponent": "Hungary"
    },
    {
        "Surname": "Owen",
        "FullName": "Michael Owen",
        "Position": "FW",
        "Caps": "89",
        "Goals": "40",
        "DebutYear": "1998",
        "DebutOpponent": "Chile"
    },
    {
        "Surname": "Hillman",
        "FullName": "Jack Hillman",
        "Position": "GK",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1899",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Freeman",
        "FullName": "Bert Freeman",
        "Position": "FW",
        "Caps": "5",
        "Goals": "3",
        "DebutYear": "1909",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Edwards",
        "FullName": "John Edwards",
        "Position": "FW",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1874",
        "DebutOpponent": "Scotland"
    },
    {
        "Surname": "Francis",
        "FullName": "Gerry Francis",
        "Position": "MF",
        "Caps": "12",
        "Goals": "3",
        "DebutYear": "1974",
        "DebutOpponent": "Czechoslovakia"
    },
    {
        "Surname": "Cole",
        "FullName": "Carlton Cole",
        "Position": "FW",
        "Caps": "7",
        "Goals": "0",
        "DebutYear": "2009",
        "DebutOpponent": "Spain"
    },
    {
        "Surname": "Osborne",
        "FullName": "Reg Osborne",
        "Position": "FB",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1927",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Brann",
        "FullName": "George Brann",
        "Position": "FW",
        "Caps": "3",
        "Goals": "0",
        "DebutYear": "1886",
        "DebutOpponent": "Scotland"
    },
    {
        "Surname": "Williams",
        "FullName": "Billy Williams",
        "Position": "FB",
        "Caps": "6",
        "Goals": "0",
        "DebutYear": "1897",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Woodcock",
        "FullName": "Tony Woodcock",
        "Position": "FW",
        "Caps": "42",
        "Goals": "16",
        "DebutYear": "1978",
        "DebutOpponent": "Northern Ireland"
    },
    {
        "Surname": "Holmes",
        "FullName": "Bob Holmes",
        "Position": "FB",
        "Caps": "7",
        "Goals": "0",
        "DebutYear": "1888",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Fry",
        "FullName": "C. B. Fry",
        "Position": "FB",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1901",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Carroll",
        "FullName": "Andy Carroll",
        "Position": "FW",
        "Caps": "9",
        "Goals": "2",
        "DebutYear": "2010",
        "DebutOpponent": "France"
    },
    {
        "Surname": "Waiters",
        "FullName": "Tony Waiters",
        "Position": "GK",
        "Caps": "5",
        "Goals": "0",
        "DebutYear": "1964",
        "DebutOpponent": "Republic of Ireland"
    },
    {
        "Surname": "Simms",
        "FullName": "Ernie Simms",
        "Position": "FW",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1921",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Clapton",
        "FullName": "Danny Clapton",
        "Position": "FW",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1958",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Burnup",
        "FullName": "Cuthbert Burnup",
        "Position": "FW",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1896",
        "DebutOpponent": "Scotland"
    },
    {
        "Surname": "Needham",
        "FullName": "Ernest Needham",
        "Position": "HB",
        "Caps": "16",
        "Goals": "3",
        "DebutYear": "1894",
        "DebutOpponent": "Scotland"
    },
    {
        "Surname": "Swan",
        "FullName": "Peter Swan",
        "Position": "DF",
        "Caps": "19",
        "Goals": "0",
        "DebutYear": "1960",
        "DebutOpponent": "Yugoslavia"
    },
    {
        "Surname": "Tremelling",
        "FullName": "Dan Tremelling",
        "Position": "GK",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1927",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Gregory",
        "FullName": "John Gregory",
        "Position": "MF",
        "Caps": "6",
        "Goals": "0",
        "DebutYear": "1983",
        "DebutOpponent": "Australia"
    },
    {
        "Surname": "Batty",
        "FullName": "David Batty",
        "Position": "MF",
        "Caps": "42",
        "Goals": "0",
        "DebutYear": "1991",
        "DebutOpponent": "Soviet Union"
    },
    {
        "Surname": "Stanbrough",
        "FullName": "Hugh Stanbrough",
        "Position": "FW",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1895",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Wright",
        "FullName": "Doug Wright",
        "Position": "FW",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1938",
        "DebutOpponent": "Norway"
    },
    {
        "Surname": "Keegan",
        "FullName": "Kevin Keegan",
        "Position": "MF / FW",
        "Caps": "63",
        "Goals": "21",
        "DebutYear": "1972",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Rimmer",
        "FullName": "Jimmy Rimmer",
        "Position": "GK",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1976",
        "DebutOpponent": "Italy"
    },
    {
        "Surname": "Healless",
        "FullName": "Henry Healless",
        "Position": "HB",
        "Caps": "2",
        "Goals": "0",
        "DebutYear": "1924",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Conlin",
        "FullName": "James Conlin",
        "Position": "FW",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1906",
        "DebutOpponent": "Scotland"
    },
    {
        "Surname": "Lipsham",
        "FullName": "Bert Lipsham",
        "Position": "FW",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1902",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Elliott",
        "FullName": "George Elliott",
        "Position": "FW",
        "Caps": "3",
        "Goals": "0",
        "DebutYear": "1913",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Carr",
        "FullName": "Jackie Carr",
        "Position": "FW",
        "Caps": "2",
        "Goals": "0",
        "DebutYear": "1919",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Boyer",
        "FullName": "Phil Boyer",
        "Position": "FW",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1976",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Hargreaves",
        "FullName": "John Hargreaves",
        "Position": "FW",
        "Caps": "2",
        "Goals": "0",
        "DebutYear": "1881",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Moon",
        "FullName": "Billy Moon",
        "Position": "GK",
        "Caps": "7",
        "Goals": "0",
        "DebutYear": "1888",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Wilkinson",
        "FullName": "Leonard Wilkinson",
        "Position": "GK",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1891",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Thomson",
        "FullName": "Bobby Thomson",
        "Position": "FB",
        "Caps": "8",
        "Goals": "0",
        "DebutYear": "1963",
        "DebutOpponent": "Northern Ireland"
    },
    {
        "Surname": "Ramsdale",
        "FullName": "Aaron Ramsdale",
        "Position": "GK",
        "Caps": "3",
        "Goals": "0",
        "DebutYear": "2021",
        "DebutOpponent": "San Marino"
    },
    {
        "Surname": "Meehan",
        "FullName": "Tommy Meehan",
        "Position": "HB",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1923",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Cunningham",
        "FullName": "Laurie Cunningham",
        "Position": "MF",
        "Caps": "6",
        "Goals": "0",
        "DebutYear": "1979",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Male",
        "FullName": "George Male",
        "Position": "FB",
        "Caps": "19",
        "Goals": "0",
        "DebutYear": "1934",
        "DebutOpponent": "Italy"
    },
    {
        "Surname": "Clemence",
        "FullName": "Ray Clemence",
        "Position": "GK",
        "Caps": "61",
        "Goals": "0",
        "DebutYear": "1972",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Settle",
        "FullName": "Jimmy Settle",
        "Position": "FW",
        "Caps": "6",
        "Goals": "6",
        "DebutYear": "1899",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Shaw",
        "FullName": "George Shaw",
        "Position": "FB",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1932",
        "DebutOpponent": "Scotland"
    },
    {
        "Surname": "James",
        "FullName": "David James",
        "Position": "GK",
        "Caps": "53",
        "Goals": "0",
        "DebutYear": "1997",
        "DebutOpponent": "Mexico"
    },
    {
        "Surname": "Kirkland",
        "FullName": "Chris Kirkland",
        "Position": "GK",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "2006",
        "DebutOpponent": "Greece"
    },
    {
        "Surname": "Geary",
        "FullName": "Fred Geary",
        "Position": "FW",
        "Caps": "2",
        "Goals": "3",
        "DebutYear": "1890",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Butcher",
        "FullName": "Terry Butcher",
        "Position": "DF",
        "Caps": "77",
        "Goals": "3",
        "DebutYear": "1980",
        "DebutOpponent": "Australia"
    },
    {
        "Surname": "Steven",
        "FullName": "Trevor Steven",
        "Position": "MF",
        "Caps": "36",
        "Goals": "4",
        "DebutYear": "1985",
        "DebutOpponent": "Northern Ireland"
    },
    {
        "Surname": "Rooney",
        "FullName": "Wayne Rooney",
        "Position": "FW",
        "Caps": "120",
        "Goals": "53",
        "DebutYear": "2003",
        "DebutOpponent": "Australia"
    },
    {
        "Surname": "Barker",
        "FullName": "Jack Barker",
        "Position": "HB",
        "Caps": "11",
        "Goals": "0",
        "DebutYear": "1934",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Beverley",
        "FullName": "Joe Beverley",
        "Position": "FB",
        "Caps": "3",
        "Goals": "0",
        "DebutYear": "1884",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Shaw",
        "FullName": "Graham Shaw",
        "Position": "FB",
        "Caps": "5",
        "Goals": "0",
        "DebutYear": "1958",
        "DebutOpponent": "Soviet Union"
    },
    {
        "Surname": "Compton",
        "FullName": "Leslie Compton",
        "Position": "HB",
        "Caps": "2",
        "Goals": "0",
        "DebutYear": "1950",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "York",
        "FullName": "Richard York",
        "Position": "FW",
        "Caps": "2",
        "Goals": "0",
        "DebutYear": "1922",
        "DebutOpponent": "Scotland"
    },
    {
        "Surname": "Richardson",
        "FullName": "Jimmy Richardson",
        "Position": "FW",
        "Caps": "2",
        "Goals": "2",
        "DebutYear": "1933",
        "DebutOpponent": "Italy"
    },
    {
        "Surname": "Walton",
        "FullName": "Nat Walton",
        "Position": "FW",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1890",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Howe",
        "FullName": "Don Howe",
        "Position": "FB",
        "Caps": "23",
        "Goals": "0",
        "DebutYear": "1957",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Cooper",
        "FullName": "Tom Cooper",
        "Position": "FB",
        "Caps": "15",
        "Goals": "0",
        "DebutYear": "1927",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Gunn",
        "FullName": "William Gunn",
        "Position": "FW",
        "Caps": "2",
        "Goals": "1",
        "DebutYear": "1884",
        "DebutOpponent": "Scotland"
    },
    {
        "Surname": "Pointer",
        "FullName": "Ray Pointer",
        "Position": "FW",
        "Caps": "3",
        "Goals": "2",
        "DebutYear": "1961",
        "DebutOpponent": "Luxembourg"
    },
    {
        "Surname": "Toney",
        "FullName": "Ivan Toney",
        "Position": "FW",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "2023",
        "DebutOpponent": "Ukraine"
    },
    {
        "Surname": "Kean",
        "FullName": "Fred Kean",
        "Position": "HB",
        "Caps": "9",
        "Goals": "0",
        "DebutYear": "1923",
        "DebutOpponent": "Belgium"
    },
    {
        "Surname": "Wood",
        "FullName": "Ray Wood",
        "Position": "GK",
        "Caps": "3",
        "Goals": "0",
        "DebutYear": "1954",
        "DebutOpponent": "Northern Ireland"
    },
    {
        "Surname": "Jagielka",
        "FullName": "Phil Jagielka",
        "Position": "DF",
        "Caps": "40",
        "Goals": "3",
        "DebutYear": "2008",
        "DebutOpponent": "Trinidad and Tobago"
    },
    {
        "Surname": "Maskrey",
        "FullName": "Harry Maskrey",
        "Position": "GK",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1908",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Hedley",
        "FullName": "George Hedley",
        "Position": "FW",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1901",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Kail",
        "FullName": "Edgar Kail",
        "Position": "FW",
        "Caps": "3",
        "Goals": "2",
        "DebutYear": "1929",
        "DebutOpponent": "France"
    },
    {
        "Surname": "Dunk",
        "FullName": "Lewis Dunk",
        "Position": "DF",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "2018",
        "DebutOpponent": "United States"
    },
    {
        "Surname": "Pearce",
        "FullName": "Stuart Pearce",
        "Position": "DF",
        "Caps": "78",
        "Goals": "5",
        "DebutYear": "1987",
        "DebutOpponent": "Brazil"
    },
    {
        "Surname": "Charles",
        "FullName": "Gary Charles",
        "Position": "DF",
        "Caps": "2",
        "Goals": "0",
        "DebutYear": "1991",
        "DebutOpponent": "New Zealand"
    },
    {
        "Surname": "Jones",
        "FullName": "Rob Jones",
        "Position": "DF",
        "Caps": "8",
        "Goals": "0",
        "DebutYear": "1992",
        "DebutOpponent": "France"
    },
    {
        "Surname": "Crowe",
        "FullName": "Chris Crowe",
        "Position": "FW",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1962",
        "DebutOpponent": "France"
    },
    {
        "Surname": "Tarkowski",
        "FullName": "James Tarkowski",
        "Position": "DF",
        "Caps": "2",
        "Goals": "0",
        "DebutYear": "2018",
        "DebutOpponent": "Italy"
    },
    {
        "Surname": "Wilson",
        "FullName": "Tom Wilson",
        "Position": "HB",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1928",
        "DebutOpponent": "Scotland"
    },
    {
        "Surname": "Venison",
        "FullName": "Barry Venison",
        "Position": "DF",
        "Caps": "2",
        "Goals": "0",
        "DebutYear": "1994",
        "DebutOpponent": "United States"
    },
    {
        "Surname": "Reader",
        "FullName": "Joe Reader",
        "Position": "GK",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1894",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Welsh",
        "FullName": "Don Welsh",
        "Position": "HB",
        "Caps": "3",
        "Goals": "1",
        "DebutYear": "1938",
        "DebutOpponent": "Germany"
    },
    {
        "Surname": "Cursham",
        "FullName": "Harry Cursham",
        "Position": "FW",
        "Caps": "8",
        "Goals": "5",
        "DebutYear": "1880",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Mosforth",
        "FullName": "Billy Mosforth",
        "Position": "FW",
        "Caps": "9",
        "Goals": "3",
        "DebutYear": "1877",
        "DebutOpponent": "Scotland"
    },
    {
        "Surname": "Coppell",
        "FullName": "Steve Coppell",
        "Position": "MF",
        "Caps": "42",
        "Goals": "7",
        "DebutYear": "1977",
        "DebutOpponent": "Italy"
    },
    {
        "Surname": "Lilley",
        "FullName": "Harry Lilley",
        "Position": "FB",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1892",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Rickaby",
        "FullName": "Stan Rickaby",
        "Position": "FB",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1953",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Howell",
        "FullName": "Rab Howell",
        "Position": "HB",
        "Caps": "2",
        "Goals": "1",
        "DebutYear": "1895",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Taylor",
        "FullName": "Jim Taylor",
        "Position": "HB",
        "Caps": "2",
        "Goals": "0",
        "DebutYear": "1951",
        "DebutOpponent": "Argentina"
    },
    {
        "Surname": "Sewell",
        "FullName": "Jackie Sewell",
        "Position": "FW",
        "Caps": "6",
        "Goals": "3",
        "DebutYear": "1951",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Foster",
        "FullName": "Steve Foster",
        "Position": "DF",
        "Caps": "3",
        "Goals": "0",
        "DebutYear": "1982",
        "DebutOpponent": "Northern Ireland"
    },
    {
        "Surname": "Thornley",
        "FullName": "Irvine Thornley",
        "Position": "FW",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1907",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Kingsford",
        "FullName": "Robert Kingsford",
        "Position": "FW",
        "Caps": "1",
        "Goals": "1",
        "DebutYear": "1874",
        "DebutOpponent": "Scotland"
    },
    {
        "Surname": "Lofthouse",
        "FullName": "Nat Lofthouse",
        "Position": "FW",
        "Caps": "33",
        "Goals": "30",
        "DebutYear": "1950",
        "DebutOpponent": "Yugoslavia"
    },
    {
        "Surname": "Lintott",
        "FullName": "Evelyn Lintott",
        "Position": "HB",
        "Caps": "7",
        "Goals": "0",
        "DebutYear": "1908",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Chambers",
        "FullName": "Calum Chambers",
        "Position": "DF",
        "Caps": "3",
        "Goals": "0",
        "DebutYear": "2014",
        "DebutOpponent": "Norway"
    },
    {
        "Surname": "Haworth",
        "FullName": "George Haworth",
        "Position": "HB",
        "Caps": "5",
        "Goals": "0",
        "DebutYear": "1887",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Livermore",
        "FullName": "Jake Livermore",
        "Position": "MF",
        "Caps": "7",
        "Goals": "0",
        "DebutYear": "2012",
        "DebutOpponent": "Italy"
    },
    {
        "Surname": "Gurney",
        "FullName": "Bobby Gurney",
        "Position": "FW",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1935",
        "DebutOpponent": "Scotland"
    },
    {
        "Surname": "Spence",
        "FullName": "Dick Spence",
        "Position": "FW",
        "Caps": "2",
        "Goals": "0",
        "DebutYear": "1936",
        "DebutOpponent": "Austria"
    },
    {
        "Surname": "Parker",
        "FullName": "Scott Parker",
        "Position": "MF",
        "Caps": "18",
        "Goals": "0",
        "DebutYear": "2003",
        "DebutOpponent": "Denmark"
    },
    {
        "Surname": "Barham",
        "FullName": "Mark Barham",
        "Position": "MF",
        "Caps": "2",
        "Goals": "0",
        "DebutYear": "1983",
        "DebutOpponent": "Australia"
    },
    {
        "Surname": "Mannion",
        "FullName": "Wilf Mannion",
        "Position": "FW",
        "Caps": "26",
        "Goals": "11",
        "DebutYear": "1946",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Parkes",
        "FullName": "Phil Parkes",
        "Position": "GK",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1974",
        "DebutOpponent": "Portugal"
    },
    {
        "Surname": "Ashcroft",
        "FullName": "Jimmy Ashcroft",
        "Position": "GK",
        "Caps": "3",
        "Goals": "0",
        "DebutYear": "1906",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Barton",
        "FullName": "Joey Barton",
        "Position": "MF",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "2007",
        "DebutOpponent": "Spain"
    },
    {
        "Surname": "Gee",
        "FullName": "Charles Gee",
        "Position": "HB",
        "Caps": "3",
        "Goals": "0",
        "DebutYear": "1931",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Leake",
        "FullName": "Alex Leake",
        "Position": "HB",
        "Caps": "5",
        "Goals": "0",
        "DebutYear": "1904",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Smith",
        "FullName": "Lionel Smith",
        "Position": "FB",
        "Caps": "6",
        "Goals": "0",
        "DebutYear": "1950",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Mills",
        "FullName": "Danny Mills",
        "Position": "MF / DF",
        "Caps": "19",
        "Goals": "0",
        "DebutYear": "2001",
        "DebutOpponent": "Mexico"
    },
    {
        "Surname": "Osborne",
        "FullName": "Frank Osborne",
        "Position": "FW",
        "Caps": "4",
        "Goals": "3",
        "DebutYear": "1922",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Hilsdon",
        "FullName": "George Hilsdon",
        "Position": "FW",
        "Caps": "8",
        "Goals": "14",
        "DebutYear": "1907",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Clement",
        "FullName": "Dave Clement",
        "Position": "DF",
        "Caps": "5",
        "Goals": "0",
        "DebutYear": "1976",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Robson",
        "FullName": "Bobby Robson",
        "Position": "FW / HB",
        "Caps": "20",
        "Goals": "4",
        "DebutYear": "1957",
        "DebutOpponent": "France"
    },
    {
        "Surname": "Bradford",
        "FullName": "Joe Bradford",
        "Position": "FW",
        "Caps": "12",
        "Goals": "7",
        "DebutYear": "1923",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Flowers",
        "FullName": "Tim Flowers",
        "Position": "GK",
        "Caps": "11",
        "Goals": "0",
        "DebutYear": "1993",
        "DebutOpponent": "Brazil"
    },
    {
        "Surname": "Goodall",
        "FullName": "Roy Goodall",
        "Position": "FB",
        "Caps": "25",
        "Goals": "0",
        "DebutYear": "1926",
        "DebutOpponent": "Scotland"
    },
    {
        "Surname": "Hateley",
        "FullName": "Mark Hateley",
        "Position": "FW",
        "Caps": "32",
        "Goals": "9",
        "DebutYear": "1984",
        "DebutOpponent": "Soviet Union"
    },
    {
        "Surname": "Longworth",
        "FullName": "Ephraim Longworth",
        "Position": "FB",
        "Caps": "5",
        "Goals": "0",
        "DebutYear": "1920",
        "DebutOpponent": "Scotland"
    },
    {
        "Surname": "Brook",
        "FullName": "Eric Brook",
        "Position": "FW",
        "Caps": "18",
        "Goals": "10",
        "DebutYear": "1929",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Astall",
        "FullName": "Gordon Astall",
        "Position": "FW",
        "Caps": "2",
        "Goals": "1",
        "DebutYear": "1956",
        "DebutOpponent": "Finland"
    },
    {
        "Surname": "Konchesky",
        "FullName": "Paul Konchesky",
        "Position": "DF",
        "Caps": "2",
        "Goals": "0",
        "DebutYear": "2003",
        "DebutOpponent": "Australia"
    },
    {
        "Surname": "Bond",
        "FullName": "Dicky Bond",
        "Position": "FW",
        "Caps": "8",
        "Goals": "2",
        "DebutYear": "1905",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Davenport",
        "FullName": "Kenny Davenport",
        "Position": "FW",
        "Caps": "2",
        "Goals": "2",
        "DebutYear": "1885",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Robinson",
        "FullName": "Jack Robinson",
        "Position": "GK",
        "Caps": "11",
        "Goals": "0",
        "DebutYear": "1897",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Perryman",
        "FullName": "Steve Perryman",
        "Position": "MF",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1982",
        "DebutOpponent": "Iceland"
    },
    {
        "Surname": "Parker",
        "FullName": "Paul Parker",
        "Position": "DF",
        "Caps": "19",
        "Goals": "0",
        "DebutYear": "1989",
        "DebutOpponent": "Albania"
    },
    {
        "Surname": "Johnson",
        "FullName": "Andrew Johnson",
        "Position": "FW",
        "Caps": "8",
        "Goals": "0",
        "DebutYear": "2005",
        "DebutOpponent": "Netherlands"
    },
    {
        "Surname": "Thickitt",
        "FullName": "Harry Thickitt",
        "Position": "HB",
        "Caps": "2",
        "Goals": "0",
        "DebutYear": "1899",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Willingham",
        "FullName": "Ken Willingham",
        "Position": "HB",
        "Caps": "12",
        "Goals": "1",
        "DebutYear": "1937",
        "DebutOpponent": "Finland"
    },
    {
        "Surname": "Green",
        "FullName": "Robert Green",
        "Position": "GK",
        "Caps": "12",
        "Goals": "0",
        "DebutYear": "2005",
        "DebutOpponent": "Colombia"
    },
    {
        "Surname": "Powell",
        "FullName": "Chris Powell",
        "Position": "DF",
        "Caps": "5",
        "Goals": "0",
        "DebutYear": "2001",
        "DebutOpponent": "Spain"
    },
    {
        "Surname": "Bould",
        "FullName": "Steve Bould",
        "Position": "DF",
        "Caps": "2",
        "Goals": "0",
        "DebutYear": "1994",
        "DebutOpponent": "Greece"
    },
    {
        "Surname": "Lee",
        "FullName": "Sammy Lee",
        "Position": "MF",
        "Caps": "14",
        "Goals": "2",
        "DebutYear": "1982",
        "DebutOpponent": "Greece"
    },
    {
        "Surname": "Graham",
        "FullName": "Leonard Graham",
        "Position": "HB",
        "Caps": "2",
        "Goals": "0",
        "DebutYear": "1925",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Perry",
        "FullName": "Tom Perry",
        "Position": "HB",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1898",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Roberts",
        "FullName": "Charlie Roberts",
        "Position": "HB",
        "Caps": "3",
        "Goals": "0",
        "DebutYear": "1905",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Royle",
        "FullName": "Joe Royle",
        "Position": "FW",
        "Caps": "6",
        "Goals": "2",
        "DebutYear": "1971",
        "DebutOpponent": "Malta"
    },
    {
        "Surname": "Taylor",
        "FullName": "Ernie Taylor",
        "Position": "FW",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1953",
        "DebutOpponent": "Hungary"
    },
    {
        "Surname": "Rowe",
        "FullName": "Emile Smith Rowe",
        "Position": "MF",
        "Caps": "3",
        "Goals": "1",
        "DebutYear": "2021",
        "DebutOpponent": "Albania"
    },
    {
        "Surname": "Hopkinson",
        "FullName": "Eddie Hopkinson",
        "Position": "GK",
        "Caps": "14",
        "Goals": "0",
        "DebutYear": "1957",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Cobbold",
        "FullName": "Nevill Cobbold",
        "Position": "FW",
        "Caps": "9",
        "Goals": "6",
        "DebutYear": "1883",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Fenton",
        "FullName": "Micky Fenton",
        "Position": "FW",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1938",
        "DebutOpponent": "Scotland"
    },
    {
        "Surname": "Beresford",
        "FullName": "Joe Beresford",
        "Position": "FW",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1934",
        "DebutOpponent": "Czechoslovakia"
    },
    {
        "Surname": "Armfield",
        "FullName": "Jimmy Armfield",
        "Position": "DF",
        "Caps": "43",
        "Goals": "0",
        "DebutYear": "1959",
        "DebutOpponent": "Brazil"
    },
    {
        "Surname": "Ashton",
        "FullName": "Claude Ashton",
        "Position": "FW",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1925",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Puddefoot",
        "FullName": "Syd Puddefoot",
        "Position": "FW",
        "Caps": "2",
        "Goals": "0",
        "DebutYear": "1925",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Roberts",
        "FullName": "Tommy Roberts",
        "Position": "FW",
        "Caps": "2",
        "Goals": "2",
        "DebutYear": "1923",
        "DebutOpponent": "Belgium"
    },
    {
        "Surname": "Grosvenor",
        "FullName": "Tom Grosvenor",
        "Position": "FW",
        "Caps": "3",
        "Goals": "2",
        "DebutYear": "1933",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Vassell",
        "FullName": "Darius Vassell",
        "Position": "FW",
        "Caps": "22",
        "Goals": "6",
        "DebutYear": "2002",
        "DebutOpponent": "Netherlands"
    },
    {
        "Surname": "Sinclair",
        "FullName": "Trevor Sinclair",
        "Position": "MF",
        "Caps": "12",
        "Goals": "0",
        "DebutYear": "2001",
        "DebutOpponent": "Sweden"
    },
    {
        "Surname": "Abbott",
        "FullName": "Walter Abbott",
        "Position": "HB",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1902",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Pickering",
        "FullName": "Nick Pickering",
        "Position": "DF",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1983",
        "DebutOpponent": "Australia"
    },
    {
        "Surname": "Cowan",
        "FullName": "Sam Cowan",
        "Position": "HB",
        "Caps": "3",
        "Goals": "0",
        "DebutYear": "1926",
        "DebutOpponent": "Belgium"
    },
    {
        "Surname": "Peters",
        "FullName": "Martin Peters",
        "Position": "MF",
        "Caps": "67",
        "Goals": "20",
        "DebutYear": "1966",
        "DebutOpponent": "Yugoslavia"
    },
    {
        "Surname": "Richards",
        "FullName": "Charlie Richards",
        "Position": "FW",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1898",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Morton",
        "FullName": "Jackie Morton",
        "Position": "FW",
        "Caps": "1",
        "Goals": "1",
        "DebutYear": "1937",
        "DebutOpponent": "Czechoslovakia"
    },
    {
        "Surname": "Buckley",
        "FullName": "Frank Buckley",
        "Position": "HB",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1914",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Wright",
        "FullName": "Richard Wright",
        "Position": "GK",
        "Caps": "2",
        "Goals": "0",
        "DebutYear": "2000",
        "DebutOpponent": "Malta"
    },
    {
        "Surname": "Veitch",
        "FullName": "John Veitch",
        "Position": "FW",
        "Caps": "1",
        "Goals": "3",
        "DebutYear": "1894",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Milner",
        "FullName": "James Milner",
        "Position": "MF / DF",
        "Caps": "61",
        "Goals": "1",
        "DebutYear": "2009",
        "DebutOpponent": "Netherlands"
    },
    {
        "Surname": "Bowyer",
        "FullName": "Lee Bowyer",
        "Position": "MF",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "2002",
        "DebutOpponent": "Portugal"
    },
    {
        "Surname": "Dimmock",
        "FullName": "Jimmy Dimmock",
        "Position": "FW",
        "Caps": "3",
        "Goals": "0",
        "DebutYear": "1921",
        "DebutOpponent": "Scotland"
    },
    {
        "Surname": "Shepherd",
        "FullName": "Albert Shepherd",
        "Position": "FW",
        "Caps": "2",
        "Goals": "2",
        "DebutYear": "1906",
        "DebutOpponent": "Scotland"
    },
    {
        "Surname": "Taylor",
        "FullName": "Tommy Taylor",
        "Position": "FW",
        "Caps": "19",
        "Goals": "16",
        "DebutYear": "1953",
        "DebutOpponent": "Argentina"
    },
    {
        "Surname": "Kane",
        "FullName": "Harry Kane",
        "Position": "FW",
        "Caps": "82",
        "Goals": "55",
        "DebutYear": "2015",
        "DebutOpponent": "Lithuania"
    },
    {
        "Surname": "Bradshaw",
        "FullName": "Frank Bradshaw",
        "Position": "FW",
        "Caps": "1",
        "Goals": "3",
        "DebutYear": "1908",
        "DebutOpponent": "Austria"
    },
    {
        "Surname": "Shorey",
        "FullName": "Nicky Shorey",
        "Position": "DF",
        "Caps": "2",
        "Goals": "0",
        "DebutYear": "2007",
        "DebutOpponent": "Brazil"
    },
    {
        "Surname": "Chedgzoy",
        "FullName": "Sam Chedgzoy",
        "Position": "FW",
        "Caps": "8",
        "Goals": "0",
        "DebutYear": "1920",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Maddison",
        "FullName": "James Maddison",
        "Position": "MF",
        "Caps": "2",
        "Goals": "0",
        "DebutYear": "2019",
        "DebutOpponent": "Montenegro"
    },
    {
        "Surname": "Fox",
        "FullName": "Freddie Fox",
        "Position": "GK",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1925",
        "DebutOpponent": "France"
    },
    {
        "Surname": "Harford",
        "FullName": "Mick Harford",
        "Position": "FW",
        "Caps": "2",
        "Goals": "0",
        "DebutYear": "1988",
        "DebutOpponent": "Israel"
    },
    {
        "Surname": "Welbeck",
        "FullName": "Danny Welbeck",
        "Position": "FW",
        "Caps": "42",
        "Goals": "16",
        "DebutYear": "2011",
        "DebutOpponent": "Ghana"
    },
    {
        "Surname": "Jones",
        "FullName": "Herbert Jones",
        "Position": "FB",
        "Caps": "6",
        "Goals": "0",
        "DebutYear": "1927",
        "DebutOpponent": "Scotland"
    },
    {
        "Surname": "Wadsworth",
        "FullName": "Sam Wadsworth",
        "Position": "FB",
        "Caps": "9",
        "Goals": "0",
        "DebutYear": "1922",
        "DebutOpponent": "Scotland"
    },
    {
        "Surname": "Robson",
        "FullName": "Bryan Robson",
        "Position": "MF",
        "Caps": "90",
        "Goals": "26",
        "DebutYear": "1980",
        "DebutOpponent": "Republic of Ireland"
    },
    {
        "Surname": "Chippendale",
        "FullName": "Harry Chippendale",
        "Position": "FW",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1894",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Tresadern",
        "FullName": "Jack Tresadern",
        "Position": "HB",
        "Caps": "2",
        "Goals": "0",
        "DebutYear": "1923",
        "DebutOpponent": "Scotland"
    },
    {
        "Surname": "Hill",
        "FullName": "Ricky Hill",
        "Position": "MF",
        "Caps": "3",
        "Goals": "0",
        "DebutYear": "1982",
        "DebutOpponent": "Denmark"
    },
    {
        "Surname": "Dobson",
        "FullName": "Martin Dobson",
        "Position": "MF",
        "Caps": "5",
        "Goals": "0",
        "DebutYear": "1974",
        "DebutOpponent": "Portugal"
    },
    {
        "Surname": "Cherry",
        "FullName": "Trevor Cherry",
        "Position": "DF / MF",
        "Caps": "27",
        "Goals": "0",
        "DebutYear": "1976",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Sinton",
        "FullName": "Andy Sinton",
        "Position": "MF / DF",
        "Caps": "12",
        "Goals": "0",
        "DebutYear": "1991",
        "DebutOpponent": "Poland"
    },
    {
        "Surname": "Makepeace",
        "FullName": "Harry Makepeace",
        "Position": "HB",
        "Caps": "4",
        "Goals": "0",
        "DebutYear": "1906",
        "DebutOpponent": "Scotland"
    },
    {
        "Surname": "Ince",
        "FullName": "Paul Ince",
        "Position": "MF",
        "Caps": "53",
        "Goals": "2",
        "DebutYear": "1992",
        "DebutOpponent": "Spain"
    },
    {
        "Surname": "Bambridge",
        "FullName": "Charles Bambridge",
        "Position": "FW / HB",
        "Caps": "18",
        "Goals": "11",
        "DebutYear": "1879",
        "DebutOpponent": "Scotland"
    },
    {
        "Surname": "Waterfield",
        "FullName": "George Waterfield",
        "Position": "FB",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1927",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Adams",
        "FullName": "Tony Adams",
        "Position": "DF",
        "Caps": "66",
        "Goals": "5",
        "DebutYear": "1987",
        "DebutOpponent": "Spain"
    },
    {
        "Surname": "Rodriguez",
        "FullName": "Jay Rodriguez",
        "Position": "FW",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "2013",
        "DebutOpponent": "Chile"
    },
    {
        "Surname": "Rostron",
        "FullName": "Thurston Rostron",
        "Position": "FW",
        "Caps": "2",
        "Goals": "0",
        "DebutYear": "1881",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Richards",
        "FullName": "John Richards",
        "Position": "FW",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1973",
        "DebutOpponent": "Northern Ireland"
    },
    {
        "Surname": "Topham",
        "FullName": "Arthur Topham",
        "Position": "HB",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1894",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Zamora",
        "FullName": "Bobby Zamora",
        "Position": "FW",
        "Caps": "2",
        "Goals": "0",
        "DebutYear": "2010",
        "DebutOpponent": "Hungary"
    },
    {
        "Surname": "Corbett",
        "FullName": "Rex Corbett",
        "Position": "FW",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1903",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Smalley",
        "FullName": "Tom Smalley",
        "Position": "HB",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1936",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Morten",
        "FullName": "Alexander Morten",
        "Position": "GK",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1873",
        "DebutOpponent": "Scotland"
    },
    {
        "Surname": "Daft",
        "FullName": "Harry Daft",
        "Position": "FW",
        "Caps": "5",
        "Goals": "3",
        "DebutYear": "1889",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Hudson",
        "FullName": "Alan Hudson",
        "Position": "MF",
        "Caps": "2",
        "Goals": "0",
        "DebutYear": "1975",
        "DebutOpponent": "West Germany"
    },
    {
        "Surname": "Bache",
        "FullName": "Joe Bache",
        "Position": "FW",
        "Caps": "7",
        "Goals": "4",
        "DebutYear": "1903",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Gosnell",
        "FullName": "Albert Gosnell",
        "Position": "FW",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1906",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Townrow",
        "FullName": "John Townrow",
        "Position": "HB",
        "Caps": "2",
        "Goals": "0",
        "DebutYear": "1925",
        "DebutOpponent": "Scotland"
    },
    {
        "Surname": "Rose",
        "FullName": "Danny Rose",
        "Position": "DF",
        "Caps": "29",
        "Goals": "0",
        "DebutYear": "2016",
        "DebutOpponent": "Germany"
    },
    {
        "Surname": "Hunt",
        "FullName": "Stephen Hunt",
        "Position": "MF",
        "Caps": "2",
        "Goals": "0",
        "DebutYear": "1984",
        "DebutOpponent": "Scotland"
    },
    {
        "Surname": "Charnley",
        "FullName": "Ray Charnley",
        "Position": "FW",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1962",
        "DebutOpponent": "France"
    },
    {
        "Surname": "Martyn",
        "FullName": "Nigel Martyn",
        "Position": "GK",
        "Caps": "23",
        "Goals": "0",
        "DebutYear": "1992",
        "DebutOpponent": "CIS"
    },
    {
        "Surname": "Woodhall",
        "FullName": "George Woodhall",
        "Position": "FW",
        "Caps": "2",
        "Goals": "1",
        "DebutYear": "1888",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Gardner",
        "FullName": "Anthony Gardner",
        "Position": "DF",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "2004",
        "DebutOpponent": "Sweden"
    },
    {
        "Surname": "Woodgate",
        "FullName": "Jonathan Woodgate",
        "Position": "DF",
        "Caps": "8",
        "Goals": "0",
        "DebutYear": "1999",
        "DebutOpponent": "Bulgaria"
    },
    {
        "Surname": "Hart",
        "FullName": "Ernie Hart",
        "Position": "HB",
        "Caps": "8",
        "Goals": "0",
        "DebutYear": "1928",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Olney",
        "FullName": "Ben Olney",
        "Position": "GK",
        "Caps": "2",
        "Goals": "0",
        "DebutYear": "1928",
        "DebutOpponent": "France"
    },
    {
        "Surname": "Deane",
        "FullName": "Brian Deane",
        "Position": "FW",
        "Caps": "3",
        "Goals": "0",
        "DebutYear": "1991",
        "DebutOpponent": "New Zealand"
    },
    {
        "Surname": "Rix",
        "FullName": "Graham Rix",
        "Position": "MF",
        "Caps": "17",
        "Goals": "0",
        "DebutYear": "1980",
        "DebutOpponent": "Norway"
    },
    {
        "Surname": "Froggatt",
        "FullName": "Redfern Froggatt",
        "Position": "FW",
        "Caps": "4",
        "Goals": "2",
        "DebutYear": "1952",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Murphy",
        "FullName": "Danny Murphy",
        "Position": "MF",
        "Caps": "9",
        "Goals": "1",
        "DebutYear": "2001",
        "DebutOpponent": "Sweden"
    },
    {
        "Surname": "Gibbs",
        "FullName": "Kieran Gibbs",
        "Position": "DF",
        "Caps": "10",
        "Goals": "0",
        "DebutYear": "2010",
        "DebutOpponent": "Hungary"
    },
    {
        "Surname": "Crabtree",
        "FullName": "Jimmy Crabtree",
        "Position": "HB�/ FB",
        "Caps": "14",
        "Goals": "0",
        "DebutYear": "1894",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Forster",
        "FullName": "Fraser Forster",
        "Position": "GK",
        "Caps": "6",
        "Goals": "0",
        "DebutYear": "2013",
        "DebutOpponent": "Chile"
    },
    {
        "Surname": "King",
        "FullName": "Ledley King",
        "Position": "DF",
        "Caps": "21",
        "Goals": "2",
        "DebutYear": "2002",
        "DebutOpponent": "Italy"
    },
    {
        "Surname": "Whitworth",
        "FullName": "Steve Whitworth",
        "Position": "DF",
        "Caps": "7",
        "Goals": "0",
        "DebutYear": "1975",
        "DebutOpponent": "West Germany"
    },
    {
        "Surname": "Woods",
        "FullName": "Chris Woods",
        "Position": "GK",
        "Caps": "43",
        "Goals": "0",
        "DebutYear": "1985",
        "DebutOpponent": "United States"
    },
    {
        "Surname": "Rice",
        "FullName": "Declan Rice",
        "Position": "MF",
        "Caps": "41",
        "Goals": "3",
        "DebutYear": "2019",
        "DebutOpponent": "Czech Republic"
    },
    {
        "Surname": "Dawson",
        "FullName": "Michael Dawson",
        "Position": "DF",
        "Caps": "4",
        "Goals": "0",
        "DebutYear": "2010",
        "DebutOpponent": "Hungary"
    },
    {
        "Surname": "Seaman",
        "FullName": "David Seaman",
        "Position": "GK",
        "Caps": "75",
        "Goals": "0",
        "DebutYear": "1988",
        "DebutOpponent": "Saudi Arabia"
    },
    {
        "Surname": "Pennington",
        "FullName": "Jesse Pennington",
        "Position": "FB",
        "Caps": "25",
        "Goals": "0",
        "DebutYear": "1907",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Hirst",
        "FullName": "David Hirst",
        "Position": "FW",
        "Caps": "3",
        "Goals": "1",
        "DebutYear": "1991",
        "DebutOpponent": "Australia"
    },
    {
        "Surname": "Mason",
        "FullName": "Charlie Mason",
        "Position": "FB",
        "Caps": "3",
        "Goals": "0",
        "DebutYear": "1887",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Pym",
        "FullName": "Dick Pym",
        "Position": "GK",
        "Caps": "3",
        "Goals": "0",
        "DebutYear": "1925",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Keen",
        "FullName": "Eric Keen",
        "Position": "HB",
        "Caps": "4",
        "Goals": "0",
        "DebutYear": "1932",
        "DebutOpponent": "Austria"
    },
    {
        "Surname": "Hart",
        "FullName": "Joe Hart",
        "Position": "GK",
        "Caps": "75",
        "Goals": "0",
        "DebutYear": "2008",
        "DebutOpponent": "Trinidad and Tobago"
    },
    {
        "Surname": "McCarthy",
        "FullName": "Alex McCarthy",
        "Position": "GK",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "2018",
        "DebutOpponent": "United States"
    },
    {
        "Surname": "Foster",
        "FullName": "Tip Foster",
        "Position": "FW",
        "Caps": "5",
        "Goals": "3",
        "DebutYear": "1900",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Sadler",
        "FullName": "David Sadler",
        "Position": "MF",
        "Caps": "4",
        "Goals": "0",
        "DebutYear": "1967",
        "DebutOpponent": "Northern Ireland"
    },
    {
        "Surname": "Merrick",
        "FullName": "Gil Merrick",
        "Position": "GK",
        "Caps": "23",
        "Goals": "0",
        "DebutYear": "1951",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Grealish",
        "FullName": "Jack Grealish",
        "Position": "FW",
        "Caps": "31",
        "Goals": "2",
        "DebutYear": "2020",
        "DebutOpponent": "Denmark"
    },
    {
        "Surname": "Bridges",
        "FullName": "Barry Bridges",
        "Position": "FW",
        "Caps": "4",
        "Goals": "1",
        "DebutYear": "1965",
        "DebutOpponent": "Scotland"
    },
    {
        "Surname": "Knowles",
        "FullName": "Cyril Knowles",
        "Position": "DF",
        "Caps": "4",
        "Goals": "0",
        "DebutYear": "1967",
        "DebutOpponent": "Soviet Union"
    },
    {
        "Surname": "Finney",
        "FullName": "Tom Finney",
        "Position": "FW",
        "Caps": "76",
        "Goals": "30",
        "DebutYear": "1946",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Thompson",
        "FullName": "Alan Thompson",
        "Position": "MF",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "2004",
        "DebutOpponent": "Sweden"
    },
    {
        "Surname": "Rawlings",
        "FullName": "Bill Rawlings",
        "Position": "FW",
        "Caps": "2",
        "Goals": "0",
        "DebutYear": "1922",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Mort",
        "FullName": "Thomas Mort",
        "Position": "FB",
        "Caps": "3",
        "Goals": "0",
        "DebutYear": "1924",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Macdonald",
        "FullName": "Malcolm Macdonald",
        "Position": "FW",
        "Caps": "14",
        "Goals": "6",
        "DebutYear": "1972",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Stevens",
        "FullName": "Gary Stevens",
        "Position": "DF",
        "Caps": "46",
        "Goals": "0",
        "DebutYear": "1985",
        "DebutOpponent": "Italy"
    },
    {
        "Surname": "Baynham",
        "FullName": "Ron Baynham",
        "Position": "GK",
        "Caps": "3",
        "Goals": "0",
        "DebutYear": "1955",
        "DebutOpponent": "Denmark"
    },
    {
        "Surname": "Terry",
        "FullName": "John Terry",
        "Position": "DF",
        "Caps": "78",
        "Goals": "6",
        "DebutYear": "2003",
        "DebutOpponent": "Serbia and Montenegro"
    },
    {
        "Surname": "Mosscrop",
        "FullName": "Eddie Mosscrop",
        "Position": "FW",
        "Caps": "2",
        "Goals": "0",
        "DebutYear": "1914",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Brown",
        "FullName": "Jimmy Brown",
        "Position": "FW",
        "Caps": "5",
        "Goals": "3",
        "DebutYear": "1881",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Cunliffe",
        "FullName": "James Cunliffe",
        "Position": "FW",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1936",
        "DebutOpponent": "Belgium"
    },
    {
        "Surname": "McNeil",
        "FullName": "Mick McNeil",
        "Position": "FB",
        "Caps": "9",
        "Goals": "0",
        "DebutYear": "1960",
        "DebutOpponent": "Northern Ireland"
    },
    {
        "Surname": "Wedlock",
        "FullName": "Billy Wedlock",
        "Position": "HB",
        "Caps": "26",
        "Goals": "2",
        "DebutYear": "1907",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Baker",
        "FullName": "Joe Baker",
        "Position": "FW",
        "Caps": "8",
        "Goals": "3",
        "DebutYear": "1959",
        "DebutOpponent": "Northern Ireland"
    },
    {
        "Surname": "Parry",
        "FullName": "Edward Parry",
        "Position": "FW",
        "Caps": "3",
        "Goals": "1",
        "DebutYear": "1879",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "George",
        "FullName": "Charlie George",
        "Position": "FW",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1976",
        "DebutOpponent": "Republic of Ireland"
    },
    {
        "Surname": "Ruddock",
        "FullName": "Neil Ruddock",
        "Position": "DF",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1994",
        "DebutOpponent": "Nigeria"
    },
    {
        "Surname": "Beardsley",
        "FullName": "Peter Beardsley",
        "Position": "FW",
        "Caps": "59",
        "Goals": "9",
        "DebutYear": "1986",
        "DebutOpponent": "Egypt"
    },
    {
        "Surname": "Smith",
        "FullName": "Alan Smith",
        "Position": "FW",
        "Caps": "13",
        "Goals": "2",
        "DebutYear": "1988",
        "DebutOpponent": "Saudi Arabia"
    },
    {
        "Surname": "Little",
        "FullName": "Brian Little",
        "Position": "FW",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1975",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Marsden",
        "FullName": "William Marsden",
        "Position": "HB",
        "Caps": "3",
        "Goals": "0",
        "DebutYear": "1929",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Pentland",
        "FullName": "Fred Pentland",
        "Position": "FW",
        "Caps": "5",
        "Goals": "0",
        "DebutYear": "1909",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Morice",
        "FullName": "Charles Morice",
        "Position": "FW",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1872",
        "DebutOpponent": "Scotland"
    },
    {
        "Surname": "Chadwick",
        "FullName": "Arthur Chadwick",
        "Position": "HB",
        "Caps": "2",
        "Goals": "0",
        "DebutYear": "1900",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Holt",
        "FullName": "Johnny Holt",
        "Position": "HB",
        "Caps": "10",
        "Goals": "0",
        "DebutYear": "1890",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Ashton",
        "FullName": "Dean Ashton",
        "Position": "FW",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "2008",
        "DebutOpponent": "Trinidad and Tobago"
    },
    {
        "Surname": "Hitchens",
        "FullName": "Gerry Hitchens",
        "Position": "FW",
        "Caps": "7",
        "Goals": "5",
        "DebutYear": "1961",
        "DebutOpponent": "Mexico"
    },
    {
        "Surname": "Turner",
        "FullName": "Hugh Turner",
        "Position": "GK",
        "Caps": "2",
        "Goals": "0",
        "DebutYear": "1931",
        "DebutOpponent": "France"
    },
    {
        "Surname": "Fashanu",
        "FullName": "John Fashanu",
        "Position": "FW",
        "Caps": "2",
        "Goals": "0",
        "DebutYear": "1989",
        "DebutOpponent": "Chile"
    },
    {
        "Surname": "Dixon",
        "FullName": "John Dixon",
        "Position": "FW",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1885",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Walker",
        "FullName": "Kyle Walker",
        "Position": "DF",
        "Caps": "75",
        "Goals": "0",
        "DebutYear": "2011",
        "DebutOpponent": "Spain"
    },
    {
        "Surname": "Weaver",
        "FullName": "Sam Weaver",
        "Position": "HB",
        "Caps": "3",
        "Goals": "0",
        "DebutYear": "1932",
        "DebutOpponent": "Scotland"
    },
    {
        "Surname": "Swepstone",
        "FullName": "Albemarle Swepstone",
        "Position": "GK",
        "Caps": "6",
        "Goals": "0",
        "DebutYear": "1880",
        "DebutOpponent": "Scotland"
    },
    {
        "Surname": "Watson",
        "FullName": "David Watson",
        "Position": "DF",
        "Caps": "65",
        "Goals": "4",
        "DebutYear": "1974",
        "DebutOpponent": "Portugal"
    },
    {
        "Surname": "Cook",
        "FullName": "Tommy Cook",
        "Position": "FW",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1925",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Tate",
        "FullName": "Joe Tate",
        "Position": "HB",
        "Caps": "3",
        "Goals": "0",
        "DebutYear": "1931",
        "DebutOpponent": "France"
    },
    {
        "Surname": "Bothroyd",
        "FullName": "Jay Bothroyd",
        "Position": "FW",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "2010",
        "DebutOpponent": "France"
    },
    {
        "Surname": "Rashford",
        "FullName": "Marcus Rashford",
        "Position": "FW",
        "Caps": "51",
        "Goals": "15",
        "DebutYear": "2016",
        "DebutOpponent": "Australia"
    },
    {
        "Surname": "Stein",
        "FullName": "Brian Stein",
        "Position": "MF",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1984",
        "DebutOpponent": "France"
    },
    {
        "Surname": "Smalling",
        "FullName": "Chris Smalling",
        "Position": "DF",
        "Caps": "31",
        "Goals": "1",
        "DebutYear": "2011",
        "DebutOpponent": "Bulgaria"
    },
    {
        "Surname": "Owen",
        "FullName": "Syd Owen",
        "Position": "HB",
        "Caps": "3",
        "Goals": "0",
        "DebutYear": "1954",
        "DebutOpponent": "Yugoslavia"
    },
    {
        "Surname": "Hacking",
        "FullName": "Jack Hacking",
        "Position": "GK",
        "Caps": "3",
        "Goals": "0",
        "DebutYear": "1928",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Wace",
        "FullName": "Henry Wace",
        "Position": "FW",
        "Caps": "3",
        "Goals": "0",
        "DebutYear": "1878",
        "DebutOpponent": "Scotland"
    },
    {
        "Surname": "Baines",
        "FullName": "Leighton Baines",
        "Position": "DF",
        "Caps": "30",
        "Goals": "1",
        "DebutYear": "2010",
        "DebutOpponent": "Egypt"
    },
    {
        "Surname": "Foulkes",
        "FullName": "Bill Foulkes",
        "Position": "FB",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1954",
        "DebutOpponent": "Northern Ireland"
    },
    {
        "Surname": "Morris",
        "FullName": "Fred Morris",
        "Position": "FW",
        "Caps": "2",
        "Goals": "1",
        "DebutYear": "1920",
        "DebutOpponent": "Scotland"
    },
    {
        "Surname": "Niles",
        "FullName": "Ainsley Maitland-Niles",
        "Position": "DF",
        "Caps": "5",
        "Goals": "0",
        "DebutYear": "2020",
        "DebutOpponent": "Denmark"
    },
    {
        "Surname": "Gillard",
        "FullName": "Ian Gillard",
        "Position": "DF",
        "Caps": "3",
        "Goals": "0",
        "DebutYear": "1975",
        "DebutOpponent": "West Germany"
    },
    {
        "Surname": "Athersmith",
        "FullName": "Charlie Athersmith",
        "Position": "FW",
        "Caps": "12",
        "Goals": "3",
        "DebutYear": "1892",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Mitchell",
        "FullName": "Clement Mitchell",
        "Position": "FW",
        "Caps": "5",
        "Goals": "5",
        "DebutYear": "1880",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Cleverley",
        "FullName": "Tom Cleverley",
        "Position": "MF",
        "Caps": "13",
        "Goals": "0",
        "DebutYear": "2012",
        "DebutOpponent": "Italy"
    },
    {
        "Surname": "Arnold",
        "FullName": "Johnny Arnold",
        "Position": "FW",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1933",
        "DebutOpponent": "Scotland"
    },
    {
        "Surname": "Greaves",
        "FullName": "Jimmy Greaves",
        "Position": "FW",
        "Caps": "57",
        "Goals": "44",
        "DebutYear": "1959",
        "DebutOpponent": "Peru"
    },
    {
        "Surname": "Pye",
        "FullName": "Jesse Pye",
        "Position": "FW",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1949",
        "DebutOpponent": "Republic of Ireland"
    },
    {
        "Surname": "Pike",
        "FullName": "Thelwell Pike",
        "Position": "FW",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1886",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Barton",
        "FullName": "Percy Barton",
        "Position": "HB",
        "Caps": "7",
        "Goals": "0",
        "DebutYear": "1921",
        "DebutOpponent": "Belgium"
    },
    {
        "Surname": "Banks",
        "FullName": "Gordon Banks",
        "Position": "GK",
        "Caps": "73",
        "Goals": "0",
        "DebutYear": "1963",
        "DebutOpponent": "Scotland"
    },
    {
        "Surname": "Sproston",
        "FullName": "Bert Sproston",
        "Position": "FB",
        "Caps": "11",
        "Goals": "0",
        "DebutYear": "1936",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Tomori",
        "FullName": "Fikayo Tomori",
        "Position": "DF",
        "Caps": "3",
        "Goals": "0",
        "DebutYear": "2019",
        "DebutOpponent": "Kosovo"
    },
    {
        "Surname": "Sillett",
        "FullName": "Peter Sillett",
        "Position": "FB",
        "Caps": "3",
        "Goals": "0",
        "DebutYear": "1955",
        "DebutOpponent": "France"
    },
    {
        "Surname": "Statham",
        "FullName": "Derek Statham",
        "Position": "DF",
        "Caps": "3",
        "Goals": "0",
        "DebutYear": "1983",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "White",
        "FullName": "David White",
        "Position": "MF",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1992",
        "DebutOpponent": "Spain"
    },
    {
        "Surname": "Walker",
        "FullName": "Billy Walker",
        "Position": "FW",
        "Caps": "18",
        "Goals": "9",
        "DebutYear": "1920",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Forrest",
        "FullName": "Jimmy Forrest",
        "Position": "HB",
        "Caps": "11",
        "Goals": "0",
        "DebutYear": "1884",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Greenhalgh",
        "FullName": "Harwood Greenhalgh",
        "Position": "FB",
        "Caps": "2",
        "Goals": "0",
        "DebutYear": "1872",
        "DebutOpponent": "Scotland"
    },
    {
        "Surname": "Tait",
        "FullName": "George Tait",
        "Position": "FW",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1881",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Welch",
        "FullName": "Reginald Courtenay Welch",
        "Position": "GK",
        "Caps": "2",
        "Goals": "0",
        "DebutYear": "1872",
        "DebutOpponent": "Scotland"
    },
    {
        "Surname": "Bassett",
        "FullName": "Billy Bassett",
        "Position": "FW",
        "Caps": "16",
        "Goals": "8",
        "DebutYear": "1888",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Richardson",
        "FullName": "Kieran Richardson",
        "Position": "MF",
        "Caps": "8",
        "Goals": "2",
        "DebutYear": "2005",
        "DebutOpponent": "United States"
    },
    {
        "Surname": "Slaney",
        "FullName": "William Kenyon-Slaney",
        "Position": "FW",
        "Caps": "1",
        "Goals": "2",
        "DebutYear": "1873",
        "DebutOpponent": "Scotland"
    },
    {
        "Surname": "Shelvey",
        "FullName": "Jonjo Shelvey",
        "Position": "MF",
        "Caps": "6",
        "Goals": "0",
        "DebutYear": "2012",
        "DebutOpponent": "San Marino"
    },
    {
        "Surname": "Fairclough",
        "FullName": "Percy Fairclough",
        "Position": "FW",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1878",
        "DebutOpponent": "Scotland"
    },
    {
        "Surname": "Beattie",
        "FullName": "Kevin Beattie",
        "Position": "DF",
        "Caps": "9",
        "Goals": "1",
        "DebutYear": "1975",
        "DebutOpponent": "Cyprus"
    },
    {
        "Surname": "Veitch",
        "FullName": "Colin Veitch",
        "Position": "HB",
        "Caps": "6",
        "Goals": "0",
        "DebutYear": "1906",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Cotterill",
        "FullName": "George Cotterill",
        "Position": "FW",
        "Caps": "4",
        "Goals": "2",
        "DebutYear": "1891",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Spence",
        "FullName": "Joe Spence",
        "Position": "FW",
        "Caps": "2",
        "Goals": "1",
        "DebutYear": "1926",
        "DebutOpponent": "Belgium"
    },
    {
        "Surname": "Adcock",
        "FullName": "Hugh Adcock",
        "Position": "FW",
        "Caps": "5",
        "Goals": "1",
        "DebutYear": "1929",
        "DebutOpponent": "France"
    },
    {
        "Surname": "Brown",
        "FullName": "Tony Brown",
        "Position": "FW",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1971",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Roberts",
        "FullName": "Frank Roberts",
        "Position": "FW",
        "Caps": "4",
        "Goals": "2",
        "DebutYear": "1924",
        "DebutOpponent": "Belgium"
    },
    {
        "Surname": "Foster",
        "FullName": "Ben Foster",
        "Position": "GK",
        "Caps": "8",
        "Goals": "0",
        "DebutYear": "2007",
        "DebutOpponent": "Spain"
    },
    {
        "Surname": "Eastham",
        "FullName": "George Eastham",
        "Position": "FW",
        "Caps": "19",
        "Goals": "2",
        "DebutYear": "1963",
        "DebutOpponent": "Brazil"
    },
    {
        "Surname": "Woodward",
        "FullName": "Vivian Woodward",
        "Position": "FW",
        "Caps": "23",
        "Goals": "29",
        "DebutYear": "1903",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Lescott",
        "FullName": "Joleon Lescott",
        "Position": "DF",
        "Caps": "26",
        "Goals": "1",
        "DebutYear": "2007",
        "DebutOpponent": "Estonia"
    },
    {
        "Surname": "Bagshaw",
        "FullName": "Jimmy Bagshaw",
        "Position": "HB",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1919",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Betmead",
        "FullName": "Harry Betmead",
        "Position": "HB",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1937",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Birkett",
        "FullName": "Reg Birkett",
        "Position": "GK",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1879",
        "DebutOpponent": "Scotland"
    },
    {
        "Surname": "Rawson",
        "FullName": "Herbert Rawson",
        "Position": "FW",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1875",
        "DebutOpponent": "Scotland"
    },
    {
        "Surname": "Bull",
        "FullName": "Steve Bull",
        "Position": "FW",
        "Caps": "13",
        "Goals": "4",
        "DebutYear": "1989",
        "DebutOpponent": "Scotland"
    },
    {
        "Surname": "Nicholls",
        "FullName": "Johnny Nicholls",
        "Position": "FW",
        "Caps": "2",
        "Goals": "1",
        "DebutYear": "1954",
        "DebutOpponent": "Scotland"
    },
    {
        "Surname": "Grainger",
        "FullName": "Colin Grainger",
        "Position": "FW",
        "Caps": "7",
        "Goals": "3",
        "DebutYear": "1956",
        "DebutOpponent": "Brazil"
    },
    {
        "Surname": "Cottee",
        "FullName": "Tony Cottee",
        "Position": "FW",
        "Caps": "7",
        "Goals": "0",
        "DebutYear": "1986",
        "DebutOpponent": "Sweden"
    },
    {
        "Surname": "Latheron",
        "FullName": "Eddie Latheron",
        "Position": "FW",
        "Caps": "2",
        "Goals": "1",
        "DebutYear": "1913",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Owen",
        "FullName": "John Owen",
        "Position": "FW",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1874",
        "DebutOpponent": "Scotland"
    },
    {
        "Surname": "Clarke",
        "FullName": "Harry Clarke",
        "Position": "HB",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1954",
        "DebutOpponent": "Scotland"
    },
    {
        "Surname": "King",
        "FullName": "Robert King",
        "Position": "HB",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1882",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Hollins",
        "FullName": "John Hollins",
        "Position": "FW",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1967",
        "DebutOpponent": "Spain"
    },
    {
        "Surname": "Burton",
        "FullName": "Frank Burton",
        "Position": "FW",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1889",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Allen",
        "FullName": "Ronnie Allen",
        "Position": "FW",
        "Caps": "5",
        "Goals": "2",
        "DebutYear": "1952",
        "DebutOpponent": "Switzerland"
    },
    {
        "Surname": "Henderson",
        "FullName": "Dean Henderson",
        "Position": "GK",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "2020",
        "DebutOpponent": "Republic of Ireland"
    },
    {
        "Surname": "Cresswell",
        "FullName": "Aaron Cresswell",
        "Position": "DF",
        "Caps": "3",
        "Goals": "0",
        "DebutYear": "2016",
        "DebutOpponent": "Spain"
    },
    {
        "Surname": "Smith",
        "FullName": "Herbert Smith",
        "Position": "FB",
        "Caps": "4",
        "Goals": "0",
        "DebutYear": "1905",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Patchitt",
        "FullName": "Basil Patchitt",
        "Position": "HB",
        "Caps": "2",
        "Goals": "0",
        "DebutYear": "1923",
        "DebutOpponent": "Sweden"
    },
    {
        "Surname": "Coleman",
        "FullName": "Tim Coleman",
        "Position": "FW",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1907",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Pickford",
        "FullName": "Jordan Pickford",
        "Position": "GK",
        "Caps": "52",
        "Goals": "0",
        "DebutYear": "2017",
        "DebutOpponent": "Germany"
    },
    {
        "Surname": "Kingsley",
        "FullName": "Matt Kingsley",
        "Position": "GK",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1901",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Brown",
        "FullName": "Arthur Brown",
        "Position": "FW",
        "Caps": "2",
        "Goals": "1",
        "DebutYear": "1904",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Hodgson",
        "FullName": "Gordon Hodgson",
        "Position": "FW",
        "Caps": "3",
        "Goals": "1",
        "DebutYear": "1930",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Dewhurst",
        "FullName": "Gerard Dewhurst",
        "Position": "FW",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1895",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Goddard",
        "FullName": "Paul Goddard",
        "Position": "FW",
        "Caps": "1",
        "Goals": "1",
        "DebutYear": "1982",
        "DebutOpponent": "Iceland"
    },
    {
        "Surname": "Bennett",
        "FullName": "Walter Bennett",
        "Position": "FW",
        "Caps": "2",
        "Goals": "0",
        "DebutYear": "1901",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Curle",
        "FullName": "Keith Curle",
        "Position": "DF",
        "Caps": "3",
        "Goals": "0",
        "DebutYear": "1992",
        "DebutOpponent": "CIS"
    },
    {
        "Surname": "Knight",
        "FullName": "Zat Knight",
        "Position": "DF",
        "Caps": "2",
        "Goals": "0",
        "DebutYear": "2005",
        "DebutOpponent": "United States"
    },
    {
        "Surname": "Furness",
        "FullName": "Billy Furness",
        "Position": "FW",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1933",
        "DebutOpponent": "Italy"
    },
    {
        "Surname": "Flowers",
        "FullName": "Ron Flowers",
        "Position": "HB",
        "Caps": "49",
        "Goals": "10",
        "DebutYear": "1955",
        "DebutOpponent": "France"
    },
    {
        "Surname": "Sagar",
        "FullName": "Charlie Sagar",
        "Position": "FW",
        "Caps": "2",
        "Goals": "1",
        "DebutYear": "1900",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Bridgett",
        "FullName": "Arthur Bridgett",
        "Position": "FW",
        "Caps": "11",
        "Goals": "3",
        "DebutYear": "1905",
        "DebutOpponent": "Scotland"
    },
    {
        "Surname": "Bradshaw",
        "FullName": "Billy Bradshaw",
        "Position": "HB",
        "Caps": "4",
        "Goals": "0",
        "DebutYear": "1910",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Storey",
        "FullName": "Peter Storey",
        "Position": "DF / MF",
        "Caps": "19",
        "Goals": "0",
        "DebutYear": "1971",
        "DebutOpponent": "Greece"
    },
    {
        "Surname": "Holley",
        "FullName": "George Holley",
        "Position": "FW",
        "Caps": "10",
        "Goals": "8",
        "DebutYear": "1909",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Drake",
        "FullName": "Ted Drake",
        "Position": "FW",
        "Caps": "5",
        "Goals": "6",
        "DebutYear": "1934",
        "DebutOpponent": "Italy"
    },
    {
        "Surname": "Cole",
        "FullName": "Andy Cole",
        "Position": "FW",
        "Caps": "15",
        "Goals": "1",
        "DebutYear": "1995",
        "DebutOpponent": "Uruguay"
    },
    {
        "Surname": "Gascoigne",
        "FullName": "Paul Gascoigne",
        "Position": "MF",
        "Caps": "57",
        "Goals": "10",
        "DebutYear": "1988",
        "DebutOpponent": "Denmark"
    },
    {
        "Surname": "Robinson",
        "FullName": "Jackie Robinson",
        "Position": "FW",
        "Caps": "4",
        "Goals": "3",
        "DebutYear": "1937",
        "DebutOpponent": "Finland"
    },
    {
        "Surname": "Walters",
        "FullName": "Arthur Walters",
        "Position": "FB",
        "Caps": "9",
        "Goals": "0",
        "DebutYear": "1885",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Bullock",
        "FullName": "Norman Bullock",
        "Position": "FW",
        "Caps": "3",
        "Goals": "2",
        "DebutYear": "1923",
        "DebutOpponent": "Belgium"
    },
    {
        "Surname": "Walcott",
        "FullName": "Theo Walcott",
        "Position": "FW / MF",
        "Caps": "47",
        "Goals": "8",
        "DebutYear": "2006",
        "DebutOpponent": "Hungary"
    },
    {
        "Surname": "Lee",
        "FullName": "Francis Lee",
        "Position": "FW",
        "Caps": "27",
        "Goals": "10",
        "DebutYear": "1968",
        "DebutOpponent": "Bulgaria"
    },
    {
        "Surname": "Betts",
        "FullName": "Billy Betts",
        "Position": "HB",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1889",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Charlton",
        "FullName": "Jack Charlton",
        "Position": "DF",
        "Caps": "35",
        "Goals": "6",
        "DebutYear": "1965",
        "DebutOpponent": "Scotland"
    },
    {
        "Surname": "Smith",
        "FullName": "Les Smith",
        "Position": "FW",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1939",
        "DebutOpponent": "Romania"
    },
    {
        "Surname": "Hector",
        "FullName": "Kevin Hector",
        "Position": "FW",
        "Caps": "2",
        "Goals": "0",
        "DebutYear": "1973",
        "DebutOpponent": "Poland"
    },
    {
        "Surname": "McCall",
        "FullName": "Joe McCall",
        "Position": "HB",
        "Caps": "5",
        "Goals": "1",
        "DebutYear": "1913",
        "DebutOpponent": "Scotland"
    },
    {
        "Surname": "Barker",
        "FullName": "Robert Barker",
        "Position": "GK",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1872",
        "DebutOpponent": "Scotland"
    },
    {
        "Surname": "Talbot",
        "FullName": "Brian Talbot",
        "Position": "MF",
        "Caps": "6",
        "Goals": "0",
        "DebutYear": "1977",
        "DebutOpponent": "Northern Ireland"
    },
    {
        "Surname": "Nicholson",
        "FullName": "Bill Nicholson",
        "Position": "HB",
        "Caps": "1",
        "Goals": "1",
        "DebutYear": "1951",
        "DebutOpponent": "Portugal"
    },
    {
        "Surname": "Rawlings",
        "FullName": "Archibald Rawlings",
        "Position": "FW",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1921",
        "DebutOpponent": "Belgium"
    },
    {
        "Surname": "Benson",
        "FullName": "Bob Benson",
        "Position": "FB",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1913",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Brindle",
        "FullName": "William Brindle",
        "Position": "FB",
        "Caps": "2",
        "Goals": "1",
        "DebutYear": "1880",
        "DebutOpponent": "Scotland"
    },
    {
        "Surname": "Lampard",
        "FullName": "Frank Lampard",
        "Position": "MF",
        "Caps": "106",
        "Goals": "29",
        "DebutYear": "1999",
        "DebutOpponent": "Belgium"
    },
    {
        "Surname": "Clarke",
        "FullName": "Allan Clarke",
        "Position": "FW",
        "Caps": "19",
        "Goals": "10",
        "DebutYear": "1970",
        "DebutOpponent": "Czechoslovakia"
    },
    {
        "Surname": "Shackleton",
        "FullName": "Len Shackleton",
        "Position": "FW",
        "Caps": "5",
        "Goals": "1",
        "DebutYear": "1948",
        "DebutOpponent": "Denmark"
    },
    {
        "Surname": "Kirton",
        "FullName": "Billy Kirton",
        "Position": "FW",
        "Caps": "1",
        "Goals": "1",
        "DebutYear": "1921",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Bryant",
        "FullName": "William Bryant",
        "Position": "HB",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1925",
        "DebutOpponent": "France"
    },
    {
        "Surname": "Hinchcliffe",
        "FullName": "Andy Hinchcliffe",
        "Position": "DF",
        "Caps": "7",
        "Goals": "0",
        "DebutYear": "1996",
        "DebutOpponent": "Moldova"
    },
    {
        "Surname": "Richardson",
        "FullName": "Kevin Richardson",
        "Position": "MF",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1994",
        "DebutOpponent": "Greece"
    },
    {
        "Surname": "Campbell",
        "FullName": "Sol Campbell",
        "Position": "DF",
        "Caps": "73",
        "Goals": "1",
        "DebutYear": "1996",
        "DebutOpponent": "Hungary"
    },
    {
        "Surname": "Moore",
        "FullName": "Bobby Moore",
        "Position": "DF",
        "Caps": "108",
        "Goals": "2",
        "DebutYear": "1962",
        "DebutOpponent": "Peru"
    },
    {
        "Surname": "Wilson",
        "FullName": "Callum Wilson",
        "Position": "FW",
        "Caps": "6",
        "Goals": "1",
        "DebutYear": "2018",
        "DebutOpponent": "United States"
    },
    {
        "Surname": "Gray",
        "FullName": "Andy Gray",
        "Position": "MF",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1991",
        "DebutOpponent": "Poland"
    },
    {
        "Surname": "Hancocks",
        "FullName": "Johnny Hancocks",
        "Position": "FW",
        "Caps": "3",
        "Goals": "2",
        "DebutYear": "1948",
        "DebutOpponent": "Switzerland"
    },
    {
        "Surname": "Spilsbury",
        "FullName": "Benjamin Spilsbury",
        "Position": "FW",
        "Caps": "3",
        "Goals": "5",
        "DebutYear": "1885",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Clayton",
        "FullName": "Ronnie Clayton",
        "Position": "HB",
        "Caps": "35",
        "Goals": "0",
        "DebutYear": "1955",
        "DebutOpponent": "Northern Ireland"
    },
    {
        "Surname": "Evans",
        "FullName": "Robert Evans",
        "Position": "FW",
        "Caps": "4",
        "Goals": "1",
        "DebutYear": "1911",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Hulme",
        "FullName": "Joe Hulme",
        "Position": "FW",
        "Caps": "9",
        "Goals": "4",
        "DebutYear": "1927",
        "DebutOpponent": "Scotland"
    },
    {
        "Surname": "Kidd",
        "FullName": "Brian Kidd",
        "Position": "FW",
        "Caps": "2",
        "Goals": "1",
        "DebutYear": "1970",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Warren",
        "FullName": "Ben Warren",
        "Position": "HB",
        "Caps": "22",
        "Goals": "2",
        "DebutYear": "1906",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Hill",
        "FullName": "Gordon Hill",
        "Position": "FW",
        "Caps": "6",
        "Goals": "0",
        "DebutYear": "1976",
        "DebutOpponent": "Italy"
    },
    {
        "Surname": "Cowell",
        "FullName": "Arthur Cowell",
        "Position": "FB",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1910",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Brockbank",
        "FullName": "John Brockbank",
        "Position": "FW",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1872",
        "DebutOpponent": "Scotland"
    },
    {
        "Surname": "Hellawell",
        "FullName": "Mike Hellawell",
        "Position": "FW",
        "Caps": "2",
        "Goals": "0",
        "DebutYear": "1962",
        "DebutOpponent": "France"
    },
    {
        "Surname": "Odoi",
        "FullName": "Callum Hudson-Odoi",
        "Position": "FW",
        "Caps": "3",
        "Goals": "0",
        "DebutYear": "2019",
        "DebutOpponent": "Czech Republic"
    },
    {
        "Surname": "Fantham",
        "FullName": "Johnny Fantham",
        "Position": "FW",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1961",
        "DebutOpponent": "Luxembourg"
    },
    {
        "Surname": "Roberts",
        "FullName": "Harry Roberts",
        "Position": "FW",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1931",
        "DebutOpponent": "Belgium"
    },
    {
        "Surname": "Humphreys",
        "FullName": "Percy Humphreys",
        "Position": "FW",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1903",
        "DebutOpponent": "Scotland"
    },
    {
        "Surname": "Summerbee",
        "FullName": "Mike Summerbee",
        "Position": "FW",
        "Caps": "8",
        "Goals": "1",
        "DebutYear": "1968",
        "DebutOpponent": "Scotland"
    },
    {
        "Surname": "Radford",
        "FullName": "John Radford",
        "Position": "MF",
        "Caps": "2",
        "Goals": "0",
        "DebutYear": "1969",
        "DebutOpponent": "Romania"
    },
    {
        "Surname": "Savage",
        "FullName": "Arthur Savage",
        "Position": "GK",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1876",
        "DebutOpponent": "Scotland"
    },
    {
        "Surname": "Garrett",
        "FullName": "Tommy Garrett",
        "Position": "FB",
        "Caps": "3",
        "Goals": "0",
        "DebutYear": "1952",
        "DebutOpponent": "Scotland"
    },
    {
        "Surname": "Jefferis",
        "FullName": "Frank Jefferis",
        "Position": "FW",
        "Caps": "2",
        "Goals": "0",
        "DebutYear": "1912",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Wright",
        "FullName": "Tommy Wright",
        "Position": "DF",
        "Caps": "11",
        "Goals": "0",
        "DebutYear": "1968",
        "DebutOpponent": "Soviet Union"
    },
    {
        "Surname": "Taylor",
        "FullName": "Ted Taylor",
        "Position": "GK",
        "Caps": "8",
        "Goals": "0",
        "DebutYear": "1922",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Brayshaw",
        "FullName": "Teddy Brayshaw",
        "Position": "HB",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1887",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Downs",
        "FullName": "Dicky Downs",
        "Position": "FB",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1920",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Bullock",
        "FullName": "Fred Bullock",
        "Position": "FB",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1923",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Hampson",
        "FullName": "Jimmy Hampson",
        "Position": "FW",
        "Caps": "3",
        "Goals": "5",
        "DebutYear": "1930",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Sharpe",
        "FullName": "Lee Sharpe",
        "Position": "MF",
        "Caps": "8",
        "Goals": "0",
        "DebutYear": "1991",
        "DebutOpponent": "Republic of Ireland"
    },
    {
        "Surname": "Ruddy",
        "FullName": "John Ruddy",
        "Position": "GK",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "2012",
        "DebutOpponent": "Italy"
    },
    {
        "Surname": "Sterland",
        "FullName": "Mel Sterland",
        "Position": "DF",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1988",
        "DebutOpponent": "Saudi Arabia"
    },
    {
        "Surname": "Mordue",
        "FullName": "Jackie Mordue",
        "Position": "FW",
        "Caps": "2",
        "Goals": "0",
        "DebutYear": "1912",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Wilson",
        "FullName": "George Wilson",
        "Position": "HB",
        "Caps": "12",
        "Goals": "0",
        "DebutYear": "1921",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Jones",
        "FullName": "Phil Jones",
        "Position": "DF",
        "Caps": "27",
        "Goals": "0",
        "DebutYear": "2011",
        "DebutOpponent": "Montenegro"
    },
    {
        "Surname": "Coates",
        "FullName": "Ralph Coates",
        "Position": "MF",
        "Caps": "4",
        "Goals": "0",
        "DebutYear": "1970",
        "DebutOpponent": "Northern Ireland"
    },
    {
        "Surname": "Hapgood",
        "FullName": "Eddie Hapgood",
        "Position": "FB",
        "Caps": "30",
        "Goals": "0",
        "DebutYear": "1933",
        "DebutOpponent": "Italy"
    },
    {
        "Surname": "Butler",
        "FullName": "Billy Butler",
        "Position": "FW",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1924",
        "DebutOpponent": "Scotland"
    },
    {
        "Surname": "Heaton",
        "FullName": "Tom Heaton",
        "Position": "GK",
        "Caps": "3",
        "Goals": "0",
        "DebutYear": "2016",
        "DebutOpponent": "Australia"
    },
    {
        "Surname": "Burgess",
        "FullName": "Herbert Burgess",
        "Position": "FB",
        "Caps": "4",
        "Goals": "0",
        "DebutYear": "1904",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "McMahon",
        "FullName": "Steve McMahon",
        "Position": "MF",
        "Caps": "17",
        "Goals": "0",
        "DebutYear": "1988",
        "DebutOpponent": "Israel"
    },
    {
        "Surname": "Crawford",
        "FullName": "Ray Crawford",
        "Position": "FW",
        "Caps": "2",
        "Goals": "1",
        "DebutYear": "1961",
        "DebutOpponent": "Northern Ireland"
    },
    {
        "Surname": "Hobbis",
        "FullName": "Harold Hobbis",
        "Position": "FW",
        "Caps": "2",
        "Goals": "1",
        "DebutYear": "1936",
        "DebutOpponent": "Austria"
    },
    {
        "Surname": "Day",
        "FullName": "Samuel Day",
        "Position": "FW",
        "Caps": "3",
        "Goals": "2",
        "DebutYear": "1906",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Moss",
        "FullName": "Frank Moss",
        "Position": "HB",
        "Caps": "5",
        "Goals": "0",
        "DebutYear": "1921",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Hargreaves",
        "FullName": "Fred Hargreaves",
        "Position": "HB",
        "Caps": "3",
        "Goals": "0",
        "DebutYear": "1880",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Carter",
        "FullName": "Raich Carter",
        "Position": "FW",
        "Caps": "13",
        "Goals": "7",
        "DebutYear": "1934",
        "DebutOpponent": "Scotland"
    },
    {
        "Surname": "Langley",
        "FullName": "Jim Langley",
        "Position": "FB",
        "Caps": "3",
        "Goals": "0",
        "DebutYear": "1958",
        "DebutOpponent": "Scotland"
    },
    {
        "Surname": "Metcalfe",
        "FullName": "Vic Metcalfe",
        "Position": "FW",
        "Caps": "2",
        "Goals": "0",
        "DebutYear": "1951",
        "DebutOpponent": "Argentina"
    },
    {
        "Surname": "Brown",
        "FullName": "Billy Brown",
        "Position": "FW",
        "Caps": "1",
        "Goals": "1",
        "DebutYear": "1923",
        "DebutOpponent": "Belgium"
    },
    {
        "Surname": "Heskey",
        "FullName": "Emile Heskey",
        "Position": "FW",
        "Caps": "62",
        "Goals": "7",
        "DebutYear": "1999",
        "DebutOpponent": "Hungary"
    },
    {
        "Surname": "Bentley",
        "FullName": "Roy Bentley",
        "Position": "FW",
        "Caps": "12",
        "Goals": "9",
        "DebutYear": "1949",
        "DebutOpponent": "Sweden"
    },
    {
        "Surname": "Rimmer",
        "FullName": "Ellis Rimmer",
        "Position": "FW",
        "Caps": "4",
        "Goals": "2",
        "DebutYear": "1930",
        "DebutOpponent": "Scotland"
    },
    {
        "Surname": "Smart",
        "FullName": "Tommy Smart",
        "Position": "FB",
        "Caps": "5",
        "Goals": "0",
        "DebutYear": "1921",
        "DebutOpponent": "Scotland"
    },
    {
        "Surname": "Smith",
        "FullName": "Albert Smith",
        "Position": "HB",
        "Caps": "3",
        "Goals": "0",
        "DebutYear": "1891",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Sorby",
        "FullName": "Thomas Sorby",
        "Position": "FW",
        "Caps": "1",
        "Goals": "1",
        "DebutYear": "1879",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Martin",
        "FullName": "Henry Martin",
        "Position": "FW",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1914",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Watson",
        "FullName": "Dave Watson",
        "Position": "DF",
        "Caps": "12",
        "Goals": "0",
        "DebutYear": "1984",
        "DebutOpponent": "Brazil"
    },
    {
        "Surname": "Brabrook",
        "FullName": "Peter Brabrook",
        "Position": "FW",
        "Caps": "3",
        "Goals": "0",
        "DebutYear": "1958",
        "DebutOpponent": "Soviet Union"
    },
    {
        "Surname": "Huddlestone",
        "FullName": "Tom Huddlestone",
        "Position": "MF",
        "Caps": "4",
        "Goals": "0",
        "DebutYear": "2009",
        "DebutOpponent": "Brazil"
    },
    {
        "Surname": "Franklin",
        "FullName": "Neil Franklin",
        "Position": "HB",
        "Caps": "27",
        "Goals": "0",
        "DebutYear": "1946",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Hills",
        "FullName": "Arnold Hills",
        "Position": "FW",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1879",
        "DebutOpponent": "Scotland"
    },
    {
        "Surname": "Urwin",
        "FullName": "Thomas Urwin",
        "Position": "FW",
        "Caps": "4",
        "Goals": "0",
        "DebutYear": "1923",
        "DebutOpponent": "Sweden"
    },
    {
        "Surname": "Campbell",
        "FullName": "Fraizer Campbell",
        "Position": "FW",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "2012",
        "DebutOpponent": "Netherlands"
    },
    {
        "Surname": "Paine",
        "FullName": "Terry Paine",
        "Position": "FW",
        "Caps": "19",
        "Goals": "7",
        "DebutYear": "1963",
        "DebutOpponent": "Czechoslovakia"
    },
    {
        "Surname": "Birkett",
        "FullName": "Ralph Birkett",
        "Position": "GW",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1935",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Booth",
        "FullName": "Frank Booth",
        "Position": "FW",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1905",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Upson",
        "FullName": "Matthew Upson",
        "Position": "DF",
        "Caps": "21",
        "Goals": "2",
        "DebutYear": "2003",
        "DebutOpponent": "South Africa"
    },
    {
        "Surname": "Woodger",
        "FullName": "George Woodger",
        "Position": "FW",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1911",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Ashurst",
        "FullName": "Bill Ashurst",
        "Position": "FB",
        "Caps": "5",
        "Goals": "0",
        "DebutYear": "1923",
        "DebutOpponent": "Sweden"
    },
    {
        "Surname": "Danks",
        "FullName": "Thomas Danks",
        "Position": "FW",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1885",
        "DebutOpponent": "Scotland"
    },
    {
        "Surname": "Cock",
        "FullName": "Jack Cock",
        "Position": "FW",
        "Caps": "2",
        "Goals": "2",
        "DebutYear": "1919",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "McManaman",
        "FullName": "Steve McManaman",
        "Position": "MF / FW",
        "Caps": "37",
        "Goals": "3",
        "DebutYear": "1994",
        "DebutOpponent": "Nigeria"
    },
    {
        "Surname": "Houlker",
        "FullName": "Kelly Houlker",
        "Position": "HB",
        "Caps": "5",
        "Goals": "0",
        "DebutYear": "1902",
        "DebutOpponent": "Scotland"
    },
    {
        "Surname": "Bliss",
        "FullName": "Bert Bliss",
        "Position": "FW",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1921",
        "DebutOpponent": "Scotland"
    },
    {
        "Surname": "Henderson",
        "FullName": "Jordan Henderson",
        "Position": "MF",
        "Caps": "75",
        "Goals": "3",
        "DebutYear": "2010",
        "DebutOpponent": "France"
    },
    {
        "Surname": "Medley",
        "FullName": "Les Medley",
        "Position": "FW",
        "Caps": "6",
        "Goals": "1",
        "DebutYear": "1950",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Spencer",
        "FullName": "Howard Spencer",
        "Position": "FB",
        "Caps": "6",
        "Goals": "0",
        "DebutYear": "1897",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Steele",
        "FullName": "Freddie Steele",
        "Position": "FW",
        "Caps": "6",
        "Goals": "8",
        "DebutYear": "1936",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Hibbert",
        "FullName": "Billy Hibbert",
        "Position": "FW",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1910",
        "DebutOpponent": "Scotland"
    },
    {
        "Surname": "Carragher",
        "FullName": "Jamie Carragher",
        "Position": "DF",
        "Caps": "38",
        "Goals": "0",
        "DebutYear": "1999",
        "DebutOpponent": "Hungary"
    },
    {
        "Surname": "Greenwood",
        "FullName": "Doctor Greenwood",
        "Position": "FB",
        "Caps": "2",
        "Goals": "0",
        "DebutYear": "1882",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Chivers",
        "FullName": "Martin Chivers",
        "Position": "FW",
        "Caps": "24",
        "Goals": "13",
        "DebutYear": "1971",
        "DebutOpponent": "Malta"
    },
    {
        "Surname": "Magee",
        "FullName": "Tommy Magee",
        "Position": "HB",
        "Caps": "5",
        "Goals": "0",
        "DebutYear": "1923",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Ward",
        "FullName": "Tim Ward",
        "Position": "HB",
        "Caps": "2",
        "Goals": "0",
        "DebutYear": "1947",
        "DebutOpponent": "Belgium"
    },
    {
        "Surname": "Seed",
        "FullName": "Jimmy Seed",
        "Position": "FW",
        "Caps": "5",
        "Goals": "1",
        "DebutYear": "1921",
        "DebutOpponent": "Belgium"
    },
    {
        "Surname": "Storer",
        "FullName": "Harry Storer",
        "Position": "FW",
        "Caps": "2",
        "Goals": "1",
        "DebutYear": "1924",
        "DebutOpponent": "France"
    },
    {
        "Surname": "Shearer",
        "FullName": "Alan Shearer",
        "Position": "FW",
        "Caps": "63",
        "Goals": "30",
        "DebutYear": "1992",
        "DebutOpponent": "France"
    },
    {
        "Surname": "Hardinge",
        "FullName": "Wally Hardinge",
        "Position": "FW",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1910",
        "DebutOpponent": "Scotland"
    },
    {
        "Surname": "Stephenson",
        "FullName": "Eric Stephenson",
        "Position": "FW",
        "Caps": "2",
        "Goals": "0",
        "DebutYear": "1938",
        "DebutOpponent": "Scotland"
    },
    {
        "Surname": "Roberts",
        "FullName": "Bob Roberts",
        "Position": "GK",
        "Caps": "3",
        "Goals": "0",
        "DebutYear": "1887",
        "DebutOpponent": "Scotland"
    },
    {
        "Surname": "Zaha",
        "FullName": "Wilfried Zaha",
        "Position": "MF",
        "Caps": "2",
        "Goals": "0",
        "DebutYear": "2012",
        "DebutOpponent": "Sweden"
    },
    {
        "Surname": "Harvey",
        "FullName": "Alf Harvey",
        "Position": "FB",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1881",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Allen",
        "FullName": "Clive Allen",
        "Position": "FW",
        "Caps": "5",
        "Goals": "0",
        "DebutYear": "1984",
        "DebutOpponent": "Brazil"
    },
    {
        "Surname": "Lawler",
        "FullName": "Chris Lawler",
        "Position": "DF",
        "Caps": "4",
        "Goals": "1",
        "DebutYear": "1971",
        "DebutOpponent": "Malta"
    },
    {
        "Surname": "Cook",
        "FullName": "Lewis Cook",
        "Position": "MF",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "2018",
        "DebutOpponent": "Italy"
    },
    {
        "Surname": "Bamford",
        "FullName": "Patrick Bamford",
        "Position": "FW",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "2021",
        "DebutOpponent": "Andorra"
    },
    {
        "Surname": "Burgess",
        "FullName": "Harry Burgess",
        "Position": "FW",
        "Caps": "4",
        "Goals": "4",
        "DebutYear": "1930",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Rigby",
        "FullName": "Arthur Rigby",
        "Position": "FW",
        "Caps": "5",
        "Goals": "3",
        "DebutYear": "1927",
        "DebutOpponent": "Scotland"
    },
    {
        "Surname": "Lyttelton",
        "FullName": "Edward Lyttelton",
        "Position": "FB",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1878",
        "DebutOpponent": "Scotland"
    },
    {
        "Surname": "Bowen",
        "FullName": "Jarrod Bowen",
        "Position": "FW",
        "Caps": "4",
        "Goals": "0",
        "DebutYear": "2022",
        "DebutOpponent": "Hungary"
    },
    {
        "Surname": "Tweedy",
        "FullName": "George Tweedy",
        "Position": "GK",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1936",
        "DebutOpponent": "Italy"
    },
    {
        "Surname": "Astle",
        "FullName": "Jeff Astle",
        "Position": "FW",
        "Caps": "5",
        "Goals": "0",
        "DebutYear": "1969",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Pope",
        "FullName": "Nick Pope",
        "Position": "GK",
        "Caps": "10",
        "Goals": "0",
        "DebutYear": "2018",
        "DebutOpponent": "Costa Rica"
    },
    {
        "Surname": "Hill",
        "FullName": "Fred Hill",
        "Position": "FW",
        "Caps": "2",
        "Goals": "0",
        "DebutYear": "1962",
        "DebutOpponent": "Northern Ireland"
    },
    {
        "Surname": "Knight",
        "FullName": "Arthur Knight",
        "Position": "FB",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1919",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Wainscoat",
        "FullName": "Russell Wainscoat",
        "Position": "FW",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1929",
        "DebutOpponent": "Scotland"
    },
    {
        "Surname": "Fletcher",
        "FullName": "Albert Fletcher",
        "Position": "HB",
        "Caps": "2",
        "Goals": "0",
        "DebutYear": "1889",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Harris",
        "FullName": "Stanley Harris",
        "Position": "FW",
        "Caps": "6",
        "Goals": "2",
        "DebutYear": "1904",
        "DebutOpponent": "Scotland"
    },
    {
        "Surname": "Howe",
        "FullName": "Jack Howe",
        "Position": "FB",
        "Caps": "3",
        "Goals": "0",
        "DebutYear": "1948",
        "DebutOpponent": "Italy"
    },
    {
        "Surname": "Phelan",
        "FullName": "Mike Phelan",
        "Position": "MF",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1989",
        "DebutOpponent": "Italy"
    },
    {
        "Surname": "Armstrong",
        "FullName": "Ken Armstrong",
        "Position": "HB",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1955",
        "DebutOpponent": "Scotland"
    },
    {
        "Surname": "Sutton",
        "FullName": "Chris Sutton",
        "Position": "FW",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1997",
        "DebutOpponent": "Cameroon"
    },
    {
        "Surname": "Catlin",
        "FullName": "Ted Catlin",
        "Position": "FB",
        "Caps": "5",
        "Goals": "0",
        "DebutYear": "1936",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "McCann",
        "FullName": "Gavin McCann",
        "Position": "MF",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "2001",
        "DebutOpponent": "Spain"
    },
    {
        "Surname": "Brooking",
        "FullName": "Trevor Brooking",
        "Position": "MF",
        "Caps": "47",
        "Goals": "5",
        "DebutYear": "1974",
        "DebutOpponent": "Portugal"
    },
    {
        "Surname": "Harrison",
        "FullName": "George Harrison",
        "Position": "FW",
        "Caps": "2",
        "Goals": "0",
        "DebutYear": "1921",
        "DebutOpponent": "Belgium"
    },
    {
        "Surname": "Lingard",
        "FullName": "Jesse Lingard",
        "Position": "MF",
        "Caps": "32",
        "Goals": "6",
        "DebutYear": "2016",
        "DebutOpponent": "Malta"
    },
    {
        "Surname": "Waring",
        "FullName": "Tom Waring",
        "Position": "FW",
        "Caps": "5",
        "Goals": "4",
        "DebutYear": "1931",
        "DebutOpponent": "France"
    },
    {
        "Surname": "Chamberlain",
        "FullName": "Alex Oxlade-Chamberlain",
        "Position": "MF",
        "Caps": "35",
        "Goals": "7",
        "DebutYear": "2012",
        "DebutOpponent": "Norway"
    },
    {
        "Surname": "Johnson",
        "FullName": "Edward Johnson",
        "Position": "FW",
        "Caps": "2",
        "Goals": "2",
        "DebutYear": "1880",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "LeTissier",
        "FullName": "Matt Le Tissier",
        "Position": "MF",
        "Caps": "8",
        "Goals": "0",
        "DebutYear": "1994",
        "DebutOpponent": "Denmark"
    },
    {
        "Surname": "Allen",
        "FullName": "Tony Allen",
        "Position": "FB",
        "Caps": "3",
        "Goals": "0",
        "DebutYear": "1959",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Hardy",
        "FullName": "Sam Hardy",
        "Position": "GK",
        "Caps": "21",
        "Goals": "0",
        "DebutYear": "1907",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Lowe",
        "FullName": "Eddie Lowe",
        "Position": "HB",
        "Caps": "3",
        "Goals": "0",
        "DebutYear": "1947",
        "DebutOpponent": "France"
    },
    {
        "Surname": "Turner",
        "FullName": "Archie Turner",
        "Position": "FW",
        "Caps": "2",
        "Goals": "0",
        "DebutYear": "1900",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Sayer",
        "FullName": "Jimmy Sayer",
        "Position": "FW",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1887",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Davis",
        "FullName": "George Davis",
        "Position": "FW",
        "Caps": "2",
        "Goals": "1",
        "DebutYear": "1904",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Marsh",
        "FullName": "Rodney Marsh",
        "Position": "FW",
        "Caps": "9",
        "Goals": "1",
        "DebutYear": "1971",
        "DebutOpponent": "Switzerland"
    },
    {
        "Surname": "Bentley",
        "FullName": "David Bentley",
        "Position": "MF",
        "Caps": "7",
        "Goals": "0",
        "DebutYear": "2007",
        "DebutOpponent": "Israel"
    },
    {
        "Surname": "Solanke",
        "FullName": "Dominic Solanke",
        "Position": "MF",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "2017",
        "DebutOpponent": "Brazil"
    },
    {
        "Surname": "Nugent",
        "FullName": "David Nugent",
        "Position": "FW",
        "Caps": "1",
        "Goals": "1",
        "DebutYear": "2007",
        "DebutOpponent": "Andorra"
    },
    {
        "Surname": "Pearson",
        "FullName": "Stuart Pearson",
        "Position": "FW",
        "Caps": "15",
        "Goals": "5",
        "DebutYear": "1976",
        "DebutOpponent": "Wales"
    },
    {
        "Surname": "Langton",
        "FullName": "Bobby Langton",
        "Position": "FW",
        "Caps": "11",
        "Goals": "1",
        "DebutYear": "1946",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Arthur",
        "FullName": "Herbie Arthur",
        "Position": "GK",
        "Caps": "7",
        "Goals": "0",
        "DebutYear": "1885",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Hendrie",
        "FullName": "Lee Hendrie",
        "Position": "MF",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1998",
        "DebutOpponent": "Czech Republic"
    },
    {
        "Surname": "Hudspeth",
        "FullName": "Frank Hudspeth",
        "Position": "FB",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1925",
        "DebutOpponent": "Ireland"
    },
    {
        "Surname": "Betts",
        "FullName": "Morton Betts",
        "Position": "GK",
        "Caps": "1",
        "Goals": "0",
        "DebutYear": "1877",
        "DebutOpponent": "Scotland"
    }
]